import i18n from '@/plugins/i18n'


// Define blockTypes as a plain object with properties for each type
export const blockTypes = {
  paragraph: {
    type: 'paragraph',
    displayString: i18n.t('blockTypes.paragraph'),
    icon: 'mdi-text-long',
  },
  header: {
    type: 'header',
    displayString: i18n.t('blockTypes.header'),
    icon: 'mdi-text-short',
  },
  string: {
    type: 'string',
    displayString: i18n.t('blockTypes.text'),
    icon: 'mdi-alphabetical-variant',
  },
  grid: {
    type: 'grid',
    displayString: i18n.t('blockTypes.grid'),
    icon: 'mdi-grid-large',
  },
  dateandtime: {
    type: 'dateandtime',
    displayString: i18n.t('blockTypes.dateTime'),
    icon: 'mdi-calendar-clock',
  },
  dictionary: {
    type: 'dictionary',
    displayString: i18n.t('blockTypes.dictionary'),
    icon: 'mdi-book-open-page-variant',
  },
  attachmentcollection: {
    type: 'attachmentcollection',
    displayString: i18n.t('blockTypes.attachmentCollection'),
    icon: 'mdi-paperclip',
  },
  uri: {
    type: 'uri',
    displayString: i18n.t('blockTypes.uri'),
    icon: 'mdi-web',
  },
  decimal: {
    type: 'decimal',
    displayString: i18n.t('blockTypes.decimal'),
    icon: 'mdi-decimal',
  },
  mimeentitycollection: {
    type: 'mimeentitycollection',
    displayString: i18n.t('blockTypes.mimeEntityCollection'),
    icon: 'mdi-email-multiple',
  },
  entity: {
    type: 'entity',
    displayString: i18n.t('blockTypes.entity'),
    icon: 'mdi-account-box',
  },
  integer: {
    type: 'integer',
    displayString: i18n.t('blockTypes.integer'),
    icon: 'mdi-numeric',
  },
  mimeentity: {
    type: 'mimeentity',
    displayString: i18n.t('blockTypes.mimeEntity'),
    icon: 'mdi-email',
  },
  enumcollection: {
    type: 'enumcollection',
    displayString: i18n.t('blockTypes.enumCollection'),
    icon: 'mdi-format-list-bulleted',
  },
  user: {
    type: 'user',
    displayString: i18n.t('blockTypes.user'),
    icon: 'mdi-account',
  },
  location: {
    type: 'location',
    displayString: i18n.t('blockTypes.location'),
    icon: 'mdi-map-marker',
  },
  boolean: {
    type: 'boolean',
    displayString: i18n.t('blockTypes.boolean'),
    icon: 'mdi-toggle-switch',
  },
  currency: {
    type: 'currency',
    displayString: i18n.t('blockTypes.currency'),
    icon: 'mdi-currency-usd',
  },
  date: {
    type: 'date',
    displayString: i18n.t('blockTypes.date'),
    icon: 'mdi-calendar',
  },
  multitext: {
    type: 'multitext',
    displayString: i18n.t('blockTypes.multiText'),
    icon: 'mdi-text-box-multiple',
  },
  address: {
    type: 'address',
    displayString: i18n.t('blockTypes.address'),
    icon: 'mdi-home-city',
  },
  enum: {
    type: 'enum',
    displayString: i18n.t('blockTypes.enum'),
    icon: 'mdi-format-list-bulleted-type',
  },
  text: {
    type: 'text',
    displayString: i18n.t('blockTypes.text'),
    icon: 'mdi-text',
  },
  entitycollection: {
    type: 'entitycollection',
    displayString: i18n.t('blockTypes.entityCollection'),
    icon: 'mdi-account-multiple',
  },
  signature: {
    type: 'signature',
    displayString: i18n.t('blockTypes.signature'),
    icon: 'mdi-pen',
  },
  email: {
    type: 'email',
    displayString: i18n.t('blockTypes.email'),
    icon: 'mdi-email',
  },
  phonenumber: {
    type: 'phonenumber',
    displayString: i18n.t('blockTypes.phoneNumber'),
    icon: 'mdi-phone',
  },
  attachment: {
    type: 'attachment',
    displayString: i18n.t('blockTypes.attachment'),
    icon: 'mdi-attachment',
  },
  resource: {
    type: 'resource',
    displayString: i18n.t('blockTypes.resource'),
    icon: 'mdi-link-variant',
  },
  block: {
    type: 'block',
    displayString: i18n.t('blockTypes.block'),
    icon: 'mdi-file-document-outline',
  },
  page: {
    type: 'page',
    displayString: i18n.t('blockTypes.page'),
    icon: 'mdi-file-document-outline',
  }
}
