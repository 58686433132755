<template>
  <div :data-testId="testId" v-show="!hidden">
    <editable-text
              v-if="(forceLabel || fieldStyle.labelAbove) && !hideLabel" 
              :textClass="labelClasses"
              :maxLength="200"
              :multiline="true"
              :readonly="!designer"
              :text="label"
              :singleClick="true"
              @submit="saveFieldLabel"
              :required="false"
              :placeholder="$t('forms.builder.formFieldLabelPlaceholder')"
            ></editable-text>

    <slot />
    <div class="text-caption mt-1">
      <LinkifiedText :text="description"/>
    </div>
  </div>
</template>

<script>
import LinkifiedText from '@/components/LinkifiedText.vue'
import { formFields } from '@/mixins/formFields.js'
import EditableText from '@/components/EditableText.vue'

export default {
  mixins: [formFields],
  props: {
    forceLabel: {
      type: Boolean,
      default: false
    },
    hideLabel:{
      type: Boolean,
      default: false
    },
  },
  components: {
    LinkifiedText,
    EditableText
  },
  computed: {
    testId() {
      return `form-field-${this.component?.fieldId}`
    },
    labelClasses() {
      return ['subtitle-2', 'font-weight-medium', 'mb-1', `${this.labelColor}--text` , 'text--darken-3' ].join(' ')
    }
  }
}
</script>
<style lang="css" scoped>
.outlined >>> .v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
}
</style>