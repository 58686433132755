<template>
  <div class="template-input-wrapper">
    <div class="label v-label theme--light" v-show="showLabel">{{label}}</div>
    <div class="v-label theme--light mb-1 text-caption" v-show="!showLabel && topLabel">{{label}}</div>
    <div ref="hiddenContent" class="hidden-content">
    </div>
    <div ref="input" class="input" :style="borderColor" :class="{textArea}">
    </div>
  </div>
</template>


<script>
import externalModel from '@/mixins/externalModel.js'
import Editor from '@/editor/Editor.js'


export default {
  mixins: [externalModel],
  props: {
    flow: null,
    textArea: {
      type: Boolean,
      default: () => false
    },
    selected: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    topLabel: {
      type: Boolean,
      default: () => false
    },
    label: null
  },
  data() {
    return {
      editor: undefined,
      textSchema: undefined,
      sections: [],
      focused: false
    }
  },
  computed: {
    borderColor() {
      return {
        'border-color': this.selected ? this.$vuetify.theme.themes.light.primary : '#D3D3D3'
      }
    },
    showLabel() {
      return (this.value == null || this.value.length === 0) && this.label && !this.focused
    },
  },
  mounted() {
    const editorViewNode = this.$refs.input
    const contentNode = this.$refs.hiddenContent

    const domEventHandlers = {
      blur: (editor) => {
        this.focused = false
        this.emitEditorContent(editor)
      },
      focus: () => {
        this.$emit('focus')
        this.focused = true
      }
    }

    this.editor = new Editor({
      flow: this.flow,
      editorViewNode,
      contentNode,
      domEventHandlers,
      multiline: this.textArea
    })
  },
  methods: {
    insert(text) {
      this.editor.insertExpression(text)
      this.emitEditorContent()
    },
    insertToken(tokenProps) {
      this.editor.insertToken(tokenProps)
      this.emitEditorContent()
    },
    emitEditorContent() {
      this.externalModel = this.editor.getContentAsText()
      this.$emit('blur')
    },
    focus() {
      this.editor.focus()
    }
  },
}
</script>

<style scoped>
.template-input-wrapper {
  position: relative;
}
.label {
  position: absolute;
  top: 12px;
  left: 12px;
  pointer-events: none;
  z-index: 1;
}
::v-deep.input > div{
  min-height: 40px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 6px;
  border: solid #D3D3D3 1px;
  border-radius: 4px;
  background: white;
  white-space: pre;
  overflow: auto;
  -webkit-user-select: auto;
  user-select: all;
}

::v-deep.textArea > div{
  min-height: 80px;
  resize: vertical;
}

::v-deep.input p {
  margin: 0px;
}

::v-deep.input span {
  margin: 0px;
  vertical-align: bottom;
}

.hidden-content {
  display: none;
}
</style>