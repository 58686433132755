<template>
  <v-menu
    offset-y
    v-model="menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="black"
        v-bind="attrs"
        icon
        x-small
        @click.prevent="on.click"
      >
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <MenuItem
        @click.stop="showRenameDialog"
        icon="mdi-pencil"
        :text="$t('blocks.renamePage')"
      />
      <MenuItem
        @click.stop="showHeaderImageDialog"
        icon="mdi-panorama-variant"
        :text="$t('blocks.setHeaderImage')"
      />
      <MenuItem
        @click.stop="deleteBlock"
        icon="mdi-delete"
        :text="$t('blocks.deletePage')"
      />
    </v-list>
    <SingleInputDialog
      v-model="headerImageDialog"
      :title="$t('blocks.setHeaderImage')"
      :okHandler="url => setHeaderImage(url)"
      :initialInputValue="block.headerImageUrl"
      max-width="290"
    />
    <SingleInputDialog
      v-model="renameDialog"
      :title="$t('blocks.renamePageTitle', {name: block.name})"
      :okHandler="newName => patchName(newName)"
      :initialInputValue="block.name"
      max-width="290"
    />
  </v-menu>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import Block from '@/store/models/blocks/Block'

export default {
  props: {
    block: {
      type: Block
    }
  },
  data() {
    return {
      menu: false,
      headerImageDialog: false,
      renameDialog: false
    }
  },
  methods: {
    setHeaderImage(url) {
      this.block.setHeaderImageUrl(url)
    },
    async patchName(newName) {
      await this.block.patchName(newName)
      this.block.name = newName
    },
    showHeaderImageDialog() {
      this.headerImageDialog = true
    },
    showRenameDialog() {
      this.renameDialog = true
    },
    async deleteBlock() {
      await this.$store.dispatch('AGDeleteBlock', this.block)
      this.$emit('blockDeleted')
    },
  },
  components: {
    MenuItem,
    SingleInputDialog
  }
}
</script>

<style
  lang="scss"
  scoped
></style>