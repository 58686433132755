<template>
  <div>
    <div class="d-flex pa-3 gap-5">
      <div class="flex-grow-1" style="max-width: 100%">
        <div class="d-flex justify-space-between align-center mb-2 mr-2 text-caption">
          <div class="d-flex align-center">
          <div class="text-caption mr-2">{{$t('columnTypes.formula')}}</div>
          <v-menu
            bottom
            dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pt-0 pb-0" dense>
              <v-list-item  @click="displayRaw" v-if="!rawTextMode">
                <v-list-item-title>{{$t('formulaOptions.showAsText')}}</v-list-item-title>
              </v-list-item>
              <v-list-item  @click="displayToken" v-else>
                <v-list-item-title>{{$t('formulaOptions.showAsToken')}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
          <v-btn small icon color="accent" target="_blank" href="https://intercom.help/apptivegrid/de/articles/8361048-formeln"><v-icon small>mdi-help-circle</v-icon></v-btn>

        </div>
        <div v-if="!rawTextMode">
          <div class="d-flex align-center">
            <ApptiveExpressionInput
              ref="input"
              data-testid="formularExpressionInput"
              v-model="expressionModel"
              class="raw-condition flex-grow-1"
              @input="emit"
              @change="computeLocalProperties"
              :grid="grid"
              :label="$t('formulaOptions.expressionLabel')"
            />
          </div>
        </div>
        <div  v-else>
          <div class="d-flex align-center pl-1 pt-1">
            <v-sheet
              width="100%"
              color="white"
              class=""
              >
              <v-textarea
                rows="3"
                ref="rawInput"
                v-model="expressionModel"
                hide-details solo
              />
            </v-sheet>
          </div>
          <div class="d-flex justify-end mt-1">
            <v-btn icon @click="displayToken" color="primary"><v-icon>mdi-check</v-icon></v-btn>
          </div>
        </div>
        <ExpressionPicker
          class="flex-shrink-0 mt-3"
          @input="addExpression"
          :field="field"
          :grid="grid"
          :supportedTypes="supportedTypes"
          :methods="methods"
          :showOperators="showOperators"
        />
        <div class="caption mt-3" v-text="$t('formulaOptions.valueTypeModel')"></div>
        <v-select
          solo flat dense
          hide-details
          v-model="valueTypeModel"
          class="mt-3"
          @input="emit"
          :items="compatibleTypes"
          :label="$t('formulaOptions.valueTypeModel')"
          item-value="name"
          item-text="displayString"
          data-testid="formulaValueTypeSelect"
        >
          <template v-slot:item="{ item, on }">
            <v-list-item v-on="on"
              :data-testid="`valueType-${item.name}`">
              <v-icon small>{{ item.typeIcon }}</v-icon>
              <v-list-item-content class="ml-3">
                <v-list-item-title v-text="item.displayString" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <component
          v-if="statefulType"
          :is="statefulType.component"
          class="state-selection"
          v-model="statefulTypeModel"
          @resized="$emit('typeGroupClicked')"
          @input="emit"
          :space="space"
          :grid="grid"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* global ASParser */
import ExpressionPicker from '@/components/ExpressionPicker.vue'
import ApptivescriptExpressionView from '@/components/ApptivescriptExpressionView.vue'
import { columnTypes } from '@/constants/columnTypes.js'
import ApptiveExpressionInput from '../ApptiveExpressionInput.vue'
import { ExpressionVisitor, EntityScope } from '@/apptivescript/apptivescriptUtils'
import apptivescriptTypeMapping from '@/constants/apptivescriptTypeMapping.js'

export default {
  props: {
    value: null,
    space: null,
    grid: null,
    field: null
  },
  data() {
    return {
      expressionModel: undefined,
      valueTypeModel: undefined,
      invalidExpression: true,
      rawTextMode: false,
      statefulTypeModel: undefined,
      expressionReturnType: undefined,
      methods: undefined
    }
  },
  computed: {
    typeItems() {
      return Object.values(columnTypes)
    },
    supportedTypes() {
      return [
        columnTypes.string,
        columnTypes.integer,
        columnTypes.decimal,
        columnTypes.dateTime,
        columnTypes.date,
        columnTypes.createdAt,
        columnTypes.boolean,
        columnTypes.currency,
      ]
    },
    compatibleTypes() {
      const filteredTypes = apptivescriptTypeMapping()[this.expressionReturnType]
      if (filteredTypes == null) {
        return this.supportedTypes
      }
      return filteredTypes
    },
    valueType() {
      return this.supportedTypes.find( type => type.name === this.valueTypeModel)
    },
    statefulType() {
      return this.valueType?.state
    },
    entityScope() {
      return new EntityScope(this.grid, this.grid.sampleEntity())
    },
    showOperators() {
      const numericalTypes = [
        'ASNumber',
        'ASDecimal',
        'ASInteger'
      ]
      return numericalTypes.includes(this.expressionReturnType)
    }
  },
  mounted() {
    this.displayToken()
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.valueTypeModel = columnTypes.integer.name
          return
        }
        this.expressionModel = newVal.expression
        this.valueTypeModel = newVal.valueType?.name
        if (newVal.valueType.state) {
          this.statefulTypeModel = newVal.valueType.state.initialisation(newVal.type)
        }
      }
    },
    expressionModel: {
      immediate: true,
      handler(newVal) {
        this.computeLocalProperties(newVal)
      }
    },
    compatibleTypes(newVal) {
      if (newVal.length === 1) {
        this.valueTypeModel = newVal[0].name
        this.emit()
      }
    }
  },
  methods: {
    newTypeAttributes() {
       return this.statefulType ? this.statefulType.attributesFrom(this.statefulTypeModel) : undefined
    },
    emit() {
      this.$emit('resized')
      if (this.expressionModel == null || this.valueTypeModel == null) {
        return
      }
      this.$emit('input', {
        expression: this.expressionModel,
        valueType: {
          name: this.valueTypeModel,
          ...this.newTypeAttributes()
        }
      })
    },
    async addExpression(expression) {
      if ('tokens' in expression) {
        if (this.rawTextMode) {
          this.insertToken({value: expression.tokens.reduce((acc, token) => acc + token.value, '')})
        } else {
          expression.tokens.forEach(this.insertToken)
        }
      } else {
        this.insertToken(expression)
      }
    },
    async insertToken(token) {
      if (this.rawTextMode) {
        const textArea = this.$refs.rawInput.$el.querySelector('textarea')
        const selectionStart = textArea.selectionStart
        const selectionEnd = textArea.selectionEnd
        this.expressionModel = this.expressionModel.slice(0, selectionStart) + token.value + this.expressionModel.slice(selectionEnd)
        await this.$nextTick()
        textArea.setSelectionRange(selectionStart + token.value.length, selectionStart + token.value.length)
        textArea.focus()
      } else {
        this.$refs.input.insertToken(token)
      }
    },
    async displayRaw() {
      await this.$nextTick()
      this.rawTextMode = true
    },
    async displayToken() {
      this.rawTextMode = false
      if (this.expressionModel == null) {
        return
      }
      await this.$nextTick()
      let tokens = []
      try {
        const ast = ASParser.as_parse_(this.expressionModel)
        const visitor = new ExpressionVisitor(this.grid)
        visitor.as_visit_(ast)
        tokens = visitor.tokens ?? []
      } catch(error) {
        console.error(error)
      }
      tokens.forEach(this.addExpression)
    },
    computeLocalProperties(expression) {
      this.invalidExpression = true
        if (!expression) {
          return
        }

        this.expressionReturnType = undefined
        try {
          const ast = ASParser.as_parse_(expression)
          const evaluated = ast.as_evaluateIn_(this.entityScope)
          const localProperties = evaluated.as_localProperties()
          const returnType = evaluated.constructor.name
          this.expressionReturnType = returnType

          if(localProperties != null ) {
            this.methods = localProperties
          }
          this.invalidExpression = false
        } catch(error) {
          console.error(error)
        }
    }
  },
  components: {
    ExpressionPicker,
    ApptivescriptExpressionView,
    ApptiveExpressionInput,
}
}
</script>

<style scoped>
.raw-condition {
  overflow-wrap: anywhere;
  color: black;
  overflow: scroll;
}

.state-selection {
  background: white;
}

</style>