import Axios from 'axios'
import Cookies from 'js-cookie'
import ApiClient from '@/ApiClient'
import apptiveErrorReporter from '@/plugins/apptiveErrorReporter.js'

const REFRESH_TOKEN = 'refresh_token'
const SESSION_ID = 'session-id'
const AUTH_PREFIX = '/auth/apptivegrid/'
const CODE_VERIFIER = 'code-verifier'

let accessToken
let codeVerifier = localStorage.getItem(CODE_VERIFIER)

function getTokenCall(authCode) {
  const params = new URLSearchParams()
  params.append('code', authCode)
  params.append('code_verifier', codeVerifier)
  params.append('grant_type', 'authorization_code')

  return Axios.post(`${AUTH_PREFIX}token`, params)
}

function generateCodeVerifier() {
  const length = Math.floor(Math.random() * (128 - 43 + 1) + 43)
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~'

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

async function generateCodeChallenge(codeVerifier) {
  var digest = await crypto.subtle.digest('SHA-256',
    new TextEncoder().encode(codeVerifier))

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

function refreshAccessTokenCall(refreshToken) {
  const params = new URLSearchParams()
  params.append('refresh_token', refreshToken)
  params.append('grant_type', 'refresh_token')

  return Axios.post(`${AUTH_PREFIX}token`, params)
}


export async function refreshAccessToken() {
  const refreshToken = Cookies.get(REFRESH_TOKEN)
  if (refreshToken == null) {
    throw 'Missing refresh token'
  }
  const response = await refreshAccessTokenCall(refreshToken)
  accessToken = response.data[ 'access_token' ]
  setRefreshToken(response.data[ 'refresh_token' ], new Date(new Date().getTime() + (response.data[ 'refresh_expires_in' ]) * 1000))
  ApiClient.setAuth(accessToken)
  return accessToken
}

export function setRefreshToken(token, expires) {
  Cookies.set(REFRESH_TOKEN, token, { expires: expires } )
}

export function setAccessToken(token) {
  accessToken = token
}

export function clearCookies() {
  Cookies.remove(REFRESH_TOKEN)
  Cookies.remove(SESSION_ID)
}

export async function toLoginPage() {
  codeVerifier = generateCodeVerifier()
  localStorage.setItem(CODE_VERIFIER, codeVerifier)
  const codeChallenge = await generateCodeChallenge(codeVerifier)
  const state = crypto.randomUUID()

  const searchParams = new URLSearchParams()
  
  let location = window.location.toString()

  searchParams.append('redirect_uri', location)
  searchParams.append('code_challenge', codeChallenge)
  searchParams.append('code_challenge_method', 'S256')
  searchParams.append('state', state)
  searchParams.append('client_id', 'web')
  let windowLocation = `${AUTH_PREFIX}authorize?${searchParams.toString()}`
  window.location.href = windowLocation

}

export async function authenticate() {
  // First check if a new login has been performed and
  // if a code needs to be exchanged
  let accessToken = null
  try {
    accessToken = await exchangeCode()
  } catch (error) {
    apptiveErrorReporter.captureException(error)
  }
  if (accessToken != null) {
    ApiClient.setAuth(accessToken)
  }
  else {
    // If not, attempting to refresh the access token
    try {
      accessToken = await refreshAccessToken()
    } catch (error) {
      apptiveErrorReporter.captureException(error)
    }
  }
  return accessToken != null
}

export function logout() {
  setAccessToken(null)
  clearCookies()
  toLoginPage()
}

export function getAuthCode() {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('code')
}

export async function exchangeCode() {
  const urlParams = new URLSearchParams(window.location.search)
  const authCode = getAuthCode()
  let accessToken
  if (authCode != null && urlParams.get('session_state') == null) {
    const tokenResponse = await getTokenCall(authCode)
    accessToken = tokenResponse.data[ 'access_token' ]
    Cookies.set(REFRESH_TOKEN, tokenResponse.data[ 'refresh_token' ], { expires: new Date(new Date().getTime() + (tokenResponse.data[ 'refresh_expires_in' ]) * 1000) })

    // The code has been exchanged, remove it from the window uri
    urlParams.delete('code')
    // also remove state param as the auth challenge is completed
    urlParams.delete('state')
    let newLocation = window.location.origin + window.location.pathname
    if (urlParams.toString()) {
      newLocation += `?${urlParams.toString()}`
    }
    window.history.pushState('object or string', 'Title', newLocation)
  }
  return accessToken
}

export async function deleteUser() {
  return Axios.delete(`${AUTH_PREFIX}users/me`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
}
