<template>
  <div
    :style="{'min-width': `${width}px`, 'max-height': `calc(100vh - ${$vuetify.application.top}px)`}"
    class="d-flex flex-column justify-space-between flex-grow list"
    v-if="showDrawer"
    data-tutorial="grid-views-list"
  >

  <v-list dense class="flex-start overflow-auto">
    <div class="d-flex align-center">
      <v-subheader class="pl-4" data-testid="virtualGridList">{{$t('navigationDrawer.viewsHeader')}}</v-subheader>
      <v-progress-circular v-show="loading" class="ml-auto mr-3" indeterminate color="primary" width='1' size="16" />
    </div>
    <AGDraggable
      tag="transition-group"
      :componentData="{props: {name: 'fade'}}"
      @end="onStatefulViewItemDragEnd"
    >
      <v-list-item
        v-for="(item) in statefulViewItems"
        :key="item.name + item.uri"
        class="nav-item"
        :to="item.to"
        :data-testid="item.testid"
      >
        <v-list-item-icon  class="mr-2">
          <ViewIcon :type="item.iconType" />
        </v-list-item-icon>
        <v-list-item-title >
          <EditableText
            v-if="item.rename"
            class="ma-1"
            :text="item.name"
            @submit="item.rename"
          />
          <span v-else class="ma-1">{{item.name}}</span>
        </v-list-item-title>

        <v-list-item-action>
          <StatefulViewMenu
            :statefulViewUri="item.to.params.statefulViewUri"
          />
        </v-list-item-action>
      </v-list-item>
  </AGDraggable>

    <v-subheader v-if="formItems.length > 0" class="pl-4" data-testid="formsList">{{$t('navigationDrawer.formsHeader')}}</v-subheader>
    <transition-group name="fade">
      <PaywallMenu
        v-for="(item) in formItems"
        :key="item.name + item.uri"
        :feature="$apptive.constants.features.FORMS_PER_SPACE"
        :count="formItems.indexOf(item)"
      >

      <v-list-item
        :key="item.name + item.uri"
        class="nav-item"
        :to="item.to"
        :data-testid="item.testid"
      >

        <v-list-item-icon  class="mr-2">
          <ViewIcon :type="item.iconType" />
        </v-list-item-icon>
        <v-list-item-title >
          <EditableText
            v-if="item.rename"
            class="ma-1"
            :text="item.name"
            @submit="item.rename"
          />
          <span v-else class="ma-1">{{item.name}}</span>
        </v-list-item-title>

        <v-list-item-action>
          <FormMoreMenu
            :formUri="item.to.params.formUri"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="black"
                data-testid="showFormMoreMenuButton"
                v-bind="attrs"
                @click.prevent="on.click"
                icon
                x-small
              >
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
          </FormMoreMenu>
      </v-list-item-action>
    </v-list-item>
  </PaywallMenu>

  </transition-group>
  </v-list>

  <div class="flex-end">
    <v-divider></v-divider>

    <v-list dense>
      <v-list-group v-model="createMenuOpen">
      <template v-slot:activator>

        <v-list-item class="px-0">
          <v-subheader class="pl-0 mr-7" data-testid="createViewsExpandButton">
            {{$t('navigationDrawer.createViewHeader')}}
         </v-subheader>
         <div v-show="!createMenuOpen" class="dflex row">
            <ViewIcon bordered class="addViewIcon" :type="ViewIconTypes.FORM" />
            <ViewIcon bordered class="addViewIcon" :type="ViewIconTypes.KANBAN" />
            <ViewIcon bordered class="addViewIcon" :type="ViewIconTypes.CALENDAR" />
            <ViewIcon bordered class="addViewIcon" :type="ViewIconTypes.MAP" />
            <ViewIcon bordered class="addViewIcon" :type="ViewIconTypes.GALLERY" />
          </div>
       </v-list-item>

      </template>

      <template v-slot:appendIcon>
        <v-icon color="accent" class="mr-2 pl-0" small>mdi-chevron-up</v-icon>
      </template>

      <v-list-item  :disabled="!canAddView" @click="createVirtualGrid" data-testid="createVirtualGrid">
        <v-list-item-icon class="mr-2">
          <ViewIcon :type="ViewIconTypes.GRID" />
        </v-list-item-icon>
        <v-list-item-title>{{$t('navigationDrawer.newVirtualGrid')}}</v-list-item-title>
        <v-list-item-icon class="pr-0">
          <v-icon color="accent" class="mr-0" small>mdi-plus</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <PaywallMenu
        :feature="$apptive.constants.features.FORMS_PER_SPACE"
        :count="formItems.length"
          >
        <v-list-item :disabled="!canAddForm" @click="createForm" data-testid="createForm">
          <v-list-item-icon class="mr-2">
            <ViewIcon :type="ViewIconTypes.FORM" />
          </v-list-item-icon>
          <v-list-item-title>{{$t('navigationDrawer.newForm')}}</v-list-item-title>
          <v-list-item-icon>
            <v-icon color="accent" small>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
     </PaywallMenu>


      <FeatureOnboardingMenu
        :title="$t('kanban.onboarding.title')"
        :subtitle="$t('kanban.onboarding.subtitle')"
        :text="$t('kanban.onboarding.text')"
        image="/kanban-board.svg"
        open-on-hover
      >

        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            :disabled="!canAddView"
            v-bind="attrs"
            v-on="on"
            @click="createKanban"
            data-testid="createKanban"
          >
            <v-list-item-icon class="mr-2">
              <ViewIcon :type="ViewIconTypes.KANBAN" />
            </v-list-item-icon>
            <v-list-item-title>{{$t('kanban.kanbanBoard')}}</v-list-item-title>
            <v-list-item-icon>
              <v-icon color="accent" small>mdi-plus</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>

      </FeatureOnboardingMenu>

      <FeatureOnboardingMenu
        :title="$t('calendar.onboarding.title')"
        :subtitle="$t('calendar.onboarding.subtitle')"
        :text="$t('calendar.onboarding.text')"
        image="/calendar.svg"
        open-on-hover
      >

        <template v-slot:activator="{ on, attrs }">
        <v-list-item
          :disabled="!canAddView"
          data-testid="createCalendar"
          v-bind="attrs"
          v-on="on"
          @click="createCalendar"
        >
          <v-list-item-icon class="mr-2">
            <ViewIcon :type="ViewIconTypes.CALENDAR" />
          </v-list-item-icon>
          <v-list-item-title>{{$t('calendar.title')}}</v-list-item-title>
          <v-list-item-icon>
            <v-icon color="accent" small>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </FeatureOnboardingMenu>

      <FeatureOnboardingMenu
        :title="$t('map.onboarding.title')"
        :subtitle="$t('map.onboarding.subtitle')"
        :text="$t('map.onboarding.text')"
        image="/map.svg"
        open-on-hover
      >

        <template v-slot:activator="{ on, attrs }">
        <v-list-item
          :disabled="!canAddView"
          data-testid="createMap"
          v-bind="attrs"
          v-on="on"
          @click="createMap"
        >
          <v-list-item-icon class="mr-2">
            <ViewIcon :type="ViewIconTypes.MAP" />
          </v-list-item-icon>
          <v-list-item-title>{{$t('map.title')}}</v-list-item-title>
          <v-list-item-icon>
            <v-icon color="accent" small>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </FeatureOnboardingMenu>

    <FeatureOnboardingMenu
        :title="$t('gallery.onboarding.title')"
        :subtitle="$t('gallery.onboarding.subtitle')"
        :text="$t('gallery.onboarding.text')"
        image="/gallery.svg"
        open-on-hover
      >

        <template v-slot:activator="{ on, attrs }">
        <v-list-item
          :disabled="!canAddView"
          data-testid="createGallery"
          v-bind="attrs"
          v-on="on"
          @click="createGalleryView"
        >
          <v-list-item-icon class="mr-2">
            <ViewIcon :type="ViewIconTypes.GALLERY" />
          </v-list-item-icon>
          <v-list-item-title>{{$t('gallery.title')}}</v-list-item-title>
          <v-list-item-icon>
            <v-icon color="accent" small>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </FeatureOnboardingMenu>
  </v-list-group>
    </v-list>
  </div>
</div>

</template>

<script>
import {ViewIconTypes} from '@/constants/viewIconTypes.js'
import ViewIcon from '@/components/ViewIcon.vue'
import FeatureOnboardingMenu from '@/components/FeatureOnboardingMenu.vue'
import EditableText from '@/components/EditableText.vue'
import StatefulViewMenu from '@/components/space/SatefulViewMenu.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import statefulViewTypes from '@/constants/statefulViewTypes.js'
import FormMoreMenu from '@/components/form/FormMoreMenu.vue'
import AGDraggable from '../AGDraggable.vue'
import PaywallMenu from '../paywall/PaywallMenu.vue'

export default {
  name: 'NavigationDrawer',
  props: {
    space: null,
    width: null,
    gridUri: null,
    statefulViewItems: null,
    formItems: null,
    showDrawer: null,
    loading: null
  },
  data() {
    return {
      createMenuOpen: false
    }
  },
  created() {
    this.ViewIconTypes = ViewIconTypes
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val){
        this.$emit('input', val)
      }
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    canAddView() {
      return hasPermission(this.grid, [PERMISSIONS.addView])
    },
    canAddForm() {
      return hasPermission(this.grid, [PERMISSIONS.addForm])
    },
  },
  methods: {
    async createForm() {
      const form = await this.$store.dispatch('AGAddFormOperation', {gridUri: this.gridUri})
      this.$intercom?.trackEvent( 'User created a form' )
      this.$store.dispatch('loadFormUris', this.grid.uri)
      this.$router.push({name: 'FormBuilder', params: { formUri: form.uri, gridUri: this.gridUri }})
    },
    createVirtualGrid() {
      this.$store.dispatch('AGAddSpreadsheetViewOperation', {
        grid: this.grid
      })
      .then((statefulView) => {
        this.$router.push({
          name: 'VirtualGrid',
          params: {
            gridUri: this.gridUri,
            virtualGridUri: statefulView.parentGrid.uri,
            statefulViewUri: statefulView.uri
        }})
      })
    },
    createKanban() {
      this.$intercom?.trackEvent( 'User created kanban board' )
      this.$store.dispatch('AGAddStatefulViewOperation', {
        grid: this.grid,
        name: 'Kanban',
        type: statefulViewTypes.kanban.name,
        fieldIds: this.grid.fields.map(field => field.id),
        properties: { version: statefulViewTypes.kanban.version }
      })
        .then((statefulView) => this.$router.push({
          name: 'KanbanView',
          params: {
            virtualGridUri: this.$store.getters.gridUriContainingStatefulView(statefulView.uri),
            statefulViewUri: statefulView.uri
          }
        }))
    },
    createCalendar() {
      this.$intercom?.trackEvent( 'User created calendar' )
      this.$store.dispatch('AGAddStatefulViewOperation', {
        grid: this.grid,
        name: 'Calendar',
        type: statefulViewTypes.calendar.name,
        fieldIds: this.grid.fields.map(field => field.id),
        properties: { version: statefulViewTypes.calendar.version }
      })
        .then((statefulView) => this.$router.push({
          name: 'CalendarView',
          params: {
            virtualGridUri: this.$store.getters.gridUriContainingStatefulView(statefulView.uri),
            statefulViewUri: statefulView.uri
          }
        }))
    },
    createMap() {
      this.$intercom?.trackEvent( 'User created map' )
      this.$store.dispatch('AGAddStatefulViewOperation', {
        grid: this.grid,
        name: 'Map',
        type: statefulViewTypes.map.name,
        fieldIds: this.grid.fields.map(field => field.id),
        properties: { version: statefulViewTypes.map.version }
      })
        .then((statefulView) => this.$router.push({
          name: 'MapView',
          params: {
            virtualGridUri: this.$store.getters.gridUriContainingStatefulView(statefulView.uri),
            statefulViewUri: statefulView.uri
          }
        }))
    },
    createGalleryView() {
      this.$intercom?.trackEvent( 'User created gallery board' )
      this.$store.dispatch('AGAddStatefulViewOperation', {
        grid: this.grid,
        name: 'Gallery',
        type: statefulViewTypes.gallery.name,
        fieldIds: this.grid.fields.map(field => field.id),
        properties: { version: statefulViewTypes.gallery.version }
      })
        .then((statefulView) => this.$router.push({
          name: 'GalleryView',
          params: {
            virtualGridUri: this.$store.getters.gridUriContainingStatefulView(statefulView.uri),
            statefulViewUri: statefulView.uri
          }
        }))
    },
    onStatefulViewItemDragEnd(event) {
      const statefulViewItem = this.statefulViewItems[event.oldIndex]
      this.$store.dispatch('AGMoveStatefulViewOperation', {
        space: this.space,
        virtualGridUri: statefulViewItem.parentGridUri,
        position: event.newIndex
      })
    }
  },
  components: {
    ViewIcon,
    FeatureOnboardingMenu,
    EditableText,
    StatefulViewMenu,
    FormMoreMenu,
    AGDraggable,
    PaywallMenu
}
}
</script>

<style lang="css" scoped>
.list {
    background-color: white;
    border-right: 1px solid #e4e5e4;
}
.fade-enter-active {
  transition-delay: .1s;
  transition-duration: .3s;
}
.fade-leave-active {
  transition-duration: .1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.overflow-auto {
  overflow: auto;
}
</style>

<style lang="scss" scoped>
::v-deep .nav-item {
  .v-list-item__action {
    margin: 0px;
  }
}
.addViewIcon {
  margin-left: -8px;

}
</style>
