<template>
  <div>
    <div
      class="d-flex flex-row align-center"
      v-for="(value, index) in internalModel"
      :key="index"
    >
      <TemplateFilePicker
        class="mt-3 flex-grow-1"
        :value="value"
        @input="newVal => update(index, newVal)"
        @keyup.enter.native.stop="() => addInput(index + 1)"
        @click.native="lastActiveInput = index"
        @focus="lastActiveInput = index; $emit('focus')"
        @blur="$emit('blur')"
        :selected="selected && lastActiveInput === index"
        v-bind="$attrs"
        ref="input"
      />
      <v-btn
        v-if="internalModel.length > 1"
        @click="() => removeInput(index)"
        text icon small class="ml-1"
      >
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-btn
      @click="() => addInput(internalModel.length)"
      text icon small
    >
      <v-icon small>mdi-plus</v-icon>
    </v-btn>
  </div>
  </template>
  
  <script>
  import TemplateFilePicker from './TemplateFilePicker.vue'
  import externalModel from '../../mixins/externalModel'
  import Vue from 'vue'
  
  export default {
    mixins: [externalModel],
    props: {
      selected: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {
        internalModel: [null],
        lastActiveInput: undefined
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          if (!Array.isArray(newVal)) {
            this.internalModel = [null]
          } else {
            this.internalModel = newVal.length >= 1 ? [...newVal] : [null]
          }
        }
      }
    },
    methods: {
      async addInput(index) {
        this.internalModel.splice(index, 0, null)
        await this.$nextTick()
        this.$refs.input[index].focus()
      },
      removeInput(index) {
        this.internalModel.splice(index, 1)
        this.$emit('input', this.internalModel)
      },
      update(index, newVal) {
        Vue.set(this.internalModel, index, newVal)
        this.$emit('input', this.internalModel)
      },
      insert(text) {
        this.$refs.input[this.lastActiveInput].insert(text)
      }
    },
    components: { TemplateFilePicker },
  }
  </script>
  