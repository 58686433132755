<template>
  <v-combobox
      v-model="selectedBlock"
      ref="comboboxRef"
      :items="blockItems"
      :search-input.sync="search"
      :filter="filter"
      :placeholder="$t('blocks.pickerPlaceholder')"
      solo flat
      return-object
      @blur="onblur"
      @input="onBlockSelected"
      append-icon=""
    >
    <template v-slot:item="{ item }">
        <v-list-item v-if="item.header != null">
            {{ item.header }}
        </v-list-item>
      <v-list-item v-else @click="onBlockSelected(item)" :disabled="item.disabled">
        <v-list-item-avatar>
          <v-icon>{{item.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.displayString }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    </v-combobox>
</template>

<script>
import { blockTypes } from '@/constants/blockTypes.js'
export default {
    props: ['autofocus'],
    data() {
        return {
            selectedBlock: null,
            blockPrototypes: null,
            search: null
        }
    },
    watch: {
        autofocus: {
            immediate: true,
            async handler(newVal) {
                this.$nextTick(() => {
                    if (newVal) {
                        this.$refs.comboboxRef.focus()
                        this.$refs.comboboxRef.isMenuActive = true
                    }
                })
            }
        }
    },
    computed: {
        blockItems() {
            return [
                {header: this.$t('blocks.basicHeader')},
                blockTypes.paragraph, // A paragraph of text
                blockTypes.header,
                // blockTypes.page,
                {header: this.$t('blocks.formFieldHeader')},
                blockTypes.text, // Text is often used for general input
                blockTypes.email, // Essential for contact forms
                blockTypes.phonenumber, // Also commonly used in forms
                blockTypes.date, // Date inputs for events, deadlines, etc.
                blockTypes.dateandtime, // Specific for capturing date and time
                blockTypes.decimal, // For financial and other numerical inputs
                blockTypes.integer, // Common for quantity, age, etc.
                blockTypes.enum, // Dropdowns are very common in forms
                blockTypes.boolean, // Toggles for yes/no, true/false questions
                // blockTypes.attachment, // For uploading files
                blockTypes.signature, // For agreements or consents
                blockTypes.enumcollection, // Multiple choice questions
                // blockTypes.user, // User selection from a predefined list
                // blockTypes.entity, // Selecting an entity such as a product or category
                // blockTypes.entitycollection, // Selecting multiple entities
                blockTypes.address, // For complete address inputs
                blockTypes.location, // For location data
                // blockTypes.resource, // For linking resources
                blockTypes.uri, // For URL inputs
                blockTypes.attachmentcollection, // For handling multiple files
                blockTypes.currency, // Specific for monetary values
                // {header: this.$t('blocks.specialHeader')},
                // blockTypes.grid, // To select a grid as reference
                // blockTypes.multitext, // A list of text inputs
                // blockTypes.mimeentity, // Specific use cases like emails
                // blockTypes.mimeentitycollection, // Collections of MIME entities
            ]
        }
    },
    methods: {
        filter (item, queryText) {
            const hasValue = val => val != null ? val.toLowerCase() : ''
            const query = hasValue(queryText)
            return item.displayString?.toLowerCase().includes(query)
        },
        onBlockSelected(block) {
            if (block != null && typeof block === 'object') {
                this.$emit('blockSelected', block.type)
            } else if (typeof block === 'string' && block.length > 0) {
                this.$emit('addParagraphBlock', block)
            }
            this.search = null
            this.$nextTick(() => {
                this.selectedBlock = null
                this.$refs.comboboxRef?.blur()
            })
        },
        async onblur() {
            await this.$nextTick()
            this.selectedBlock = null
            // This delay allows selecting a block
            // by clicking the list before the picker is destroyed
            setTimeout(() => {
                this.$emit('blockSelected', null)
            }, 200)
        }
    },
}
</script>

<style>

</style>