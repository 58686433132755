export function hasPermission(halDocument, requiredLinks) {
  return requiredLinks.every(link => Object.keys(halDocument?._links ?? {}).includes(link))
}

export const PERMISSIONS = {
  addSpace: 'addSpace',
  addGrid: 'addGrid',
  addAccessCredentials: 'addAccessCredentials',
  addHook: 'addHook',
  addField: 'addField',
  addEntity: 'addEntity',
  addForm: 'addForm',
  addView: 'addVirtualGrid',
  updates: 'updates',
  remove: 'remove',
  removeField: 'removeField',
  updateFieldName: 'updateFieldName',
  updateFieldType: 'updateFieldType',
  rename: 'rename',
  forms: 'forms',
  addShare: 'addShare',
  shares: 'shares',
  views: 'virtualGrids',
  addLink: 'addLink',
  updateFilter: 'updateFilter',
  update: 'update',
  submit: 'submit',
  addEditionLink: 'addEditionLink',
  grids: 'grids',
  updateFieldKey: 'updateFieldKey',
  copy: 'copy',
  updateKey: 'updateKey',
  patch: 'patch',
  exportAsCsv: 'exportAsCsv',
  extractToGrid: 'extractToGrid',
  addSView: 'addSView',
  sviews: 'sviews',
  uploadCSV: 'uploadCSV',
  addFlow: 'addFlow',
  addNode: 'addNode',
  addTransition: 'addTransition',
  test: 'test',
  hook: 'hook',
  changePersistentGridPosition: 'changePersistentGridPosition',
  changePosition: 'changePosition',
  addChild: 'addChild'
}