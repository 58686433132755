<template>
  <v-dialog
    v-model="dialog"
    scrollable  
     :overlay="false"
    max-width="80%"
    persistent
    width="720px"
    transition="dialog-transition"
  >
    <v-card>
      <v-row justify="space-between" class="pa-0 ma-4">
        <img src="/logo.svg" width="120px" />
      </v-row>
      <v-card-text>
      <div v-if="step == 0" justify="center" class="pa-0 ma-0 black--text text-center" :class="$vuetify.breakpoint.mobile ? 'text-h6 ma-2' : 'text-h5 ma-8'">
        <div class="font-weight-bold">{{$t('onboarding.welcome')}} </div>
        <div  class="font-weight-bold">{{ fullname }}!</div>
        <div class="mt-2">{{$t('onboarding.purposeTitle')}}</div>
        <v-item-group  v-model="purposeIndex" class="mt-6">
          <template v-for="aPurpose in purposes">
            <v-btn large v-bind:key="aPurpose.title" @click="setPurpose(aPurpose)"  color="primary" class="ma-2" outlined>{{$t(`onboarding.${aPurpose}`)}}</v-btn>
          </template>
        </v-item-group>
      </div>
      <v-row v-if="step == 1" justify="center" class="pa-0 ma-0">
        <iframe class="apptive-embed apptive-dynamic-height" width="100%" :src="decoratedFormLink" frameborder="0"></iframe>
      </v-row>
    </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { base64EncodeUrl } from '@/utils/base64Url.js'
import axios from 'axios'

export default {
  props: [ 'user'],
    data() {
        return {
            step: 0,
            dialog: false,
            purposeIndex: undefined,
            purpose: undefined,
            apptivecomSettings: undefined,
            formSubmitListener: undefined,
            purposes: ['work' , 'private', 'other'],
            hash: undefined
        }
    },
     watch: {
      dialog: {
        immediate: false,
          handler(newVal) {
            if (newVal) {
              this.loadScript()
              this.addListener()
            }
            else {
              this.unloadScript()
              this.removeListener()
            }
          }
        }
    },
    async mounted() {
      // check if user is not onboarded
      const config = await this.$store.dispatch('getConfig')
      if(config.apptivecom) {
        this.apptivecomSettings = config.apptivecom
      }
      else {
        this.apptivecomSettings = {
          de: {
            work: '/api/r/65b0ed87a8e80e7057cbe6db/65b0ef96fc2e234495cbfd7b/BDOmRVrRfuAzfyGa0wsQBjXzZmBseX9X7vNtZmB6CeZmgMscbNj',
            private: '/api/r/65b0ed87a8e80e7057cbe6db/65b24970514f0dc065cc227e/BDOmRVrRfuAzfyGa0wsQBjXzZmBseX9X7vNtZmB6CeZmgMscbNj',
            other: '/api/r/65b0ed87a8e80e7057cbe6db/65b24986514f0d78c8cc227f/BDOmRVrRfuAzfyGa0wsQBjXzZmBseX9X7vNtZmB6CeZmgMscbNj',
          },
          en: {
              work: '/api/r/65b0ed87a8e80e7057cbe6db/65b249b5514f0d107acc2280/BDOmRVrRfuAzfyGa0wsQBjXzZmBseX9X7vNtZmB6CeZmgMscbNj',
              private: '/api/r/65b0ed87a8e80e7057cbe6db/65b249ca514f0d39d1cc2281/BDOmRVrRfuAzfyGa0wsQBjXzZmBseX9X7vNtZmB6CeZmgMscbNj',
              other: '/api/r/65b0ed87a8e80e7057cbe6db/65b249d7514f0d39d1cc2282/BDOmRVrRfuAzfyGa0wsQBjXzZmBseX9X7vNtZmB6CeZmgMscbNj',
          },
          hashField: '65b264e8514f0d81dacc2283',
          grid: '/api/users/65b0ed87a8e80e7057cbe6db/spaces/65b0eddafc2e23ddedcbfd4b/grids/65b265b0514f0d2172cc2286/entities'
          }
        }
        this.hash = await this.generateHash()
        let onbarded = await this.onbarded()
        this.dialog = !onbarded
    },
    computed: {
      fullname() {
        return `${this.user?.firstName} ${ this.user?.lastName }`
      },
      form() {
        return this.localeApptiveComSettings[this.purpose]
      },
      localStorageKey() {
        return `onboardingShown - ${this.hash}`
      },
      localOnbardingState() {
        let data = localStorage.getItem( this.localStorageKey )
        let state = JSON.parse(data) 
        return state
      },
      decoratedFormLink() {
        //add hash
        let params = {}
        params[this.apptivecomSettings.hashField] = this.hash
        const data = JSON.stringify(params)
        
        const url = new URL( `${window.location.origin}${this.form}`)
        
        // Add query parameters using URLSearchParams
        const searchParams = new URLSearchParams()
        searchParams.set('authtoken', this.token)
        searchParams.set('embedded', true)
        searchParams.set('data', base64EncodeUrl(data))
        
        // Append the search params to the URL
        url.search = searchParams.toString()
        return url.toString()
      },
      localeApptiveComSettings(){
        return this.apptivecomSettings[this.$i18n.locale]
      },
      token(){
        return this.$store.getters.jsonWebToken
      },
    },
    methods: {
      async onbarded() {
        let onboarded = true
        if(this.localOnbardingState === true) {
          return true
        }
        try {
          const response = await axios.get(this.apptivecomSettings.grid, {
            params: {
              filter: `{"${this.apptivecomSettings.hashField}":"${this.hash}"}`,
              layout: 'key'
            },
            headers: {
              'Content-Type': 'application/json'
            }
          })

          if (response.status === 200) {
            const responseJson = response.data
            if (responseJson.size === 0) {
              // user not found in the database
              onboarded = false
            }
            if (responseJson.size === 1) {
                // save state local to reduce server load
              localStorage.setItem( this.localStorageKey , true )
            }
          }
        } catch (error) {
          // Handle error appropriately
          console.error('Error fetching data:', error)
          onboarded = false
        }
        return onboarded
      },

      async generateHash() {
        var digest = await crypto.subtle.digest('SHA-256',
        new TextEncoder().encode(this.user.id))
        return  btoa(String.fromCharCode(...new Uint8Array(digest)))
                .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
      },
      setPurpose(aPurpose) {
        this.purpose = aPurpose
        this.step = 1
      },
      addListener() {
        // listen to method to hide dialog once the form did submit
        this.formSubmitListener = (event) => {
          var message = event.data
          if (message && message.key === 'apptiveEmbedSubmitActionTriggered') {
            this.dialog = false
          }
        }
        window.addEventListener('message', this.formSubmitListener)
      },
      removeListener() {
        window.removeEventListener('message', this.formSubmitListener)
      },
      loadScript() {
        // Create a script tag and add it to the head of the document
        this.script = document.createElement('script')
        this.script.src = window.location.origin + '/apptive-embedded.js'
        document.head.appendChild(this.script)
      },
      unloadScript() {
        // Remove the script tag from the document
        this.script.remove()
      },
      nextStep() {
        this.step++
      },
  }
}
</script>

<style>

</style>




