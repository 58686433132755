<template>
<v-dialog
  :disabled="disabled"
  v-model="externalModel"
  max-width="400px"
>
  <v-card>
    <v-card-title
      class="d-flex justify-center text-h5"
      v-html="$t('dialogs.gridKeysTitle', {gridTitle})"
    />
    <v-card-text class="d-flex flex-column" :style="{gap: '20px'}">
      <div
        v-for="(field, index) in fields"
        :key="index"
      >
        <div
          class="d-flex align-center"
        >
          <v-icon small>{{field.columnType.typeIcon}}</v-icon>
          <div
            class="ml-2 textOverflow text-subtitle-2 grey--text text--darken-2"
          >{{field.name}}</div>
        </div>
        <v-text-field
          outlined hide-details
          clearable
          :value="field.key"
          @input="value => form[field.id] = value"
          @blur="setKey(field)"
          :loading="loading[field.id]"
          data-testId="headerCell-text"
        />
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  props: {
    virtualGrid: null,
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      form: {},
      loading: {}
    }
  },
  computed: {
    fields() {
      return this.virtualGrid?.fields ?? []
    },
    gridTitle() {
      return this.virtualGrid?.name
    }
  },
  watch: {
    fields: {
      immediate: true,
      handler(newVal) {
        newVal.forEach(field => {
          this.form[field.id] = field.key
        })
      }
    }
  },
  methods: {
    setKey(field) {
      const keyInputValue = this.form[field.id]
      this.$set(this.loading, field.id, true)
      field.setKey(this.virtualGrid.uri, keyInputValue)
        .finally(() => {
          this.$set(this.loading, field.id, false)
        })
    }
  }
}
</script>
