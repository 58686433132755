<template>
  <div class="entity-ref-card d-flex gap-3 pl-3 align-start" :class="editOnClick ? 'pointer' : ''" :style="cardStyle" @click="editEntity">
    <div v-if="empty" class="empty-state">{{$t('entityPicker.empty')}}</div>
    <component
      ref="component"
      v-for="(item, index) in items"
      :key="index"
      class="field-attribute py-2"
      :style="attributeStyle"
      :attribute="item.value"
      :is="item.component"
      :renderIfEmpty="true"
      :animateIfEmpty="false"
      :loading="loading"
      freeHeight
      v-bind="$attrs"
      :hideTypeIcon="true"
    />
    <img
      v-if="thumbnailSrc"
      class="thumbnail"
      :src="thumbnailSrc"
    />
    <EditEntityDialog
      v-model="editEntityDialog"
      :virtualGrid="grid"
      :entity="loadedEntity"
    />
  </div>
</template>

<script>
import {columnTypes} from '@/constants/columnTypes.js'
export default {
  props: {
    entity: null,
    entityUri: null,
    grid: null,
    selected: {
      type: Boolean,
      default: () => false
    },
    editOnClick: {
      type: Boolean,
      default: () => false
    },
    empty: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loadedEntity: undefined,
      loading: false,
      editEntityDialog: false
    }
  },
  watch: {
    entityUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null || this.entity != null) return
        this.loading = true
        try {
          this.loadedEntity = await this.$store.dispatch('AGFetchEntityOperation', newVal)
        } finally {
          this.loading = false
        }
      }
    },
    entity: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null) return
        this.loadedEntity = newVal
      }
    },
    editEntityDialog: {
      async handler(newVal) {
        if (!newVal) return
        this.loading = true
        try {
          this.loadedEntity = await this.$store.dispatch('AGFetchEntityOperation', this.entityUri || this.entity.uri)
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    items() {
      if (this.loadedEntity == null || this.grid == null) {
        return []
      }
      return this.loadedEntity.fields
        .slice(0, 4)
        .map((field, index) => ({
          component: this.grid.fields[index].columnType.kanbanAttributeComponent,
          value: {
            key: this.grid.fields[index].name,
            value: this.grid.displayFormat(field, index),
            fieldValue: field,
            property: this.grid.fields[index].columnType
          }
        }))
        .filter((_, index) => index != this.attachmentFieldIndex)
    },
    attachmentFieldIndex() {
      return this.grid.fields.findIndex(field => field.columnType.name === columnTypes.attachments.name)
    },
    thumbnailSrc() {
      if (this.loadedEntity == null || this.grid == null) {
        return undefined
      }
      if (this.attachmentFieldIndex < 0) {
        return undefined
      }
      return this.loadedEntity.fields[this.attachmentFieldIndex]?.[0]?.smallThumbnail
    },
    attributeStyle() {
      return {
        'flex-basis': `${100 / (this.items.length)}%`,
      }
    },
    cardStyle() {
      return {
        'border-color': this.selected ? this.$vuetify.theme.themes.light.primary : 'rgb(211, 210, 212)'
      }
    },
  },
  methods: {
    editEntity() {
      if (!this.editOnClick) return
      this.editEntityDialog = true
    }
  },
  components: {
    EditEntityDialog: () => import('@/components/gridView/EditEntityDialog.vue'),
  }
}
</script>

<style scoped>
.entity-ref-card {
  border-radius: 6px;
  border: solid;
  border-width: 1px;
  width: 100%;
  height: 74px;
  background-color: white;
}

.field-attribute {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
}

.thumbnail {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 80px;
  min-height: 72px;
  height: 100%;
  overflow: hidden;
  flex: none;
  object-fit: cover;
}

.pointer {
  cursor: pointer;
}

.empty-state {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>