<template>
  <AppBar
    :space="space"
    :extension-height="extensionHeight"
    height="48px"
    app
    flat
    clipped-right
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      class="ml-auto"
      @click="sideNavigation = !sideNavigation"
    />

    <v-toolbar-title class="font-weight-medium">
      <EditableText
        :readonly="!canRenameSpace"
        class="full-width textOverflow pa-1"
        :text="spaceTitle"
        @submit="renameSpace"
        :maxLength="maxSpaceNameLength"
        data-testId="spaceTitle"
      />
    </v-toolbar-title>
    <SpaceMoreMenu
      v-if="space != null"
      :spaceUri="spaceUri"
      :spaceName="spaceTitle"
      :disableRename="!canRenameSpace"
      :disableShare="!canShareSpace"
      :disableUploadCsv="!canUploadCsv"
    />
    <v-spacer></v-spacer>

    <div v-if="alphaMode">
      <v-btn
        class="mr-2"
        icon
        width="35"
        height="35"
        @click="toggleApptiveDrawer"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </div>
    <SpaceCollaborators
      v-if="space != null"
      :space="space"
      class="mr-3"
    />
    <UserProfile v-if="userLoggedIn" />


    <template v-slot:extension>
      <slot name="extension" />
    </template>
  </AppBar>
</template>

<script>
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import EditableText from '@/components/EditableText.vue'
import SpaceMoreMenu from '@/components/space/SpaceMoreMenu.vue'
import SpaceCollaborators from '@/components/SpaceCollaborators.vue'
import UserProfile from '@/components/user/UserProfile.vue'
import AppBar from '@/components/AppBar.vue'

export default {
  props: {
    spaceUri: {
      type: String
    },
    extension: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    renameSpace(newName) {
      if (this.space.name == newName) {
        return
      }
      return this.$store.dispatch('AGRenameSpaceOperation', {
        spaceUri: this.space.uri,
        newName: newName
      })
    },
    toggleApptiveDrawer() {
      this.$store.commit('setApptivesDrawer', !this.$store.state.apptivesDrawer)
    },
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    spaceTitle() {
      return this.space?.title()
    },
    canRenameSpace() {
      return hasPermission(this.space, [PERMISSIONS.patch])
    },
    canShareSpace() {
      return hasPermission(this.space, [PERMISSIONS.addShare])
    },
    canUploadCsv() {
      return hasPermission(this.space, [PERMISSIONS.uploadCSV])
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    sideNavigation: {
      get() {
        return this.$store.state.sideNavigation
      },
      set(newVal) {
        return this.$store.commit('setSideNavigation', newVal)
      }
    },
    alphaMode() {
      return this.$store.getters.alphaMode
    },
    extensionHeight() {
      return this.extension ? '32px' : null
    },
    maxSpaceNameLength() {
      return this.$apptive?.constants.nameLengths.space
    }
  },
  components: {
    EditableText,
    SpaceMoreMenu,
    SpaceCollaborators,
    UserProfile,
    AppBar
  }
}
</script>

<style
  lang="scss"
  scoped
></style>