<template>
  <BaseNodeComponent v-model="externalModel" v-bind="$attrs" @blur="$emit('blur')">
    <v-checkbox
     v-model="externalModel"
     v-bind="$attrs"
     v-on="$listeners"
     @change="$emit('blur')"
     hide-details
    />
  </BaseNodeComponent>
</template>

<script>
import BaseNodeComponent from '@/components/flow/BaseNodeComponent.vue'
import externalModel from '@/mixins/externalModel.js'

export default {
    mixins: [externalModel],
    components: { BaseNodeComponent }
}
</script>