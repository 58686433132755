<template>
<div>
  <div
    class="d-flex flex-row align-center"
    v-for="(value, index) in internalModel"
    :key="index"
  >
    <template-input
      class="mt-1 flex-grow-1"
      :value="value"
      @input="newVal => update(index, newVal)"
      @keyup.enter.native.stop="() => addInput(index + 1)"
      @click.native="lastActiveInput = index"
      @focus="lastActiveInput = index; $emit('focus')"
      @blur="$emit('blur')"
      :selected="selected && lastActiveInput === index"
      v-bind="$attrs"
      ref="input"
      data-testid="templateInput"
    />
    <v-btn
      v-if="internalModel.length > 1"
      @click="() => removeInput(index)"
      text icon small class="ml-1"
      >
      <v-icon small>mdi-close</v-icon>
    </v-btn>
  </div>
  <v-btn
    @click="() => addInput(internalModel.length)"
    text icon small
    data-testid="addInputButton"
  >
    <v-icon small>mdi-plus</v-icon>
  </v-btn>
</div>
</template>

<script>
import TemplateInput from './TemplateInput.vue'
import externalModel from '../../mixins/externalModel'
import Vue from 'vue'

export default {
  mixins: [externalModel],
  props: {
    selected: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      internalModel: [''],
      lastActiveInput: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (!Array.isArray(newVal)) {
          this.internalModel = ['']
        } else {
          this.internalModel = newVal.length >= 1 ? [...newVal] : ['']
        }
      }
    }
  },
  methods: {
    async addInput(index) {
      this.internalModel.splice(index, 0, '')
      await this.$nextTick()
      this.$refs.input[index].focus()
    },
    removeInput(index) {
      this.internalModel.splice(index, 1)
      this.$emit('input', this.internalModel)
    },
    update(index, newVal) {
      Vue.set(this.internalModel, index, newVal)
      const noEmptyValues = this.internalModel.filter(item => item != null && item.length > 0)
      const noEmptyArray = noEmptyValues.length > 0 ? noEmptyValues : null
      this.$emit('input', noEmptyArray)
    },
    insert(text) {
      const lastActiveInput = this.$refs['input'][this.lastActiveInput]
      lastActiveInput.insert(text)
    }
  },
  components: { TemplateInput },
}
</script>
