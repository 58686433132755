import ApiClient from '@/ApiClient/index.js'
import axios from 'axios'
import store from '@/store/store'

async function genAttachmentUrl(fileName) {
  const config = await store().dispatch('getAttachmentsConfig')
  return `${config.apiEndpoint}/${fileName}`
}

export async function uploadFileToS3(name, type, file, unauthenticated = false, progress = () => {}) {
  const config = await store().dispatch('getAttachmentsConfig')
  //upload to S3
  const endPoint = unauthenticated ? config.unauthenticatedSignedUrlEndpoint : config.signedUrlEndpoint
  const uploadUrlResponse = await ApiClient.getUri(endPoint, {
    fileName: name,
    fileType: type
  })
  const uploadUrl = uploadUrlResponse.data.uploadURL
  await axios
    .put(uploadUrl, file, {
      onUploadProgress: progressEvent => {
        progress(
          progressEvent.lengthComputable,
          progressEvent.loaded,
          progressEvent.total
        )
      }
    })
  return genAttachmentUrl(name)
}