<template>
        <v-dialog
            v-model="dialog"
            scrollable
            :fullscreen="fullscreen"
            persistent :overlay="false"
            max-width="800px"
            transition="dialog-transition"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="primary"
            rounded outlined
            large
            elevation="5"
            @click="open"
            v-on="on" v-bind="attrs"
            class="ml-2"
            >
                <v-icon data-testid="testRunFlowButton_Waiting" >
                mdi-file-document-outline
                </v-icon>
                <span>Open</span>
            </v-btn>
        </template>
        <v-sheet class="d-flex flex-column">
            <v-toolbar flat >
            <v-spacer></v-spacer>
            <v-btn icon @click="fullscreen = !fullscreen" color="accent"><v-icon>mdi-arrow-expand</v-icon></v-btn>
            <v-btn icon @click="dialog = false" color="accent"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <!-- browser window -->
        <safari-window :url="url" class="ma-3 browser">
            <FlowInstanceLinkView v-if="uri" :uri="uri"></FlowInstanceLinkView>
        </safari-window>
        </v-sheet>

        </v-dialog>
</template>

<script>
import FlowInstanceLinkView from '@/components/FlowInstanceLinkView.vue'
import SafariWindow from '@/components/SafariWindow.vue'

export default {
    props: {
        link: null,
  },

  data() {
    return {
        dialog: false,
        fullscreen: false,
        uri: undefined
    }
  },
  watch: {
    link: {
      immediate: false,
      async handler(newVal) {
        if (newVal == null) {
          return
        }
        this.dialog = true
      }
    }
  },
  methods: {
    async open() {
      // get the flow instance link 
      // open it
      this.uri = this.link
      // show modal with FlowinstanceLinkView
    },
  },
  computed: {
    url() {
        return window.location.origin + this.uri
    }
  },
  components: {
    FlowInstanceLinkView,
    SafariWindow
  }
}
</script>

<style>
.browser {
    min-height: 70vh;
}
</style>