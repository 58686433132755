<template>
  <TemplateSelect
    v-model="externalModel"
    v-bind="$attrs"
    v-on="$listeners"
    :multiple="true"
  />
</template>

<script>
import TemplateSelect from '@/components/flow/TemplateSelect.vue'
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [externalModel],
  components: { TemplateSelect }
}
</script>

<style lang="scss" scoped>

</style>