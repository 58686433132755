<template>
<div
  v-if="attribute.value || renderIfEmpty"
  class="text-no-wrap text-truncate d-flex flex-column"
  :style="style"
>
  <div>
    <v-icon
      v-if="!hideTypeIcon" 
      small
      class="mr-1"
      :color="iconColor"
    >
      {{ computedIcon }}
    </v-icon>
    <span v-if="!hideFieldName" class="kanban-column-title font-weight-medium text-caption"> {{attribute.key}}</span>
  </div>
  <div v-if="!hideValue" style="min-height: 0;">
    <slot v-if="!loading && attribute.value" :attribute="attribute">
      <div class="text-no-wrap text-truncate text-body-2">{{attribute.value}}</div>
    </slot>
    <v-skeleton-loader v-else type="text" :boilerplate="!loading && !animateIfEmpty" width="30"/>
  </div>
</div>
</template>

<script>
export default {
  props: {
    attribute: null,
    renderIfEmpty: null,
    animateIfEmpty: null,
    hideTypeIcon: {
      type: Boolean,
      default: () => false
    },
    hideFieldName: {
      type: Boolean,
      default: () => false
    },
    hideValue: {
      type: Boolean,
      default: () => false
    },
    height: {
      type: Number,
      default: () => 40
    },
    titleHeight: {
      type: Number,
      default: () => 18
    },
    icon: null,
    iconColor: {
      default: () => 'grey'
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    freeHeight: {
      type: Boolean,
      default: () => false
    },
  },
  computed: {
    computedIcon() {
      if (this.icon != null) {
        return this.icon
      }
      var icon = null
      try {
        icon = this.attribute.property.typeIcon
      } catch (error) {
        this.$apptiveErrorReporter.captureException(error)
        icon = 'mdi-progress-download'
      }
      return icon
    },
    minHeight() {
      if (this.freeHeight) return undefined
      return (this.hideTypeIcon && this.hideFieldName) ? this.height - this.titleHeight : this.height
    },
    maxHeight() {
      if (this.freeHeight) return undefined
      return this.renderIfEmpty ? this.height - this.titleHeight : this.height
    },
    style() {
        return {
            'min-height': `${this.minHeight}px !important`,
            'height': `${this.maxHeight}px !important`,
        }
    }
  }
}
</script>

<style scoped>
.kanban-column-title {
  color: grey;
}
</style>