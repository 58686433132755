<template>
<v-card class="kanban-state-selection">
  <template v-if="items && items.length > 0">
    <v-card-title>{{$t('kanban.stateSelection.title')}}</v-card-title>
    <v-card-subtitle>{{$t('kanban.stateSelection.text')}}</v-card-subtitle>
    <v-card-text>
      <v-select
        v-if="items && items.length > 0"
        solo flat outlined dense
        v-model="selectModel"
        @input="fieldIdSelected"
        :items="items"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </v-card-text>
  </template>
  <template v-else>
    <v-card-title>{{$t('kanban.stateSelection.createTitle')}}</v-card-title>
    <v-card-subtitle>{{$t('kanban.stateSelection.noEnum')}}</v-card-subtitle>
    <v-card-text>
      <v-sheet rounded color="background pa-3">
        <EnumOptions class="pa-0" v-model="newEnum"/>
      </v-sheet>
      <div class="d-flex justify-end">
        <v-btn
          :disabled="submitDisabled"
          class="mt-3"
          color="primary"
          @click="createEnum"
          :loading="enumLoading"
        >{{$t('kanban.stateSelection.submitNewEnum')}}</v-btn>
      </div>
    </v-card-text>
  </template>
  <slot></slot>
</v-card>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'
import { columnTypes } from '@/constants/columnTypes.js'
import EnumOptions from '@/components/gridView/EnumOptions.vue'


export default {
  props: ['virtualGrid'],
  mixins: [externalModel],
  data() {
    return {
      selectModel: undefined,
      showEnumOptions: false,
      enumLoading: false,
      newEnum: columnTypes.enum.state.defaultValue()
    }
  },
  watch: {
    externalModel: {
      immediate: true,
      handler(newVal) {
        this.selectModel = this.items.find(item => item.value === newVal)
      }
    }
  },
  computed: {
    items() {
      if (this.virtualGrid == null) {
        return []
      }
      return this.virtualGrid.parentGrid().fields
        .filter(field => field.columnType.name === columnTypes.enum.name)
        .map(field => ({
          text: field.name,
          value: field.id
        }))
    },
    submitDisabled() {
      return !columnTypes.enum.state.validate(this.newEnum)
    }
  },
  methods: {
    createEnum() {
      this.enumLoading = true
      this.virtualGrid.addColumn(undefined, {
        type: {
          name: columnTypes.enum.name,
          ...columnTypes.enum.state.attributesFrom(this.newEnum)
        },
      })
        .then(() => this.virtualGrid.reload())
        .then(() => this.externalModel = this.items[this.items.length - 1].value)
        .finally(() => this.enumLoading = false)
    },
    async fieldIdSelected(fieldId) {
      this.externalModel = fieldId
    }
  },
  components: {
    EnumOptions
  }
}
</script>

<style scoped>
.kanban-state-selection {
  max-width: 400px;
}
</style>
