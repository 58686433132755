/* global ASParser ApptiveScript ASFlowInstanceScope ASFunctionCallNode ASFormattingVisitor ASEntityScope*/

export class ASExpression {
  constructor(string) {
    this.originalString = string
    this.value = ASParser.as_parse_(string)
  }

  get displayString() {
    if (this.value.arguments != null) {
      return this.value.arguments[0].value.toString()
    }

    return this.value.property
  }

  typeInFlowTestInstance(flow) {
    const scope = scopeFrom(flow)
    return ApptiveScript.as_returnType_inScope_(this.originalString, scope)
  }

  receiverTypeInFlowTestInstance(flow) {
    const scope = scopeFrom(flow)
    return ApptiveScript.as_receiverReturnType_inScope_(this.originalString, scope)
  }

  get isFunctionCall() {
    return this.value instanceof ASFunctionCallNode
  }

  get receiverCodeString() {
    return this.value.function.receiver.as_codeString()
  }

  get functionName() {
    return this.value.function.property
  }

  get firstArgument() {
    return this.value.arguments[0]?.value
  }
}

export function scopeFrom(flow) {
  const scope = new ASFlowInstanceScope()
  scope.as_onStepDo_((nodeId) => flow.testInstance.steps.find(step => step._links.node.href.split('/').pop() === nodeId))
  return scope
}

export function listTypes() {
  return  ApptiveScript.as_listTypes()
}

export class ASValueTreeNode {
  constructor(object) {
    this.decorated = object
    if (object == null) {
      return
    }
    this.value = 'as_apptiveScriptQuery' in object ? object : object.as_asApptiveScriptValueTreeNode()
  }

  get query() {
    return this.value?.as_apptiveScriptQuery()
  }

  get children() {
    return this.value?.as_children().map(child => new ASValueTreeNode(child))
  }
}

import Token from '@/components/flow/Token.vue'

export class ExpressionVisitor extends ASFormattingVisitor {
  constructor(grid) {
    super()
    this.grid = grid
    this.tokens = []
  }

  renderToken(tokenProps) {
    return tokenProps
  }

  as_visitFunctionCallNode_(node) {
    if (node.function.property === 'fieldValue') {
      const field = this.grid.fields.find(field => field.id === node.arguments[0].value)
      const div = this.renderToken({
          text: (field?.name) ?? 'Unknown Field',
          color: '#007affff',
          value: node.as_codeString()
      })
      this.tokens.push(div)
    } else {
      super.as_visitFunctionCallNode_(node)
    }
  }

  as_visitPropertyLookupNode_(node) {
    const div = this.renderToken({
      text: node.property,
      color: 'purple',
      value: node.property
    })
    super.as_visitPropertyLookupNode_(node)
    this.tokens.push(div)
  }

  as_visitLiteralValueNode_(node) {
    const div = this.renderToken({
      text: node.value,
      color: '#30d158ff',
      value: node.as_codeString()
    })
    this.tokens.push(div)
    super.as_visitLiteralValueNode_(node)
  }

  as_processArgumentListSeparator() {
    const div = this.renderToken({
      text: ',',
      color: '#616161',
      value: ','
    })
    this.tokens.push(div)
  }

  as_processBinarySelector_(node) {
    const div = this.renderToken({
      text: node,
      color: '#5856D6',
      value: node
    })
    this.tokens.push(div)
  }

  as_processCloseParentheses() {
    const div = this.renderToken({
      text: ')',
      color: '#616161',
      value: ')',
    })
    this.tokens.push(div)
  }

  as_processDot() {
    const div = this.renderToken({
      text: '.',
      color: '#616161',
      value: '.',
    })
    this.tokens.push(div)
  }

  as_processOpenParentheses() {
    const div = this.renderToken({
      text: '(',
      color: '#616161',
      value: '(',
    })
    this.tokens.push(div)
  }

  as_visitNode_(node) {
    super.as_visitNode_(node)
  }
}

export class RenderFunctionExpressionVisitor extends ExpressionVisitor {
  constructor(h, grid) {
    super()
    this.h = h
    this.grid = grid
    this.tokens = []
  }

  renderToken(tokenProps) {
    return this.h(Token, {
      props: tokenProps
    })
  }
}

export class EntityScope extends ASEntityScope {
  constructor(grid, entity) {
    super()
    this.grid = grid
    this.entity = entity
  }

  as_fieldValueForFieldId_(fieldId) {
    const fieldIndex = this.grid.fields.findIndex(field => field.id === fieldId)
    return this.entity.fields[fieldIndex]
  }
}

export class PathDisplayVisitor extends ASFormattingVisitor {
  constructor(flow) {
    super()
    this.pathString = ''
    this.flow = flow
  }

  as_visitFunctionCallNode_(node) {
    if (node.function.property === 'step') {
      const flowNode = this.flow.nodes.find(item => item.id === node.arguments[0].value)
      this.pathString += `[${flowNode.name}]`
    } else {
      super.as_visitFunctionCallNode_(node)
    }
  }

  as_visitPropertyLookupNode_(node) {
    super.as_visitPropertyLookupNode_(node)
    if (node.property !== 'get') {
      this.pathString += `.${node.property}`
    }
  }

  as_visitLiteralValueNode_(node) {
    this.pathString += `.${node.value}`
    super.as_visitLiteralValueNode_(node)
  }

  as_visitNode_(node) {
    super.as_visitNode_(node)
  }
}