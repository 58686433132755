<template>
  <div style="height: 100%;">
    <SpaceAppBar :spaceUri="spaceUri" />
    <div
      class="d-flex flex-row"
      style="height: 100%;"
    >
      <div
        class="navigation-list"
        style="min-width: 240px; max-width: 300px; overflow: auto"
      >
        <div class="d-flex align-center px-3">
          <v-subheader class="pl-0 pr-3">{{ $t('blocks.pages') }}</v-subheader>
          <v-spacer />
          <v-btn
            small
            icon
            @click="newBlock"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-list>
          <v-list-item
            v-for="(block, index) in blocks"
            :key="index"
            :to="{ name: 'BlockPage', params: { blockUri: block.uri } }"
          >
            <v-list-item-title>
              <EditableText
                :text="blockName(block, index)"
                @submit="newName => patchName(block, newName)"
                :readonly="!(block instanceof PageBlock)"
              />
            </v-list-item-title>
            <v-list-item-action>
              <PageBlockMenu
                :block="block"
                @blockDeleted="() => blockDeleted(block)"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <router-view />
    </div>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import PageBlockMenu from '@/components/block/PageBlockMenu.vue'
import PageBlock from '@/store/models/blocks/PageBlock'
import SpaceAppBar from '@/views/SpaceAppBar.vue'

export default {
  props: {
    spaceUri: null,
  },
  data() {
    return {
      loading: false,
      blocks: [],
      PageBlock
    }
  },
  watch: {
    spaceUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: newVal
          })
          await this.listBlocks()
          if ( this.$route.name === 'BlockEditor' ) {
            this.initialNavigation()
          }
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
  },
  methods: {
    initialNavigation() {
      if (this.blocks.length >= 1) {
        this.$router.replace({ name: 'BlockPage', params: { blockUri: this.blocks[ 0 ].uri } })
      }
    },
    async listBlocks() {
      this.blocks = await this.$store.dispatch('AGListSpaceBlocks', this.space)
    },
    async newBlock() {
      const response = await this.$store.dispatch('AGAddSpacePageBlock', this.space)
      this.listBlocks()
      this.$router.push({ name: 'BlockPage', params: { blockUri: response.headers.location } })
    },
    async loadBlock(block) {
      await this.$store.dispatch('AGReadBlock', { blockUri: block.getLink('self')})
    },
    blockName(block, index) {
      return block.name || `block ${index + 1}`
    },
    async patchName(block, newName) {
      await block.patchName(newName)
      block.name = newName
    },
    async blockDeleted(block) {
      await this.listBlocks()
      if (this.$route.name === 'BlockPage' && this.$route.params.blockUri === block.uri) {
        this.initialNavigation()
      }
    }
  },
  components: {
    // BlockPage,
    EditableText,
    PageBlockMenu,
    SpaceAppBar
  }
}
</script>

<style
  lang="scss"
  scoped
>
.navigation-list {
  background-color: white;
  border-right: 1px solid #e4e5e4;
}
</style>