<template>
  <div class="">
    <svg class="line">
      <defs>
    <linearGradient :id="`gradent-${lineId}`" gradientUnits="userSpaceOnUse" :x1="x1" :y1="y1" :x2="x2" :y2="y2">
      <stop offset="0%" :style="`stop-color:${fromNode.color};stop-opacity:1`" />
      <stop offset="100%" :style="`stop-color:${endColor};stop-opacity:1`" />
    </linearGradient>
  </defs>
      <line
        v-if="isParallel"
        ref="transitionLine"
        class="linePath"
        :id="lineId"
        :x1="x1"
        :y1="y1"
        :x2="x2"
        :y2="y2"
        :stroke="'#AED9A7AA'"
        :stroke-width="nodeSize"
        stroke-linecap="round"
      />
      <line
        ref="transitionLine"
        class="linePath"
        :id="lineId"
        :x1="x1"
        :y1="y1"
        :x2="x2"
        :y2="y2"
        :stroke="`url(#gradent-${this.lineId})`"
        :stroke-width="10"
        stroke-linecap="round"
        :stroke-dasharray="'0, 20'"
      />
      <circle
        ref="flowDirectionCircle"
        :id="`circle-${lineId}`"
        :cx="startCircleCenter.x"
        :cy="startCircleCenter.y"
        :hidden="transition.unconnected()"
        r="0"
        :fill="fromNode.color"
      >
        <animateTransform
           ref="animation"
           attributeName="transform"
           type="translate"
           begin="indefinite"
           from="'0,0'"
           :to="`${endCircleCenter.x - startCircleCenter.x},${endCircleCenter.y - startCircleCenter.y}`"
           :dur="`${lineAnimationDuration}s`"
           restart="whenNotActive"
           fill="remove"
           calcMode="spline"
           keyTimes="0; 1"
           keySplines="0.1 0.4 0.5 1"
           repeatCount="1"
           v-on:beginEvent="$refs.flowDirectionCircle.setAttribute('r', 8)"
           v-on:endEvent="$refs.flowDirectionCircle.setAttribute('r', 0)"
        />
      </circle>
    </svg>

    <v-hover v-slot="{ hover }" v-if="!transition.unconnected() && line.length > 100">
      <div ref="filter" class="filter" :style="filterStyle">
        <FlowTransitionMenu
          :flow="flow"
          :visible="hover || transition.condition != null"
          :transition="transition"
          @deleteTransition="deleteTransition"
        />
      </div>
    </v-hover>
    <div class="transition-description" :style="descriptionStyle">{{transition.description}}</div>
    <flow-transition-connector
      :disabled="!canAddTransition"
      :color="fromNode.color"
      :center="startCircleCenter"
      radius="15"
    />
    <flow-transition-connector
      :disabled="!canAddTransition"
      v-on:moved="endCircleMoved"
      v-on:movedFinish="endCircleFinishedMoving"
      @pointerdown.native="endClicked"
      :unconnected="transition.unconnected()"
      :center="endCircleCenter"
      :end="true"
      radius="15"
      :color="endColor"
      :highlight="highlightConnectors"
    />
  </div>
</template>

<script>
import flowLayoutBus from '@/components/flow/flowLayoutBus.js'
import FlowTransitionConnector from '@/components/flow/FlowTransitionConnector.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import FlowTransitionMenu from './FlowTransitionMenu.vue'
import FlowParallelNode from '@/store/models/flow/nodes/FlowParallelNode'

export default {
  props: {
    flow: null,
    transition: null,
    fromNode: null,
    nodeSize: null,
    highlightConnectors: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      line: {
        centerX: 0,
        centerY: 0,
        length: 0
      },
      startCircleCenter: { x: null, y: null },
      endCircleCenter: { x: null, y: null },
      nodeDistancesMap: {},
      lineAnimationDuration: 2,
      listener: {
        'flow-draw-transition': (data) => this.drawTransition(data),
        'I am a node and be ready to connect with you': (data) => this.storeNodeWithDistance(data),
        'I am node thank you for asking me I connected us!': (data) => this.clearDisTanceMap(data),
        'animateTransition':(data) => this.animateTransition(data)
      },
      endColor: null,
      endMoving: false
    }
  },
  mounted() {
    Object.keys(this.listener).forEach((key) => {
      flowLayoutBus.$on( key , this.listener[key] )
    })
    this.draw()
  },
  beforeDestroy(){
    Object.keys(this.listener).forEach((key) => {
      flowLayoutBus.$off( key , this.listener[key] )
    })
  },
  computed: {
    canAddTransition() {
      return hasPermission(this.flow, [PERMISSIONS.addTransition])
    },
    fromNodeId() {
      return this.fromNode.id
    },
    toNodeId() {
      return this.transition.targetId
    },
    toNode(){
      return this.$store.getters.nodeWithUri(this.transition.target)
    },
    transitionIndex() {
      return this.fromNode.indexOfTransiton(this.transition)
    },
    lineId() {
      return `line-fromNode-${this.fromNodeId}-toNode-${this.toNodeId}-outgoingIndex-${this.transitionIndex}`
    },
    filterStyle() {
      return {
        top: `${this.line.centerY - 15}px`,
        left: `${this.line.centerX - 15}px`,
        // hide if nodes are too close together
        opacity: this.line.length < 110 ? 0 : 1,
      }
    },
    descriptionStyle() {
      return {
        top: `${this.line.centerY - (this.nodeSize / 2)}px`,
        left: `${this.line.centerX}px`,
        // hide if nodes are too close together
        opacity: this.line.length < 110 ? 0 : 1,
      }
    },
    x1() {
      return (this.fromNode?.layout.position.x ?? 0) + (this.nodeSize / 2)
    },
    y1() {
      return (this.fromNode?.layout.position.y ?? 0) + (this.nodeSize / 2)
    },
    x2() {
      if (this.toNode != null) {
        return (this.toNode?.layout.position.x ?? 0) + (this.nodeSize / 2)
      } else {
        return this.endCircleCenter.x
      }
    },
    y2() {
      if (this.toNode != null) {
        return (this.toNode?.layout.position.y ?? 0) + (this.nodeSize / 2)
      } else {
        return this.endCircleCenter.y
      }
    },
    positionBlob() {
      return `${this.x1}_${this.x2}_${this.y1}_${this.y2}`
    },
    isParallel() {
      if (this.fromNode instanceof FlowParallelNode) {
        return true
      }
      const ancestors = this.flow.ancestorsOf(this.fromNode)
      return ancestors.some(node => node instanceof FlowParallelNode)
    }
  },
  watch: {
    positionBlob() {
      if (!this.endMoving) {
        this.draw()
      }
    }
  },
  methods: {
    updateConnectorEndColor() {
      this.endColor = this.connectorEndColor()
    },
    connectorEndColor(){
      return this.toNode?.color || this.nextNodeToMe()?.color || this.fromNode.color
    },
    animateTransition(data) {
      if (data.nodeId == this.fromNodeId) {
        this.lineAnimationDuration = this.calcLineAnimationDuration()
        this.$refs.animation?.beginElement()
      }
    },
    clearDisTanceMap(data) {
      if (data.transition == this.transition) {
        this.nodeDistancesMap = {}
      }
    },
    storeNodeWithDistance(data) {
      if (data.transition == this.transition) {
        let distance = this.distanceToNode(data.node)
        this.nodeDistancesMap[`${data.node.id}`] = {
          node: data.node,
          distance: distance
        }
      }
    },
    drawTransition(data) {
      if (data.nodeId === this.fromNodeId || data.nodeId === this.toNodeId) {
        this.draw()
      }
    },
    async draw() {
      await this.$nextTick()
      this.drawCircles()
      this.updateConnectorEndColor()
      this.updateLineData()
    },
    endCircleMoved(position) {
      if (!this.canAddTransition) {
        return
      }
      this.endMoving = true
      this.endCircleCenter = position
      this.$nextTick(() => {
        this.drawFromCircle()
      })
      // annaounce
      flowLayoutBus.$emit('I am a transition looking for a node to connect', {
        node: this.fromNode,
        transition: this.transition
      })
      this.$nextTick(() => {
        this.updateConnectorEndColor()
      })
    },
    endCircleFinishedMoving() {
      if (!this.canAddTransition) {
        return
      }
      this.endMoving = false
      // finde the next node
      let node = this.nextNodeToMe()
      if (node) {
        flowLayoutBus.$emit(
          'It is me, I am a node you are the next node to me so lets connect',
          { me: this.fromNode, you: node, transition: this.transition }
        )
      } else {
        // can not find a node next to me ready to connect
        // move the end circle back to the node edge
        this.$nextTick(() => {
          this.drawCircles()
        })
      }
    },
    updateLineData() {
      const line = this.$refs.transitionLine
      if (line != null) {
        const pathLength = line.getTotalLength()
        const centerPoint = line.getPointAtLength(pathLength / 2)
        this.line = {
          centerX: centerPoint.x,
          centerY: centerPoint.y,
          length: pathLength
        }
      }
    },
    outineIndexInFromNode() {
      return this.fromNode.outgoing.indexOf(this.transition)
    },
    drawCircles() {
      this.drawFromCircle()
      this.drawToCircle()
    },
    drawFromCircle() {
      var line = this.$refs.transitionLine
      if (line != null) {
        var startPoint = line.getPointAtLength((this.nodeSize / 2))
        this.startCircleCenter = { x: startPoint.x, y: startPoint.y }
      } else {
        this.startCircleCenter = { x: this.x1 + (this.nodeSize / 2), y: this.y1 }
      }
    },
    drawToCircle() {
      var line = this.$refs.transitionLine
      if (line != null && this.toNode != null) {
        var pathLength = line.getTotalLength()
        var endPoint = line.getPointAtLength(pathLength - (this.nodeSize / 2))
        this.endCircleCenter = {
          x: endPoint.x + this.outineIndexInFromNode() * 0,
          y: endPoint.y - this.outineIndexInFromNode() * 0
        }
      } else {
        this.endCircleCenter = { x: this.x1 + (this.nodeSize / 2), y: this.y1 }
      }
    },
    distanceToNode(node) {
      var a = this.endCircleCenter.x - parseInt(node.layout.position.x)
      var b = this.endCircleCenter.y - parseInt(node.layout.position.y)
      let distance = parseInt(Math.sqrt(a * a + b * b))
      return distance
    },
    nextNodeToMe() {
      var allNodes = Object.values(this.nodeDistancesMap)
      if (!allNodes.length) return null
      let allNodesDistances = allNodes.map(node => node.distance)
      const smallestDistance = allNodesDistances.reduce((a, b) =>
        Math.min(a, b)
      )
      let node = allNodes.find(node => node.distance == smallestDistance)
      if (!node) return null
      return node.node
    },
    deleteTransition() {
      this.$emit('delete')
    },
    calcLineAnimationDuration() {
      var duration = 2
      var line = this.$refs.transitionLine
      if (line != null) {
        var pathLength = line.getTotalLength()
        duration = pathLength / 300 // px per second
      }
      return duration
    },
    endClicked() {
      this.$emit('endConnectorPointerDown')
    }
  },
  components: {
    FlowTransitionConnector,
    FlowTransitionMenu
  }
}
</script>

<style lang="css" scoped>
.line {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.linePath:hover {
  stroke: red;
}
.filter {
  position: absolute;
  z-index: 5;
  width: 50px;
  height: 50px;
}
.transition-description {
  position: absolute;
  z-index: 5;
  transform: translateX(-50%);
  pointer-events: none;
}
</style>
