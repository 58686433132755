<template>
  <v-dialog
    v-model="dialog"
    max-width="1050"
    >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-sheet color="grey lighten-4" outlined>
      <v-list-item three-line>
        <v-list-item-icon class="mr-4">
          <v-icon color="purple" large>mdi-arrow-up-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
           <v-list-item-title class="text-h6 mb-1">
             {{ $t("paywall.upgradeTeaserTitle") }}
           </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("paywall.upgradeTeaserSubtitle", {planName: plan}) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </v-sheet>
      <v-card-text>
        <PlanList />
      </v-card-text>
    
    </v-card>
  </v-dialog>
</template>

<script>
import PlanList from '@/components/paywall/PlanList.vue'

export default {
  props: ['text'],
  data() {
    return {
      dialog: false,
    }
  },
  watch: {
    dialog: {
      immediate: true,
      async handler(val) {
        if(val === true ){
          this.$intercom?.trackEvent( 'User opened paywall plans' )
        }
      }
    }
  },
  methods: {

  },
  computed: {
    user() {
      return this.$store.state.user?.user
    },
    plan() {
      return this.$store.getters.userPlan?.toUpperCase()
    },
  },
  components: {
    PlanList
  }
}
</script>
