<template>
  <v-container class="pa-0">

    <v-col v-for="(item, i) in internalModel" :key="i" no-gutters>
      <v-row v-for="(subItem, j) in item" :key="`${i}_${j}`">
        <v-col class="pa-1 pl-4">
        <span v-if="j == 0">{{$t('flow.templateKeyValues.name')}}</span>
        <span v-else>{{$t('flow.templateKeyValues.value')}}</span>
        <template-input
          class="mt-1 flex-grow-1"
          :value="subItem"
          @input="newVal => update(i, j, newVal)"
          @keyup.enter.native.stop="() => addInput(i + 1)"
          @click.native="lastActiveInput = [i, j]"
          @focus="lastActiveInput = [i, j]; $emit('focus')"
          @blur="$emit('blur')"
          :selected="inputSelected(i, j)"
          v-bind="$attrs"
          :ref="`input_${i}`"
          data-testid="templateInput"
        />
        </v-col>
      </v-row>
      <v-col v-if="internalModel.length > 1" cols="1" class="d-flex align-center justify-center">
        <v-btn
          @click="() => removeInput(index)"
          text icon small class="ml-1"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-col>
    <v-btn
      @click="() => addInput(internalModel.length)"
      text small
      data-testid="addInputButton"
    >
      <v-icon small>mdi-plus</v-icon>
      Add Item
    </v-btn>
  </v-container>
</template>

<script>
import TemplateInput from './TemplateInput.vue'
import externalModel from '../../mixins/externalModel'
import Vue from 'vue'

export default {
  mixins: [externalModel],
  props: {
    selected: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      internalModel: [['', '']],
      lastActiveInput: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (typeof newVal !== 'object') {
          this.internalModel = [['', '']]
        } else {
          const entries = newVal ? Object.entries(newVal) : [['', '']]
          this.internalModel = entries.length >= 1 ? [...entries] : [['', '']]
        }
      }
    }
  },
  methods: {
    async addInput(index) {
      this.internalModel.splice(index, 0, ['', ''])
      await this.$nextTick()
      this.$refs[`input_${index}`][0].focus()
    },
    removeInput(index) {
      this.internalModel.splice(index, 1)
      this.emitInput()
    },
    update(itemIndex, subItemIndex, newVal) {
      Vue.set(this.internalModel[itemIndex], subItemIndex, newVal)
      this.emitInput()
    },
    emitInput() {
      const newModel = this.internalModel.reduce((acc, item) => {
        if (item[0] != null && item[0].length > 0 || item[1] != null && item[1].length > 0) {
          acc[item[0]] = item[1]
        }
        return acc
      }, {})
      const noEmptyObject = Object.keys(newModel).length > 0 ? newModel : null
      this.$emit('input', noEmptyObject)
    },
    insert(text) {
      const lastActiveInput = this.$refs[`input_${this.lastActiveInput[0]}`][this.lastActiveInput[1]]
      lastActiveInput.insert(text)
    },
    inputSelected(i, j) {
      return this.selected && i === this.lastActiveInput?.[0] && j === this.lastActiveInput?.[1]
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TemplateInput
  }
}
</script>
