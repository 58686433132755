import Vue from 'vue'
import App from './App.vue'
import store from './store/store.js'
import router from './router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import feature from '@/plugins/feature'
import i18n from '@/plugins/i18n' // path to vuetify export
import $apptive from './$apptive.js'
import apptiveErrorReporter from '@/plugins/apptiveErrorReporter.js'

Vue.config.productionTip = false

// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
// Allow `<zapier-zap-manager>` custom element
// Allow `<zapier-app-directory>` custom element
// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
Vue.config.ignoredElements = ['zapier-zap-manager']
Vue.config.ignoredElements = ['zapier-app-directory']

document.documentElement.setAttribute('lang', i18n.locale)
initializeApp()
feature.initialize()

Vue.prototype.$apptive = $apptive

Vue.prototype.$apptiveErrorReporter = apptiveErrorReporter
Vue.config.errorHandler = (error) => apptiveErrorReporter.captureException(error)

function initializeApp() {
  new Vue({
    i18n,
    vuetify,
    router,
    store: store(),
    render: h => h(App)
  }).$mount('#app')
}
