<template>
  <v-sheet elevation="0" rounded class="pa-1 grey lighten-2 one-line d-flex">
    <input data-testid="linkInput" ref="linkInput" class="full-width" readonly id="url" :value="sharedUrl()" />
    <v-tooltip
      v-for="(button, index) in buttons"
      :key="index"
      open-delay="400"
      top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn :data-testid="button.testId" v-bind="attrs" v-on="on" min-width="32" width="32" class="mr-1" depressed small @click="button.onClick">
          <v-icon :color="button.color" small>{{button.icon}}</v-icon>
        </v-btn>
      </template>
      <span>{{ button.tooltip }}</span>
    </v-tooltip>
    <v-menu v-if="moreItems.length" bottom right offset-y>
      <template v-slot:activator="{on, attrs}">
        <v-btn data-testid="linkMoreMenu" v-bind="attrs" v-on="on" min-width="32" width="32" class="mr-1" depressed small>
          <v-icon color="primary" small>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <MenuItem
          v-for="(item, index) in moreItems"
          :key="index"
          @click="item.onClick"
          :text="item.text"
          :icon="item.icon"
          :color="item.color"
          :data-testid="item.testId"
        />
      </v-list>
    </v-menu>
  </v-sheet>
</template>
<script>
import { sharedLinksMethods } from '@/mixins/sharedLinksMethods.js'
import MenuItem from '@/components/MenuItem.vue'

export default {
  mixins: [sharedLinksMethods],
  props: {
    uri: {
      type: String
    },
    buttons: {
      type: Array,
      default: () => []
    },
    moreItems: {
      type: Array,
      default: () => []
    }
  },
  components: {
    MenuItem
  }
}
  </script>