<template>
  <v-sheet color="white" elevation="1" rounded class="pa-4">
    <div class="d-flex flex-column">
      <div v-if="title" class="no-text-overflow font-weight-medium">{{title}}</div>
      <div v-else class="no-text-overflow font-weight-medium grey--text">{{$t('kanban.unnamedEntry')}}</div>

        <component
          ref="component"
          v-for="(attribute, index) in nonFirstAttributes"
          class="mt-2"
          :key="index"
          :attribute="attribute"
          :is="component(attribute)"
          v-bind="$attrs"
          :hideTypeIcon="true"/>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: ['entity'],
  computed: {
    title() {
      return this.entity.attributes[0]?.value
    },
    nonFirstAttributes() {
      return this.entity.attributes.slice(1)
    },
  },
  methods: {
    component(attribute) {
      return attribute.property.kanbanAttributeComponent
    },
  }
}
</script>

<style scoped>
.no-text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
