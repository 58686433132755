<template>
  <template-input ref="input" :textArea="true" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import TemplateInput from './TemplateInput.vue'
export default {
  components: { TemplateInput },
  methods: {
    getInternalValue() {
      return this.$refs.input.getInternalValue()
    },
    insert(text) {
      this.$refs.input.insert(text)
    }
  }
}
</script>

<style>

</style>