import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

import de from 'vuetify/lib/locale/de.js'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken3,
        secondary: colors.blue,
        accent: colors.grey.darken2,
        error: colors.red.accent3,
        destructive: colors.red.lighten1,
        background: colors.grey.lighten4,
      },
      dark: {
        primary: colors.red.lighten3
      }
    }
  },
  breakpoint: {
    mobileBreakpoint: 'xs'
  },
  lang: {
    locales: { de },
    current: navigator.language?.includes('de') ? 'de' : 'en',
  },
}

export const vuetifyColors = opts.theme.themes.light

export default new Vuetify(opts)
