<template>
  <FlowVariablePickerMenu
      v-model="menu"
      :disabled="noScript"
      :pickerValue="pickerValue"
      :flow="flow"
      :filterNode="filterNode"
      @pickerInput="(expression) => externalModel = `{{${expression}}}`"
      attach
    >
    <template v-slot:activator="{on}">
      <TemplateInput
        ref="input"
        v-model="externalModel"
        :flow="flow"
        v-bind="$attrs"
        @click.native="(event) => onInputClick(on, event)"
      />
    </template>
  </FlowVariablePickerMenu>
</template>

<script>
import FlowVariablePickerMenu from './FlowVariablePickerMenu.vue'
import TemplateInput from '@/components/flow/TemplateInput.vue'
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [externalModel],
  props: {
    flow: null,
    noScript: {
      type: Boolean,
      default: () => false
    },
    filterNode: null,
    pickerValue: null
  },
  data() {
    return {
      menu: false
    }
  },
  methods: {
    onInputClick(on, event) {
      on.click?.(event)
    }
  },
  components: {
    FlowVariablePickerMenu,
    TemplateInput
  }
}

</script>