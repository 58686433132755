<template>
  <BaseStatefulView
    :persistentGridUri="gridUri"
    :virtualGridUri="virtualGridUri"
    :viewTitle="statefulView ? statefulView.name : ''"
    @reloadView="reloadView"
  >
    <template v-slot:toolbar-left v-if="!loading && virtualGrid">
      <MapStateSelectionMenu v-if="canPatchView" :mapView="statefulView" />
      <VirtualGridToolbar :virtualGrid="virtualGrid" :statefulViewUri="statefulViewUri" :sorting="false" />
    </template>
    <div
      v-if="!loading && virtualGrid && mapModel == null"
      class="d-flex full-height justify-center align-center"
    >
      <MapStateSelection v-if="canPatchView" :mapView="statefulView" />
    </div>
    <GoogleMap
      v-if="!loading && virtualGrid && mapModel != null"
      ref="map"
      :options="mapOptions"
      :searchBar="false"
      :loading="entitiesLoading"
      @dragend="loadEntities"
    >
      <template v-slot:markers="{ mapPromise }">
        <GoogleMapMarker
          v-for="(marker, i) in mapModel"
          :key="i"
          :mapPromise="mapPromise"
          v-bind="marker.position"
        >
          <template v-slot:infoWindow>
            <KanbanCard
              :entity="marker.entity"
              @click.native="editEntity(marker.entity.entity)"
            />
          </template>
        </GoogleMapMarker>
      </template>
    </GoogleMap>
    <EditEntityDialog
      v-model="editEntityDialog"
      :virtualGrid="virtualGrid"
      :entity="selectedEntity"
    />
  </BaseStatefulView>
</template>

<script>
import BaseStatefulView from '@/views/BaseStatefulView.vue'
import GoogleMap from '@/components/map/GoogleMap.vue'
import GoogleMapMarker from '@/components/map/GoogleMapMarker.vue'
import MapStateSelection from '@/components/mapView/MapStateSelection.vue'
import MapStateSelectionMenu from '@/components/mapView/MapStateSelectionMenu.vue'
import VirtualGridToolbar from '@/components/gridView/VirtualGridToolbar.vue'
import KanbanCard from '@/components/kanbanView/KanbanCard.vue'
import EditEntityDialog from '@/components/gridView/EditEntityDialog.vue'
import { isEqual } from 'lodash'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'

export default {
  props: ['gridUri', 'statefulViewUri'],
  data() {
    return {
      loading: false,
      entitiesLoading: false,
      selectedEntity: undefined,
      editEntityDialog: false
    }
  },
  mounted() {
    this.$intercom?.update({ hide_default_launcher: true })
  },
  beforeDestroy() {
    this.$intercom?.update({ hide_default_launcher: false })
    this.saveMapPosition()
  },
  beforeRouteUpdate(to, from, next) {
    this.saveMapPosition()
    next()
  },
  watch: {
    statefulViewUri: {
      immediate: true,
      async handler() {
        this.loading = true
        try {
          const statefulView = await this.$store.dispatch(
            'AGReadStatefulViewOperation',
            this.statefulViewUri
          )
          await this.$store.dispatch('AGReadVirtualGridOperation', {
            virtualGridUri: statefulView.parentGridUri,
            loadEntities: false
          })
          this.loadEntities(this.initialMapCenter)
        } finally {
          this.loading = false
        }
      }
    },
    mapModel: {
      immediate: true,
      handler(newVal, oldVal) {
        // Fit to markers if initialMapCenter and initialZoom are not set
        if (newVal == null) return
        if (isEqual(oldVal, newVal)) return
        if (
          this.statefulView.initialMapCenter != null &&
          this.statefulView.initialZoomLevel != null
        )
          return
        const markers = newVal.map(item => item.position)
        // HACKY : setTimeout gives the opportunity to the browser to render
        // the map element before the watch handler is called
        // See Late Timeouts example here :
        // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#reasons_for_delays_longer_than_specified
        setTimeout(() => {
          this.$refs.map?.fitMapToMarkers(markers)
        })
      }
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    canPatchView() {
      return hasPermission(this.statefulView, [PERMISSIONS.patch])
    },
    stateFieldIndex() {
      return this.virtualGrid?.fields.findIndex(
        field => field.columnType.name === 'geolocation'
      )
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    virtualGrid() {
      return this.statefulView?.parentGrid
    },
    virtualGridUri() {
      return this.statefulView?.parentGridUri
    },
    mapModel() {
      return this.statefulView?.mapModel()
    },
    initialMapCenter() {
      return {
        lat: () =>
          this.statefulView?.initialMapCenter?.lat ?? 50.941248064035904,
        lng: () => this.statefulView?.initialMapCenter?.lng ?? 6.958303812540371
      }
    },
    mapOptions() {
      return {
        zoom: this.statefulView?.initialZoomLevel ?? 14,
        center: {
          lat: this.initialMapCenter.lat(),
          lng: this.initialMapCenter.lng()
        },
        mapTypeControl: false,
        streetViewControl: false,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          }
        ]
      }
    }
  },
  provide() {
    return {
      space: this.space
    }
  },
  methods: {
    editEntity(entity) {
      this.selectedEntity = entity
      this.editEntityDialog = true
    },
    async saveMapPosition() {
      const map = await this.$refs.map.mapPromise
      this.statefulView.saveMapPosition(
        {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng()
        },
        map.getZoom()
      )
    },
    async loadEntities(center) {
      this.entitiesLoading = true
      try {
        this.virtualGrid.setLocalEntityParams(
          this.statefulView.entityParams(center)
        )
        await this.virtualGrid.loadEntities()
      } finally {
        this.entitiesLoading = false
      }
    },
    reloadView() {
      this.virtualGrid.reload()
      this.statefulView.reload()
    },
  },
  components: {
    BaseStatefulView,
    GoogleMap,
    GoogleMapMarker,
    MapStateSelection,
    VirtualGridToolbar,
    MapStateSelectionMenu,
    KanbanCard,
    EditEntityDialog
  }
}
</script>

<style scoped>
.full-height {
  height: 100%;
  background-color: #f7f7f7;
}
</style>