<template>
  <v-list width="100%" class="pt-3 overflow-y-auto" v-bind="$attrs" dense>
    <v-list-item v-if="!noTitle">
      <v-list-item-title>
        {{nodeName}}
      </v-list-item-title>
    </v-list-item>
    <v-list-group v-for="event in events" :key="event.id">
      <template v-slot:activator>
        <v-list-item-icon class="mr-3 align-self-center d-flex">
          <v-icon dense  v-if="hasError(event)" color="error">mdi-alert-circle-outline</v-icon>
          <v-icon dense v-else color="green">mdi-checkbox-marked-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>{{timestampDisplay(event)}}</v-list-item-subtitle>
          <v-list-item-title>{{ event.message }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item >
        <v-list-item-content>
          <code class="line-break">{{ event.message }}</code>
        
      </v-list-item-content>
     </v-list-item>
        
    </v-list-group>
    <v-list-group v-if="stepValue" v-model="outputGroup">
      <template v-slot:activator>
        <v-icon dense class="mr-3" color="green">mdi-arrow-right-circle-outline</v-icon>
        <v-list-item-content>
          <v-list-item-title>{{$t('flow.instanceDetail.output')}}</v-list-item-title>
        </v-list-item-content>
      </template>
      
      <v-list-item >
        <v-list-item-content class="overflow-x-auto">
      <FlowTreeView
        :value="stepValue"
        :flow="flow"
        :disabled="true"
        :openOnClick="true"/>
      </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
  import FlowTreeView from './FlowTreeView.vue'
  import { DATETIME_SECONDS_DISPLAY_FORMAT } from '@/constants/dateFormats.js'
  import moment from 'moment'

  export default {
    props: {
      flow: null,
      step: null,
      noTitle: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {
        outputGroup: true
      }
    },
    mounted() {
      this.step?.loadEvents()
      this.flow?.loadNodes()
    },
    computed: {
      events() {
        return this.step?.events ?? []
      },
      node() {
        return this.flow.nodes.find(node => node.uri === this.step.getLink('node'))
      },
      stepValue(){
        if (this.node == null) {
          return undefined
        }
        return {[this.node.id]: this.step?.output}
      },
      nodeName() {
        return this.node?.name
      }
    },
    methods: {
      hasError(event) {
        return event.type?.toLowerCase().includes('error')
      },
      timestampDisplay(event) {
        return moment(event.timestamp).format(DATETIME_SECONDS_DISPLAY_FORMAT)
      }
    },
    components: {
      FlowTreeView
    }
  }
</script>
<style scoped>
.line-break {
  line-break: anywhere;
}
</style>