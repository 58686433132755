<template>
  <div class="app" :style="{ height: '100%' }">
    <v-container style="height: 100%; width: 100%">
      <div
        v-if="showSuccess"
        class="d-flex flex-column justify-center align-center"
        style="height: 100%; width: 100%"
      >
        <lottie
            :options="successAnimationOptions"
            :height="100"
            :width="100"
            v-on:animCreated="handleSuccessAnimation"
          />
          <div>
            <div class="text-h4">
              {{ $t('forms.view.successTitle') }}
            </div>
            <div class="mt-2">
              {{$t('forms.view.successMessage')}}
            </div>
          </div>
      </div>
      <div v-else-if="!loading && errorMessage" class="error-display d-flex justify-center align-center text-h4">{{ errorMessage }}
      </div>
      <BlockPage v-else-if="block && !loading" :blockUri="blockUri" @submitBlock="submitBlock" @unaccessibleView="unaccessibleView" />
    </v-container>
    <LoadingOverlay :show="loading" />

  </div>

</template>

<script>

import LoadingOverlay from '@/components/LoadingOverlay.vue'
import BlockPage from '@/components/block/BlockPage.vue'
import Lottie from 'vue-lottie'
import * as successAnimationData from '@/assets/success.json'
import ApiClient from '@/ApiClient'
import { createBlock } from '@/store/models/blocks'
import linkAuthorizationNeededBus from '@/utils/linkAuthorizationNeededBus'

export default {
  props: ['uri'],
  data() {
    return {
      loading: false,
      errorMessage: undefined,
      block: undefined,
      blockUri: null,
      showSuccess: false,
      successAnimationOptions: {
        animationData: successAnimationData.default,
        loop: false,
        color: 'red'
      },
    }
  },
  mounted() {
    if(!this.uri) {
      // here we get the uri from the search params as vue router ignores queries that are before the /#view/
      const urlParams = new URLSearchParams(window.location.search)
      this.blockUri = urlParams.get('uri') 
    }
    else if(this.uri)  {
      this.blockUri = this.uri
    }
    linkAuthorizationNeededBus.onLinkAuthorizationNeeded(this.onLinkAuthorizationNeeded)
    this.loadView()
  },
  beforeDestroy() {
    linkAuthorizationNeededBus.linkAuthorizationNeededOff(this.onLinkAuthorizationNeeded)
  },
  methods: {
    onLinkAuthorizationNeeded() {
      // show login
      this.loginDialog = true
    },
    credentialsProvided() {
      this.canLogout = true
      this.loadView()
    },
    async loadView() {
        this.loading = true
        this.errorMessage = null
        this.block = null
        try {
          const response = await ApiClient.getUri( this.blockUri, undefined, { customErrorHandling: [404, 400]})
          this.block = createBlock(response.data) 
        } catch (error) { 
          if (error.response?.status === 404) {
            this.errorMessage = this.$t('errorMessages.unaccessibleView')
          }
          else if (error.response?.status === 400) {
            this.errorMessage = error.response.data.description
          }
        }
        finally {
          this.loading = false
        }
    },
    unaccessibleView() {
      this.showSuccess = true
    },
    async submitBlock(payload, submitAction) {
      // colect values 
      this.loading = true
      const response = await ApiClient.postUri( submitAction.uri, payload )
      const aLink = response.headers.location
      this.loading = false
      this.blockUri = aLink
    },
    logout() {
      //reload view to rest auth setting in api client
      window.location.reload()
    },
    handleSuccessAnimation(animation) {
      animation.play()
    },
  },
  computed: {
  },
  components: {
    LoadingOverlay,
    BlockPage,
    lottie: Lottie,
  }
}
</script>

<style scoped>
.app {
  background-color: white;
}


.error-display {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>