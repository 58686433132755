import { render, staticRenderFns } from "./Parameter.vue?vue&type=template&id=55da4d67&scoped=true&"
import script from "./Parameter.vue?vue&type=script&lang=js&"
export * from "./Parameter.vue?vue&type=script&lang=js&"
import style0 from "./Parameter.vue?vue&type=style&index=0&id=55da4d67&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55da4d67",
  null
  
)

export default component.exports