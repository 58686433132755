<template>
<v-sheet elevation="0" rounded class="pa-1 grey lighten-2 one-line d-flex">
  <input data-testid="externalLink" ref="linkInput" class="full-width" readonly id="url" :value="sharedUrl()" />
  <v-tooltip open-delay="400" top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn data-testid="copyLink" v-bind="attrs" v-on="on" min-width="32" width="32" class="mr-1" depressed small @click="copyLink">
        <v-icon color="primary" small>mdi-content-copy</v-icon>
      </v-btn>
    </template>
    <span>{{ $t("virtualGrid.qrCode.dialog.buttons.copy")}}</span>
  </v-tooltip>

  <v-tooltip v-if="showOpen" open-delay="400" top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn  v-bind="attrs" v-on="on"  min-width="32" width="32" class="mr-1" small @click="open" depressed>
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </v-btn>
    </template>
    <span>{{ $t("virtualGrid.qrCode.dialog.buttons.open") }}</span>
  </v-tooltip>

  <v-tooltip v-if="showDeleteButton" open-delay="400" top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn  v-bind="attrs" v-on="on" min-width="32" width="32" small @click="deleteClicked" depressed>
        <v-icon color="red" small>mdi-delete-outline</v-icon>
      </v-btn>
    </template>
    <span>{{ $t("virtualGrid.qrCode.dialog.buttons.remove")}}</span>
  </v-tooltip>
</v-sheet>
</template>

<script>
import { sharedLinksMethods } from '@/mixins/sharedLinksMethods.js'

export default {
  mixins: [sharedLinksMethods],
  props: {
    uri: {
      type: String
    },
    showOpen: {
      type: Boolean,
      default: () => false
    },
    showDeleteButton: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    deleteClicked() {
      this.$emit('delete')
    }
  }
}
</script>