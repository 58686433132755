<template>
<v-dialog v-model="dialog" v-bind="$attrs">
  <template v-slot:activator="{ on, attrs }">
    <slot name="activator" :on="on" :attrs="attrs" />
  </template>
  <v-card>
    <v-card-title class="text-h5" data-testid="singleInputDialogTitle">
      <div v-html="title" />
    </v-card-title>
    <v-card-text
      >{{text}}
      <v-text-field
        v-if="!this.area"
        ref="input"
        class="mt-2"
        outlined
        autofocus
        @focus="$event.target.select()"
        clearable
        :rules="inputRules"
        :counter="counter"
        @keyup.enter.native="okPressed"
        @input="$emit('textFieldInput', inputModel)"
        v-model="inputModel"
      />
      <v-textarea
        v-else
        ref="input"
        class="mt-2"
        outlined
        autofocus
        @focus="$event.target.select()"
        clearable
        :rules="inputRules"
        :counter="counter"
        @input="$emit('textFieldInput', inputModel)"
        v-model="inputModel"
      />
      <slot />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey darken-1" text @click="dialog = false">
        {{$t("dialogs.cancelButton")}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        @click="okPressed"
        :loading="loading"
        data-testid="dialogOkButton"
      >
        {{okButtonLabel}}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { textFieldRules } from '@/mixins/textFieldRules.js'
import i18n from '@/plugins/i18n'

export default {
  mixins: [ textFieldRules ],
  props: {
    title: String,
    text: String,
    okButtonLabel: {
      type: String,
      default: () => i18n.t('dialogs.okButton')
    },
    okHandler: Function,
    max: {
      type: Number,
      default: () => undefined
    },
    nameRequired: {
      type: Boolean,
      default: () => true
    },
    area: {
      type: Boolean,
      default: () => false
    },
    initialInputValue: String,
    value: {
      default: () => false
    },
    additionalRules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      inputModel: undefined
    }
  },
  watch: {
    initialInputValue: {
      immediate: true,
      handler(newVal) {
        this.inputModel = newVal
      }
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    inputRules() {
      return [
        ...(this.max != null ? this.rules.maxLength(this.max) : []),
        ...(this.nameRequired ? this.rules.nameRequired : []),
        ...this.additionalRules
      ]
    },
    counter() {
      return this.max
    }
  },
  methods: {
    async okPressed() {
      if (!this.$refs.input.validate()) {
        return
      }
      this.loading = true
      try {
        await this.okHandler(this.inputModel)
        this.dialog = false
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="css"></style>
