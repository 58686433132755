import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class HeaderBlock extends Block {
  constructor(data) {
    super(data)
    this.isDirty = false
  }

  get text() {
    return this.header.text
  }

  set text(newVal) {
    if (newVal === this.header.text) {
      return
    }
    this.header.text = newVal
    this.isDirty = true
  }

  get level() {
    return this.header.level ?? 'h3'
  }

  set level(newVal) {
    if (newVal === this.header.level) {
      return
    }
    this.header.level = newVal
    this.isDirty = true
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        header: {
          text: this.text,
          level: this.level
        }
      }
    })
    this.isDirty = false
  }
}