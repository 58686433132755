<template>
  <div id="app">
    <v-app>
      <v-main>
        <router-view name="SideBar"></router-view>
        <router-view name="MainContent"></router-view>
        <ErrorReportDialog />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import ErrorReportDialog from '@/components/ErrorReportDialog'
import { logTarget } from '@/plugins/apptiveErrorReporter.js'
export default {
  beforeMount() {
    this.$store.commit('setNavigationDrawer', !this.$vuetify.breakpoint.mobile)
  },
  async mounted() {
    // log error
    const config = await this.$store.dispatch('getConfig')
    let version = 'Unkown Version'
    try {
      version = await this.$store.dispatch('loadWebVersion')
    } catch(error) {
      console.error(error)
    }
    if (config?.error?.form != null) {
      // log to console not cloud while develop
      let target = (process.env.NODE_ENV === 'development') ? logTarget.CONSOLE : logTarget.CLOUD
      this.$apptiveErrorReporter.init({ app: 'ApptiveGrid', form: config.error.form, version: version, target: target, beforeSend: this.beforeSendError})
    }
  },
  data() {
    return {
    }
  },
  methods: {
    beforeSendError(event) {
      const response = event?.error?.response
      if ( response ) {
        event.properties['response-status'] = response.status || null
        // don't log 401
        if (response.status === 401) {
         let target = (process.env.NODE_ENV === 'development') ? logTarget.CONSOLE : logTarget.IGNORE
         event.logTarget = target
        }
      }

      // log additional infos
      event.properties['vue-route'] = this.$route?.name || null
      event.properties['page-url'] = window.location.href || null
      event.properties['apptive-Id'] = this.$store.state.user?.user?.id || null
      event.properties['apptive-email'] = this.$store.state.user?.user?.email || null

      // in case of a api call error log url, method, errorcode
      const config = event?.error?.config
      if (config) { 
        event.properties['request-url'] =  config?.url || null
        event.properties['request-method'] = config?.method || null
      }

      // send error if it is triggered via test or forced send with sendAlways e.g. in profile page
      if(event.error?.sendAlways) event.target = logTarget.CLOUD
      return event
    }
  },
  components: {
    ErrorReportDialog
  }
}
</script>

<style scoped>
#app {
  background-color: #F7F7F7;
}

</style>

<style>
/* The --v-navigation-drawer-zindex is here for reference and has no effect */
:root {
  --v-navigation-drawer-zindex: 1;
  --grid-group-header-zindex: 2;
  --grid-toolbar-zindex: 3;
  --top-of-v-navigation-drawer-zindex: 5;
}

.v-main {
  transition: none !important;
  max-height: 100%;
}

.v-application--wrap {
  height: 100vh;
}

/* preventing p tags from overflowing their parent */
p {
  min-width: 1px;
}
</style>
