import StatefulView from './StatefulView'
import store from '../store'

export default class CardViewBase  extends StatefulView { 
  constructor(data) {
    super(data)
  }

  initShownFields() {
    // fields shown on a card are based on fieldPropertes.id.GALLERY_CARD_POSITION 
    // inital no field is shown. 
    const fieldIds = this.parentGrid.fields.map(field => field.id)
    let found = fieldIds.find( id => this.fieldIsShown(id))
    if(!found) {
      fieldIds.forEach((fieldId,index) => {
        this.setFieldProperty(fieldId, this.cardPositionKey , index)
      })
    }
  }

  saveProperties() {
    return  store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      properties: this.properties,
      slotProperties: this.slotProperties
    })
      .then(() => this.reload())
  }

  fieldIsShown (fieldId) {
    return this.fieldPostion(fieldId) != null
  }

  fieldPostion (fieldId) {
    let fieldProperty = this.slotProperties[fieldId]
    if(!fieldProperty) return null
    return fieldProperty[this.cardPositionKey]
  }

  cardFields() {
    var list = this.parentGrid.fields.map(field => {
      return {
        label: field.name,
        value: field.id,
        icon: this.icon(field),
        shown: this.fieldIsShown(field.id),
        fixed: !this.fieldIsShown(field.id)
      }
    })
    return list.sort( (a, b) =>   {
      let aIndex = this.fieldPostion(a.value)
      let bIndex = this.fieldPostion(b.value)
      return aIndex === undefined ? 1 : bIndex === undefined ? -1 : aIndex - bIndex
    })
  }

  icon(field) {
    var icon = null
    try {
      icon = field.columnType.typeIcon
    } catch (error) {
      icon = 'mdi-progress-download'
    }
    return icon
  }

  updateShownCardFields(cardFields) {

    
    const parentGridFieldIds = this.parentGrid.fields.map(field => field.id)
    // add all slotProperties keys that are part of the parrent grid, but not part of the slotProperties of the sview
    parentGridFieldIds.forEach(fieldId => {
      if (!(fieldId in this.slotProperties)) {
        this.slotProperties[fieldId] = {}
      }
    })

    cardFields.forEach(element => {
      if(!element.shown){
        this.deleteFieldProperty(element.value, this.cardPositionKey)
      }
    })

    cardFields.filter(el => el.shown ).forEach((element, index) => {
      this.setFieldProperty(element.value, this.cardPositionKey, index)
    })
    // remove all fieldProps that are hidden in the parent grid as it would result in an error
    // updating the sview
    this.slotProperties = Object.fromEntries(
      Object.entries(this.slotProperties)
        .filter(([key]) => parentGridFieldIds.includes(key))
    )
    return store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      slotProperties: this.slotProperties
    })
    .then(() => this.reload())

  }
}