<template>
  <span
    class="token font-weight-medium pl-1 pr-1"
    :data-value="value"
    :data-color="color"
    :data-type="parameterType"
    :style="style"
    @click="onClick"
  >{{actualText}}</span>
</template>

<script>
export default {
props: {
  text: null,
  color: null,
  parameterType: null,
},
data() {
  return {
    actualText: this.text
  }
},
computed: {
  style() {
    return {
      'background-color': this.color,
      cursor: this.draggable ? 'move' : 'pointer'
    }
  },
  value() {
    return this.$el?.textContent ? `'${this.$el?.textContent}'` : this.text
  }
},
methods: {
  onClick() {
    if (this.actualText === this.text) {
      this.actualText = ''
    }
  }
}
}
</script>

<style scoped>
.token {
  color: white;
  border-radius: 4px;
  margin-left: 1px;
  margin-right: 1px;
  height: 24px;
  display: inline-block;
}
.invisible {
  opacity: 0;
  width: 0px;
}
</style>
