import Axios from 'axios'

const DEFAULT_CONFIG = {
  unauthenticatedSignedUrlEndpoint: 'https://7zzn3khlt1.execute-api.eu-central-1.amazonaws.com/uploads',
  signedUrlEndpoint: 'https://9t6o4slb79.execute-api.eu-central-1.amazonaws.com/uploads',
  apiEndpoint: 'https://apptiveattachmentsalpha-apptiveattachmentbucket-rg72rfbppffp.s3.eu-central-1.amazonaws.com'
}

export default {
  state() {
    return {
      config: undefined
    }
  },
  mutations: {
    setAttachmentsConfig(state, config) {
      state.config = config
    }
  },
  actions: {
    getAttachmentsConfig(context) {
      if (context.state.config) {
        return Promise.resolve(context.state.config)
      }
      return Axios.get('/config.json')
        .then(response => {
          context.commit('setAttachmentsConfig', response.data.attachments)
          return response.data.attachments
        })
        .catch(error => {
          if (error.response.status === 404) {
            context.commit('setAttachmentsConfig', DEFAULT_CONFIG)
            return DEFAULT_CONFIG
          }
        })
    }
  }
}