<template>

  <div style="height:100%;" class="">

    <div style="height:100%;" class="d-flex flex-row" >

      <NavigationDrawer
        :space="space"
        :gridUri="gridUri"
        :statefulViewItems="statefulViewItems"
        :formItems="formItems"
        :showDrawer="showDrawer"
        :width="drawerWidth"
        :loading="loading" />

      <div v-if="!loading" :style="{ height: '100%', width: '100%', overflow: 'scroll' }" class="flex-shrink-1">
        <router-view />
      </div>

      <FullHeightLoader v-model="loading" :style="{ width: '100%'}"/>

      <ApptivesDrawer v-model="apptivesDrawer"/>

    </div>
  </div>
</template>

<script>
import NavigationDrawer from '@/components/space/NavigationDrawer.vue'
import FullHeightLoader from '@/components/FullHeightLoader.vue'
import ApptivesDrawer from '@/components/apptives/ApptivesDrawer.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import {ViewIconTypes} from '@/constants/viewIconTypes.js'

export default {
  props: {
    gridUri: null,
    spaceUri: null
  },
  data: () => ({
    drawerWidth: 240,
    loading: false
  }),
  provide() {
    return {
      plan: () => this.plan,
      space: this.space,
    }
  },
  watch: {
    gridUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          const grid = await this.$store.dispatch('AGReadGridOperation', newVal)
          const listPromises = []
          if (this.canListForms) {
            listPromises.push(this.$store.dispatch('AGListFormsOperation', newVal))
          }
          if (this.canListViews) {
            listPromises.push(this.$store.dispatch('loadViewUris', newVal))
          }
          if (this.canListStateFulViews) {
            listPromises.push(this.$store.dispatch('AGListStatefulViewsOperation', grid))
          }
          Promise.all(listPromises)
          .then(() => {
            if (this.$route.name === 'Grid' && this.navItems.length > 0) {
              this.$router.replace(this.navItems[0].to)
            }
          })
        } finally {
          this.loading = false
        }
      }
    },
    $route(route) {
      if (route.name === 'Grid' && this.navItems.length > 0) {
        this.$router.replace(this.navItems[0].to)
      }
    }
  },
  computed: {
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    plan() {
        return this.space?.plan
    },
    canListForms() {
      return hasPermission(this.grid, [PERMISSIONS.forms])
    },
    canListViews() {
      return hasPermission(this.grid, [PERMISSIONS.views])
    },
    canListStateFulViews() {
      return hasPermission(this.grid, [PERMISSIONS.sviews])
    },
    showDrawer: {
      get() {
        return this.$store.state.navigationDrawer
      },
      set(newVal) {
        return this.$store.commit('setNavigationDrawer', newVal)
      }
    },
    apptivesDrawer: {
      get() {
        return this.$store.state.apptivesDrawer
      },
      set(newVal) {
        this.$store.commit('setApptivesDrawer', newVal)
      }
    },
    statefulViewItems () {
      const navItems = {
        spreadsheet: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.GRID,
          testid: 'virtualGridListItem',
          to: {name: 'VirtualGrid', params: { statefulViewUri: statefulView.uri }},
        }),
        kanban: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.KANBAN,
          testid: 'kanbanListItem',
          to: {name: 'KanbanView', params: { statefulViewUri: statefulView.uri }}
        }),
        calendar: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.CALENDAR,
          testid: 'calendarListItem',
          to: {name: 'CalendarView', params: { statefulViewUri: statefulView.uri }}
        }),
        map: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.MAP,
          testid: 'mapListItem',
          to: {name: 'MapView', params: { statefulViewUri: statefulView.uri }}
        }),
        gallery: statefulView => ({
          name: statefulView.name,
          uri: statefulView.uri,
          iconType: ViewIconTypes.GALLERY,
          testid: 'galleryListItem',
          to: {name: 'GalleryView', params: { statefulViewUri: statefulView.uri }}
        }),
      }

      const statefulViewItems = this.statefulViews
        .map(statefulView => {
          const navItem = navItems[statefulView.type]?.(statefulView)
          const fullView = this.$store.getters.statefulViewWithUri(statefulView.uri)
          if (fullView?.canRename()) {
            navItem.rename = newName => {
              fullView.rename(newName)
            }
          }
          navItem.parentGridUri = statefulView._links.grid.href
          return navItem
        })
        .filter(item => item != null)

      return statefulViewItems
    },
    formItems() {
      var formItems = []
      formItems = this.forms.map( form => {
        const fullForm = this.$store.getters.formWithUri(form.uri)
        let navItem =  {
          name: form.name,
          uri: form.uri,
          iconType: ViewIconTypes.FORM,
          testid: 'virtualGridFormListItem',
          to: {name: 'FormBuilder', params: { formUri: form.uri}}
        }
        if (fullForm && hasPermission(fullForm, [PERMISSIONS.update])) {
          navItem.rename = newName => this.renameForm(fullForm, newName)
        }
        return navItem
      })
      return formItems
    },
    navItems() {
      return [...this.statefulViewItems, ...this.formItems]
    },
    forms() {
      if (!this.grid) {
        return []
      }
      return this.$store.getters.formsOfGrid(this.grid.uri)
    },
    statefulViews() {
      if (!this.grid) {
        return []
      }
      return this.$store.getters.statefulViewsOfGrid(this.grid.uri)
    },
  },
  methods: {
    renameVirtualGrid(virtualGrid, newName) {
      if (virtualGrid.name === newName) {
          return
        }
        return this.$store.dispatch('AGRenameVirtualGridOperation', {
          virtualGrid,
          newName
        }).then(() => {
          this.$store.dispatch('AGListStatefulViewsOperation', virtualGrid.parentGrid())
        })
    },
    renameForm(form, newName) {
      if (form.name == newName) {
          return
        }
      return form.rename(newName)
    },
  },
  components: {
    NavigationDrawer,
    FullHeightLoader,
    ApptivesDrawer,
  }
}
</script>
<style scoped>

</style>
