<template>
<div class="full">
  <v-app-bar app dense flat dark color="primary">
    <v-btn @click="back(from)" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
    <v-toolbar-title class="font-weight-medium">{{ $t("settings.accountSettingTitle") }}</v-toolbar-title>

  </v-app-bar>

  <v-tabs
    v-model="tab"
    v-if="this.$vuetify.breakpoint.mobile"
  >
    <v-tabs-slider color="primary"></v-tabs-slider>

    <v-tab

      v-for="item in navigationItems"
      :key="item.text"
      :to="item.to"
    >
    {{ item.text }}
    </v-tab>
  </v-tabs>


  <div style="height:100%;" class="d-flex flex-row" >

  <v-navigation-drawer
     v-if="!this.$vuetify.breakpoint.mobile"
    width="280px"
    v-model="drawer"
    :permanent="$vuetify.breakpoint.smAndUp"
    >
    <v-list nav dense>
      <v-list-item
        v-for="(item, i) in navigationItems"
        :key="i"
        :to="item.to"
        :data-testid="item.testId">
        <v-list-item-icon class="mr-3">
          <v-icon dense v-text="item.icon"/>
        </v-list-item-icon>
        <v-list-item-title  v-text="item.text"/>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <div :style="{ height: '100%', width: '100%', overflow: 'scroll' }" class="flex-shrink-1">
    <router-view />
  </div>
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      drawer: !this.$vuetify.breakpoint.mobile,
      tab: 0,
      from: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.from = from
    })
  },
  mounted() {
    this.$store.dispatch('loadUser')
  },
  computed: {
    navigationItems() {
      return [
        {text: this.$t('settings.profileNavigationTitle'), icon: 'mdi-account-circle-outline', to: {name: 'Profile'}, testId: 'settingsProfile'},
        {text: this.$t('settings.payment'), icon: 'mdi-credit-card-outline', to: {name: 'PaymentPage'}, testId: 'settingsPayment'},
        {text: this.$t('settings.apiCredentialsNavigation'), icon: 'mdi-key-outline', to: {name: 'ApiCredentials'}, testId: 'settingsCredentials'},
      ]
    },
  },
  methods: {
    back(from = { name: 'Spaces' }) {
      this.$router.push( from )
    },
  }
}
</script>

<style scoped>
.full {
  height: 100%;
  width: 100%;
}
</style>
