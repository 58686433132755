<template>
  <TemplateInput
    ref="input"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="internalModel"
    @blur="emit"
  />
</template>

<script>
import TemplateInput from '@/components/flow/TemplateInput.vue'
import externalModel from '@/mixins/externalModel'
import { isExpression } from '@/editor/Editor.js'

  export default {
    mixins: [externalModel],
    data() {
      return {
        internalModel: undefined
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          const withMustache = `{{${newVal}}}`
          if (isExpression(withMustache)) {
            this.internalModel = withMustache
          } else {
            this.internalModel = newVal
          }
        }
      }
    },
    methods: {
      emit() {
        if (isExpression(this.internalModel)) {
          const withoutMustache = this.internalModel.slice(2, this.internalModel.length - 2)
          this.$emit('input', withoutMustache)
        } else {
          this.$emit('input', this.internalModel)
        }
      },
      async insert(text) {
        this.externalModel = null
        setTimeout(() => {
          this.$refs.input.insert(text)
        }, 1)
      },
      async insertToken(tokenProps) {
        this.externalModel = null
        setTimeout(() => {
          this.$refs.input.insertToken(tokenProps)
        }, 1)
      },
    },
    components: {
      TemplateInput
    }
  }
</script>

<style lang="scss" scoped>

</style>