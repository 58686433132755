// import Vue from 'vue'
// const bus = new Vue()
let dragged
export default {
  tokenDragStart(expression) {
    dragged = expression
  },
  getDraggedExpression() {
    const temp = dragged
    dragged = null
    return temp
  },
}
