<template>
  <BaseNodeComponent v-model="externalModel" v-bind="$attrs" @blur="$emit('blur')">
    <div
      class="d-flex flex-row align-center"
      v-for="(key, index) in Object.keys(internalModel)"
      :key="index"
    >
      <TemplateInput
        class="mt-1 flex-grow-1"
        :value="internalModel[key]"
        :error="errors[key]"
        :label="key"
        @input="newVal => update(key, newVal)"
        @click.native="lastActiveInput = key"
        @focus="lastActiveInput = key; $emit('focus')"
        @blur="$emit('blur')"
        :jsonSchema="jsonSchema.properties[key]"
        :selected="selected && lastActiveInput === key"
        v-bind="$attrs"
        :ref="`input-${key}`"
        data-testid="templateInput"
        topLabel
      />
    </div>
  </BaseNodeComponent>
</template>

<script>
import BaseNodeComponent from '@/components/flow/BaseNodeComponent.vue'
import TemplateInput from '@/components/flow/TemplateInput.vue'
import externalModel from '@/mixins/externalModel.js'
import Vue from 'vue'

export default {
  mixins: [externalModel],
  props: {
    selected: {
      type: Boolean,
      default: () => false
    },
    jsonSchema: null,
    items: null
  },
  data() {
    return {
      internalModel: undefined,
      errors: undefined,
      lastActiveInput: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal == null || typeof newVal !== 'object') {
          this.internalModel = this.initialModel()
        } else {
          this.internalModel = {...newVal}
        }
        this.errors = this.initialErrors()
      }
    }
  },
  methods: {
    initialModel() {
      if (this.jsonSchema?.properties == null) {
        return {}
      }
      let model = {}
      Object.keys(this.jsonSchema.properties)
        .filter(key => !this.ignoreProperty(key))
        .forEach(key => model[key] = '')
      return model
    },
    ignoreProperty(key){
      // hack: ignore displayValue for type user. Do not ignore for crossref as the backend requires it 
      return key == 'displayValue' && this.items?.type?.name == 'user'
    },
    initialErrors() {
      if (this.jsonSchema?.properties == null) {
        return {}
      }
      let model = {}
      Object.keys(this.jsonSchema.properties)
        .forEach(key => model[key] = null)
      return model
    },
    update(key, newVal) {
      Vue.set(this.internalModel, key, newVal)

      // Allow setting all values to null :
      if (Object.values(this.internalModel).every(val => val == null || val === '')) {
        this.externalModel = null
        this.errors = this.initialErrors()
        return
      }

      let hasErrors = false
      for (const key of this.jsonSchema.required) {
        if (this.internalModel[key] == null || this.internalModel[key].length === 0) {
          hasErrors = true
          this.errors[key] = this.$t('validation.required')
        } else {
          this.errors[key] = null
        }
      }
      if (hasErrors) return
      this.externalModel = this.internalModel
    },
    insert(text) {
      const lastActiveInput = this.$refs[`input-${this.lastActiveInput}`][0]
      lastActiveInput.insert(text)
    }
  },
  components: {
    BaseNodeComponent,
    TemplateInput
}
}
</script>