import Vue from 'vue'
import VueIntercom from 'vue-intercom'
import store from '@/store/store.js'

export async function bootIntercom(templateId) {
  if (document.querySelector('#intercom-frame') != null) {
    return
  }
  const name = store().state.user.user?.firstName
  const email = store().state.user.user?.email
  const plan = store().state.user.user?.plan

  if (name == null || email == null) {
    return
  }
  const config = await store().dispatch('getConfig')

  Vue.use(VueIntercom, { appId: config.intercom?.appID || 'gc99d2tg' })
  new Vue({
    el: '#intercomPlaceholder',
    render: h => h('div', {id: 'bootedIntercom'}),
    mounted() {
    this.$intercom.boot({
      name,
      email,
      templateId,
      plan
    })
  }})
}
