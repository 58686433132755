import FlowNode from '@/store/models/flow/nodes/FlowNode.js'
import { nodeTypes } from '@/constants/nodeTypes'
import TemplateFlowFormPicker from '@/components/flow/TemplateFlowFormPicker.vue'
import i18n from '@/plugins/i18n'

export default class FlowFormNode extends FlowNode {
  constructor(data, eventType) {
    super(data, eventType)

    nodeTypes.flowForm
    this.typeName = nodeTypes.flowForm.typeName
    this.description = nodeTypes.flowForm.description
    this.icon = nodeTypes.flowForm.icon
    this.graphIcon = nodeTypes.flowForm.graphIcon
    this.color = nodeTypes.flowForm.color
    this.docuLink = nodeTypes.flowForm.docuLink
  }

  async loadSetupView(space) {
    return this.loadSetupBlock(space)
  }
  _component(key, label, componentType, options, space, jsonSchema, children) {
    if (componentType === 'resource') {
      return {
        component: TemplateFlowFormPicker,
        props: {
          label: i18n.t('flow.nodeProperties.flowFormPicker'),
          name: label,
          key,
          space
        }
      }
    }
    else {
      return super._component(key, label, componentType, options, space, jsonSchema, children)
    }
  }
}