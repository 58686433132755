<template>
  <div>
       <v-snackbar
      :value="flowRunnerError"
      bottom
      color="error"
      outlined

      transition="slide-y-reverse-transition"
    >
    <strong class="h2">{{ $t("flow.editor.errors.testRunFailed") }}</strong>
    <div>{{ flowRunnerErrorDescription }}</div>
    </v-snackbar>
  <v-menu
    top
    offset-y
    v-model="menu"
    :close-on-content-click="false"
  >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          large
          elevation="5"
          v-bind="attrs"
          @click="event => testButtonClicked(on.click, event)"
          :loading="runningFlow"
          :disabled="!trigger"
          data-testid="openTestRunUI"
        >
        <span v-if="!runningFlow">{{ $t("flow.editor.buttons.testflow") }} </span>
        <v-icon class="ml-2">{{ testButtonIcon }}</v-icon>
        </v-btn>

      </template>
      <v-card> 
      <v-list dense>
        <v-list-item
          v-for="(action, index) in actions"
          :key="index"
          :data-testid="action.dataTestId"
          @click="action.action"
          
        >

            <v-list-item-content>
              <v-list-item-title>{{ action.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ action.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary" v-if="!runningFlow">{{ action.icon }}</v-icon>
            </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-sheet v-if="showJsonInput" class="pa-6">
        <p>{{ $t('flow.nodes.requestTrigger.configuration.testWebhookDescription') }}</p>
            <v-textarea
              v-model="jsonPayloadString"
              outlined
              :label="$t('flow.nodes.requestTrigger.configuration.jsonPayloadTitle')"
              placeholder='{"key": "value"}'
            ></v-textarea>
            <v-btn :disabled="!isJsonValid" color="primary" rounded class="mt-4" @click="runWithPayload">
              <v-icon class="ml-2">
                mdi-play
              </v-icon>
              {{ $t('flow.nodes.requestTrigger.configuration.sendTestRequestButton') }}
            </v-btn>
      </v-sheet>

    </v-card>
    </v-menu>
    <FlowInstanceFlowform v-if="runningFlow && showFlowForm" :link="flowFormLink"></FlowInstanceFlowform>

    <v-btn
          v-if="runningFlow"
          color="destructive"
          rounded outlined
          large
          elevation="5"
          @click="stop"
          class="ml-2"
        >
        <v-icon data-testid="testRunFlowButton_Running" large>
          mdi-stop
        </v-icon>
        <span>Stop</span>

        </v-btn>

</div>
</template>

<script>
import FlowRunner from '@/store/models/flow/FlowRunner.js'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import FlowInstanceFlowform from '@/components/flow/FlowInstanceFlowform.vue'

export default {
  props: ['flow', 'disabled'],
  data() {
    return {
      menu: false,
      flowRunner: null,
      showJsonInput: false,
      jsonPayloadString: null,
      flowRunnerActions: {
        flowForm: {
          actions: [
            {
              title: this.$t('flow.editor.buttons.openPageAndWaitUntilNextRun'),
              description: this.$t('flow.editor.buttons.openPageAndWaitUntilNextRunDescription'),
              icon: 'mdi-play-circle',
              action: this.openPageAndWaitUntilNextRun,
            },
          ]
        },
        externalFlowTrigger: 
        {
          actions: [
            {
              title: this.$t('flow.editor.buttons.waitforNewRunTitle'),
              description: this.$t('flow.editor.buttons.waitforNewRunDescription'),
              icon: 'mdi-play-circle',
              action: this.waitUntilNextRun,
            },
            {
              title: this.$t('flow.editor.buttons.reRunFlowTitle'),
              description: this.$t('flow.editor.buttons.reRunFlowDescription'),
              icon: 'mdi-play-circle',
              action: this.reRunFlow,
            },
            {
              title: this.$t('flow.editor.buttons.testRunWithPayloadTitle'),
              description: this.$t('flow.editor.buttons.testRunWithPayloadDescription'),
              icon: 'mdi-dots-horizontal',
              action: this.togglJsonInput,
            }
         ]
        },
        internalFlowTrigger:
        {
          actions: [
            {
              title: this.$t('flow.editor.buttons.testRunFlowTitle'),
              description: this.$t('flow.editor.buttons.testRunFlowDescription'),
              dataTestId: 'testRunFlowButton',
              icon: 'mdi-play-circle',
              action: this.testRunHook,
            }
         ]
        },
      } 
    }
  },
  methods: {
    testButtonClicked(openMenuHandler, event) {
      if (this.actions.length === 1) {
        this.actions[0].action()
      } else {
        openMenuHandler(event)
      }
    },
    waitUntilNextRun() {
      this.getRunner().waitUntilNextRun()
      this.menu = false
    },
    testRunHook() {
      this.getRunner().testRunHook(this.flow?.trigger)
      this.menu = false
    },
    reRunFlow() {
      this.getRunner().reRunFlow(this.flow?.trigger)
      this.menu = false
    },
    runWithPayload() {
      this.getRunner().runWithPayload(this.flow?.trigger, JSON.parse(this.jsonPayloadString))
      this.menu = false
    },
    openPageAndWaitUntilNextRun() {
      this.getRunner().openPageAndWaitUntilNextRun()
      this.menu = false
    },
    stop(){
      this.getRunner().stop()
    },
    getRunner() {
      if(this.flowRunner == null) {
        this.flowRunner = new FlowRunner(this.flow)
      }
      return this.flowRunner
    },
    togglJsonInput() {
      this.showJsonInput = !this.showJsonInput
      this.menu = false
        this.$nextTick(() => {
          this.menu = true
        })
    },
  },
  computed: {
    flowRunnerError() {
      return this.flowRunner?.state == 'error'
    },
    flowRunnerErrorDescription() {
      return this.flowRunner?.errorDescription
    },
    actions() {
      let flow = this.flow 
      if(flow && flow.isFlowFormFlow()) {
        return this.flowRunnerActions.flowForm.actions
      }
      let trigger = this.trigger
      return trigger ? this.flowRunnerActions[trigger.type].actions : []
    },
    showFlowForm() {
      return this.flow?.isFlowFormFlow()
    },
    testButtonIcon() {
      return this.actions.length === 1 ? 'mdi-play' : 'mdi-dots-horizontal'
    },
    canTestRun() {
      if (this.flow?.hasTriggerLink) {
        return true
      }
      return (this.trigger != null) && hasPermission(this.trigger, [PERMISSIONS.test])
    },
    instanceWaiting() {
      return this.flowRunner?.waiting()
    },
    runningFlow(){
      return this.flowRunner?.running()
    },
    trigger() {
      return this.flow?.trigger
    },
    instance() {
      return this.flow?.testInstance
    },
    flowFormLink() {
      return this.flow?.trigger?.uri
    },
    isJsonValid() {
      try {
        JSON.parse(this.jsonPayloadString)
        return true
      } catch (e) {
        return false
      }
    },
   },
  components: {
    FlowInstanceFlowform
  }
}
</script>

<style lang="css" scoped>
.errorAlert {
  position: fixed;
  bottom: 84px;
}
</style>
