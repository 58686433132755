<template>
  <div class="block-page-root">
    <LoadingOverlay :show="loading" />
    <v-img
      v-if="headerImageUrl"
      :src="headerImageUrl"
      class="headerImage"
    />
    <v-container v-if="block != null">
      <!-- Grid system with a single row and centered content -->
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          md="8"
          lg="6"
        >
        <AGDraggable
          v-model="block.children"
          @change="onDragChange"
          handle=".grabbable"
        >
          <div
            class=""
            v-for="(child, i) in block.children"
            :key="i"
          >
            <div class="col-12">

              <component
                :ref="`block_${child.id}`"
                :is="component(child)"
                :block="child"
                :editorMode="editorMode"
                @updateBlock="() => updateBlock(child)"
                @delete="deleteChild(child)"
                @add="() => blockPickerIndex = i + 1"
                @addParagraphBlock="text => addParagraphBlock(text, i + 2)"
              />
              <!-- Conditionally display the block picker after the block if it's the chosen index -->
            </div>
            <BlockPicker
              :autofocus="true"
              v-if="blockPickerIndex === i + 1"
              @blockSelected="(type) => addChild(type, i + 2)"
              @addParagraphBlock="text => addParagraphBlock(text, i + 2)"
            />
          </div>
        </AGDraggable>
          <BlockPicker
            v-if="canAddChild"
            @blockSelected="(type) => addChild(type, block.childCount + 1)"
            @addParagraphBlock="text => addParagraphBlock(text, block.childCount + 1)"
          />

          <div class="d-flex justify-end">
            <v-btn
              v-if="canSubmit"
              :loading="loading"
              class="mr-6"
              @click="submitBlock"
              color="primary"
            >{{$t('blocks.submitPage')}}</v-btn>
          </div>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import BlockPicker from '@/components/block/editor/BlockPicker.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import CellInputBlock from '@/components/block/CellInputBlock.vue'
import ParagraphBlock from '@/store/models/blocks/ParagraphBlock'
import ParagraphBlockVue from '@/components/block/ParagraphBlock.vue'
import HeaderBlock from '@/store/models/blocks/HeaderBlock'
import HeaderBlockVue from '@/components/block/HeaderBlock.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import AGDraggable from '@/components/AGDraggable.vue'

export default {
  props: {
    blockUri: {
      type: String
    },
    editorMode: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      block: undefined,
      loading: false,
      blockPickerIndex: null
    }
  },
  computed: {
    headerImageUrl() {
      return this.block?.headerImageUrl
    },
    canAddChild() {
      return hasPermission(this.block, [ PERMISSIONS.addChild ]) && this.editorMode
    },
    canSubmit() {
      return this.submitAction
    },
    submitAction() {
      return this.block?.actions?.find( action => action.name == 'submit')
    }
  },
  watch: {
    blockUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null) {
          return
        }
        this.loading = true
        try {
          this.loadBlock()
        } finally {
          this.loading = false
        }
      }
    }
  },
  methods: {
    async loadBlock() {
      try {
        this.block = await this.$store.dispatch('AGReadBlock', { blockUri: this.blockUri, customErrorHandling: [400]})
      } catch (error) { 
          if (error.response?.status === 400) {
            this.$emit('unaccessibleView')
          }
      }
    },
    async deleteChild(child) {
      if (!this.editorMode) {
        return
      }
      child.deleted = true
      await this.$store.dispatch('AGDeleteBlock', child)
      this.loadBlock()
    },
    async addChild(type, position) {
      if (!this.editorMode) {
        return
      }
      if (type == null) {
        this.blockPickerIndex = null
        return
      }
      const response = await this.$store.dispatch('AGAddBlockChild', {
        block: this.block,
        type: type,
        position: position
      })
      const newBlock = await this.$store.dispatch('AGReadBlock', { blockUri:  response.headers.location })
      await this.loadBlock()
      this.focus(newBlock)
    },
    async addParagraphBlock(text, position) {
      if (!this.editorMode) {
        return
      }
      const response = await this.$store.dispatch('AGAddBlockChild', {
        block: this.block,
        type: 'paragraph',
        position: position
      })
      const newParagraphBlock = await this.$store.dispatch('AGReadBlock', { blockUri: response.headers.location })
      if (text) {
        newParagraphBlock.text = text
        await newParagraphBlock.updateValue()
      }
      await this.loadBlock()
      this.focus(newParagraphBlock)
      this.blockPickerIndex = null
    },
    component(block) {
      if (block instanceof ParagraphBlock) {
        return ParagraphBlockVue
      } else if (block instanceof HeaderBlock) {
        return HeaderBlockVue
      }
      return CellInputBlock
    },
    focus(block) {
      this.$refs[ `block_${block.id}` ][ 0 ].focus?.()
    },
    updateBlock(block) {
      if (block.deleted || !this.editorMode) {
        return
      }
      block.updateValue()
    },
    onDragChange() {
      this.block.update()
    },
    async submitBlock() {
      // colect values 
      const payload = this.block.payload()
      this.$emit('submitBlock', payload, this.submitAction)
    }
  },
  components: {
    CellInputBlock,
    BlockPicker,
    LoadingOverlay,
    AGDraggable
  }
}
</script>

<style scoped>
.block-page-root {
  height: 100%;
  width: 100%;
  background: white;
  position: inherit;
  overflow: auto;
}

.headerImage {
  object-fit: cover;
  max-height: 240px;
  min-height: 220px;
}
</style>