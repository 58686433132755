import store from '@/store/store.js'
import typeValidation from '@/utils/typeValidation.js'
import Vue from 'vue'
import HalObject from './HalObject'

export default class Entity extends HalObject {

    constructor(data) {
        super(data)
        this.fields = data.fields
        this._id = data._id
        this.dirty = false
        this.updating = false
        this.position = data.position
    }

    changed ( grid, newValue, field ) {
      const position = grid.positionOf(field)
      const type = field.columnType
      // Note that the sanitized value is only used here to determine if the entity has actually changed.
      // In order not to interfere with user typing, the sanitation is only applied when submitting the
      // entity to the backend in updateEntity
      const currentSanitizedValue = typeValidation.sanitizedEntityValue(type, this.fields[position])
      const sanitizedNewValue = typeValidation.sanitizedEntityValue(type, newValue)
      var equal = this.compareValues(type, currentSanitizedValue, sanitizedNewValue )

      if (!equal) {
        this.dirty = { newValue: sanitizedNewValue, field }
      }
      Vue.set(this.fields, position, newValue)
    }

    compareValues( type, a, b ) {
      var equal = false
      if (type.compareOverrides?.equals) {
        equal = type.compareOverrides.equals(a, b)
      } else {
        equal = a == b
      }
      return equal
    }

    async update(virtualGrid) {
      if (!this._id) console.error('entity need to have an valid _id')
      if (!this.dirty) return Promise.resolve()
      const entityPUTObject = this.asPUTObjectIn(virtualGrid)
      this.updating = true
      try {
        const updateResponse = await store().dispatch('AGUpdateEntityOperation', {
          virtualGrid: virtualGrid,
          entity: this
        })
        if (!this.dirty) return Promise.resolve()
        const type = this.dirty.field.columnType
        var equal = this.compareValues( type, this.dirty.newValue, entityPUTObject[this.dirty.field.id] )
        if(equal){
            this.dirty = false
        }
        // update fields with response values
        this.fields = virtualGrid.fields.map(field => updateResponse.data[field.id] ?? updateResponse.data[field.key] ?? null )
      } finally {
        this.updating = false
      }
    }

    asPUTObjectIn (grid) {
      var object = {}
      grid.fields.forEach( (field, index) => {
        const type = field.columnType
        if (type.readonly) {
          object[field.id] = null
        } else {
          const sanitizedValue = typeValidation.sanitizedEntityValue(type, this.fields[index])
          object[field.id] = sanitizedValue
        }
      })
      return object
    }

    loadEditLinks() {
      return store().dispatch('AGListEntityEditLinks', this)
    }
}
