<template>
  <draggable
        v-model="fields"
        filter=".fixed"
        @change="saveFields()"
        >
      <div class="d-flex  align-center mt-3" v-for="(item, index) in fields" :key="item.value" :class="item.fixed || disabled ? 'fixed' :''">
        
        <v-icon class="pr-1 cursor-grab" v-if="!item.fixed" small>mdi-drag-vertical</v-icon>
        <div v-else class="pl-5"></div>
        <div class="d-flex grow justify-space-between align-center">
        <div class="cursor-grab">
         <v-icon small class="mr-3">{{ item.icon }}</v-icon>
         <span class="text-subtitle-2 shrink text-truncate mr-4">{{ item.label }}</span> 
       </div>
        <v-switch
            class="mt-0 pt-0"
            v-model="shownFieldIds"
            dense inset 
            hide-details
            hide-label
            @change="savedSwitchFields()"
            color="primary"
            :value="item.value"
            :data-testid="`column-card-visibility-switch-${index}`"
            :disabled="disabled || (shownFieldIds.length == 1 && shownFieldIds.includes(item.value))"
          >
          </v-switch>
        </div>
      </div>
        </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    props: ['virtualGrid', 'statefulViewUri', 'disabled'],

    data() {
    return {
      fields: [],
      shownFieldIds: [],
    }
  },
  watch: {
    statefulViewUri: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal != oldVal){
          this.fields = this.statefulView.cardFields()
          this.shownFieldIds = this.fields.filter( field => field.shown).map( field => field.value)
        }
      }
    },
    hiddenFields: {
      immediate: false,
      handler(newVal, oldVal) {
        if(newVal != oldVal){
          // react changes on hidden fields
          this.fields = this.statefulView.cardFields()
          this.shownFieldIds = this.fields.filter( field => field.shown).map( field => field.value)
        }
      }
    }
  },
  computed: {
    hiddenFields() {
      return this.virtualGrid.hiddenFields
    },
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
  },
  methods: {
    savedSwitchFields() {
      this.fields.forEach(field => {
        field.shown = this.shownFieldIds.some(aFieldId => field.value == aFieldId)
      })
      this.saveFields()
    },
    async saveFields() {
      await this.statefulView.updateShownCardFields(this.fields)
      this.fields = this.statefulView.cardFields()
      this.shownFieldIds = this.fields.filter( field => field.shown).map( field => field.value)
    }
  },
  components: {
    draggable
  }
}
</script>

<style>

</style>