<template>
  <div class="headerCell d-flex justify-center align-center">
    <ColumnSettingsMenu :virtualGrid="virtualGrid" createOnSave>
      <template v-slot:customActivator="{on}">
        <v-btn
          :disabled="disabled"
          color="grey"
          elevation="0"
          dark
          icon
          small
          :loading="loading"
          v-on="on"
          @contextmenu.prevent="addColumn"
          data-testid="addColumnButton"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </ColumnSettingsMenu>
  </div>
</template>

<script>
import ColumnSettingsMenu from '@/components/gridView/ColumnSettingsMenu.vue'

export default {
  props: {
    virtualGrid: null,
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addColumn() {
      this.$emit('addColumn')
    }
  },
  components: {
    ColumnSettingsMenu
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headerCell {
  width: 35px;
  overflow: hidden;
  border-style: none solid none none;
  border-width: 1px;
  border-color: #d3d2d4;
}
</style>
