<template>
  <v-menu
    v-model="menu"
    ref="menu"
    content-class="columns-settings-menu"
    :close-on-content-click="false"
    :close-on-click="false"
    offset-x
    :attach="attach"
    v-bind="$attrs"
  >
    <template v-if="!noActivator" v-slot:activator="{ on, attrs }">
      <slot name="customActivator"
          :attrs="attrs"
          :on="on"
        >
        <v-btn
          :data-testId="`showColumnSettingsButton-${index}`"
          icon
          x-small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </slot>
    </template>
    <!-- Force menu content to rerender when open -->
    <ColumnSettings
      v-if="menu"
      v-bind="$props"
      @close="close"
      @typeGroupClicked="activateMenu"
      @columnUpdated="$emit('columnUpdated')"
      :data-testId="`columnSettingsMenu-${index}`"
    />
  </v-menu>
</template>

<script>
import ColumnSettings from '@/components/gridView/ColumnSettings.vue'

export default {
  props: {
    virtualGrid: null,
    field: null,
    attach: null,
    index: null,
    createOnSave: {
      type: Boolean,
      default: () => false
    },
    createPosition: null,
    noActivator: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    menu: false
  }),
  methods: {
    open() {
      this.menu = true
    },
    close() {
      this.menu = false
    },
    activateMenu() {
      // This allows the menu to be properly re-positioned, if needed,
      // when the type selection group is opened or closed
      this.$refs.menu.activate()
    }
  },
  components: {
    ColumnSettings
  }
}
</script>

<style scoped>
.columns-settings-menu {
  max-height: 80%;
}
</style>