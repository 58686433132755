<template>
  
  <v-sheet elevation="0" rounded class="mt-2 mb-5 pa-1 grey lighten-2 one-line d-flex">
    <input ref="linkInput" class="full-width" readonly id="url" :value="link" />
    <v-tooltip open-delay="400" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" min-width="32" width="32" class="mr-1" depressed small @click="copyLink">
          <v-icon color="primary" small>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("virtualGrid.qrCode.dialog.buttons.copy") }}</span>
    </v-tooltip>

    <v-tooltip open-delay="400" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" min-width="32" width="32" class="mr-1" small @click="openLink" depressed>
          <v-icon color="primary" small>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("virtualGrid.qrCode.dialog.buttons.open") }}</span>
    </v-tooltip>

    <v-tooltip open-delay="400" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" min-width="32" width="32" small @click="removeShare" depressed>
          <v-icon color="red" small>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("virtualGrid.qrCode.dialog.buttons.remove") }}</span>
    </v-tooltip>
  </v-sheet>
</template>

<script>
import { sharedLinksMethods } from '@/mixins/sharedLinksMethods.js'
import { base64EncodeUrl } from '@/utils/base64Url.js'

export default {
  mixins: [sharedLinksMethods],
  props: {
    virtualGridUri: null,
    statefulViewUri: null,
  },
  data() {
    return {
      uri: null,
      share: undefined,
      shareLoading: false,
      loading: false,
      links: [],
    }
  },
  watch: {
    statefulViewUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.loading = true
          try {
            await this.loadShares()
          } finally {
            this.loading = false
          }
        }
      }
    }
  },
  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    link() {
      return `${window.location.origin}/#/view/${base64EncodeUrl(this.statefulView.uri)}`
    },
  },
  methods: {
    async createShare() {
      this.shareLoading = true
      try {
        await this.$store.dispatch('AGShareViewOperation', {statefulView: this.statefulView})
        await this.loadShares()
      } finally {
        this.shareLoading = false
      }
    },
    async removeShare() {
      this.loading = true
      try {
        const share = await this.$store.dispatch('AGReadViewShareOperation', { shareUri: this.share })
        await this.$store.dispatch('AGDeleteViewShareOperation', { share })
        await this.loadShares()
      } finally {
        this.loading = false
      }
    },
    async loadShares() {
      const shares = await this.$store.dispatch('AGListViewSharesOperation', {statefulView: this.statefulView})
      this.share = shares[0]?._links.self.href
    },
    addLink() {
      this.uri = this.virtualGridUri
    },
    openLink() {
      var win = window.open(this.link, '_blank')
      win.focus()
    },
    copyLink() {
      this.$refs.linkInput.select()
      try {
        document.execCommand('copy')
      } catch (error) {
        this.$apptiveErrorReporter.captureException(error)
        alert('Unable to copy')
      }
    },
  },
  components: {
  }
}
</script>

<style scoped>
.fit-parent-img {
  width: auto;
  height: 40px;
}

.one-line {
  overflow: auto;
  overflow-wrap: normal;
}

.full-width {
  outline: none;
  width: 100%;
}
</style>
