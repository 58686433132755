import store from '@/store/store.js'
import newAjv from '@/utils/newAjv.js'
import HalObject from '@/store/models/HalObject'
import Field from './Field'
import Vue from 'vue'
import TextBlock from './TextBlock'
import i18n from '@/plugins/i18n'
import FormPage from './FormPage'
import { actions, triggers } from '@/constants/afterSubmitTypes.js'
import { vuetifyColors } from '@/plugins/vuetify'

// eslint-disable-next-line no-unused-vars
const CURRENT_VERSION = 4
const VERSION = 'version'
const LOGO_IMAGE = 'logoImage'
const BUTTON_TITLE = 'buttonTitle'
const BUTTON_COLOR = 'buttonColor'
const PRIMARY_COLOR = 'primaryColor'
const SUCCESS_MESSAGE = 'successMessage'
const SUCCESS_TITLE = 'successTitle'
const PAGES = 'pages'
const CARD_COLOR = 'cardColor'
const BACKGROUND_COLOR = 'backgroundColor'
const HEADER_COLOR = 'headerColor'
const CARD_FLAT = 'flatCard'

// Version 3
const PAGE_ID = 'pageId'
const FIELD_INDEX = 'fieldIndex'
const BLOCKS = 'blocks'
const PAGE_IDS = 'pageIds'

// Version 3.1 (no migration needed)
const ADS_HIDDEN = 'adsHidden'

// Version 4 ( added a action that defines an action after a form was submitted)
// Before V4 a form has per default an button to send an additional answer. The Button
// Text was in property additionalAnswer.
const AFTER_SUBMIT_ACTION = 'afterSubmitAction'
  const ACTION = 'action'
  const TRIGGER = 'trigger'
  const ACTION_BUTTON_TITLE = 'buttonTitle'
  const TARGET_URL = 'targetUrl'
  const TRIGGER_DELAY = 'delay'

const defaultAction = {
  ACTION: actions.ADDITIONAL_ANSWER,
  TRIGGER: triggers.BUTTON,
}

const defaultSyle = {
  PRIMARY_COLOR: vuetifyColors.primary,
  BUTTON_COLOR: vuetifyColors.primary,
  CARD_COLOR: '#ffffff',
  HEADER_COLOR: '#E7E7E7',
  BACKGROUND_COLOR: '#f7f7f7',
}

// Version 4.1 no migration needed ( added save form data to continue later)
const SAVE_PROGRESS = 'saveProgress'

// Version 4.2 no migration needed added autosubmit feature 
const AUTO_SUBMIT = 'autoSubmit'

// Version 4.3 no migration needed added i18n feature
const DEFAULT_LANGUAGE = 'defaultLanguage'
const ADDITIONAL_LANGUAGES = 'additionalLanguages'
const I18N = 'i18n'
const I18N_FEATURE = 'i18nFeature'

// Version 4.4 no migration needed added field style
const FIELD_STYLE = 'fieldStyle'

export default class Form extends HalObject {
    constructor(data) {
        super(data)
        this.fields = data.fields.map(field => new Field(field))
        this._validate = undefined
        this.actions = data.actions
        // Hack for submitting non-displayed components
        this.nonDisplayedComponents = data.nonDisplayedComponents ?? []
        this.components = []
        data.components.forEach((component, index) => {
            // Hack for filtering out CreatedBy components
            const schemaItem = this.fields[index].type.jsonSchema
            if(schemaItem.typeName === 'createdby') {
                this.nonDisplayedComponents.push(component)
            } else {
                this.components.push(component)
            }
        })
        this.name = data.name
        this.uri = data.uri ?? data._links?.self.href
        this.headerImage = data.headerImage
        this.properties = data.properties ?? {}
        this.fieldProperties = data.fieldProperties ?? {}
        this.title = data.title
        this.description = data.description
        this.schemaObject = data.schemaObject
        this.id = data.id
        this.migrate()
        this.pages = this.importPages()
        this._validatePageFieldsIds()
        this.localData = this._loadLocalData()
        this.prefilledWithLocalDataKey = null
        this.plan = data.plan

    }


/*  -------------------- COPIED FROM StatefulView.js --------------------------  */

    get version() {
        return this.properties[VERSION] ?? 1
    }

    set version(newVal) {
        this.properties[VERSION] =  newVal
    }

    getI18nResource(language, key) {
        if (!this.i18nFeature || this.i18n == null || language === this.defaultLanguage) {
            return this[key]
        }
        return this.i18n[language]?.[key] || this[key]
    }

    setI18nResource(language, key, newVal) {
        if (!this.i18nFeature || this.i18n == null || language === this.defaultLanguage) {
            this[key] = newVal
        } else {
            this.i18n[language][key] = newVal
        }
    }

    isOfAcceptedType() {
        throw 'Should be implemented by subclass'
      }

      getFieldProperty(property) {
        const fieldId = Object.keys(this.fieldProperties).find(key => this.fieldProperties[key][property])
        if (this.parentGrid.fields.some(field => field.id === fieldId && this.isOfAcceptedType(field))) {
          return fieldId
        } else {
          return undefined
        }
      }

      deleteFieldProperty(fieldId, property) {
        if (fieldId != null && property in this.fieldProperties[fieldId]) {
          delete this.fieldProperties[fieldId][property]
        }
      }

      setFieldProperty(fieldId, property, value) {
        if (fieldId != null) {
          this.fieldProperties[fieldId] = {
            ...this.fieldProperties[fieldId],
            [property]: value
          }
        }
      }

/*  -------------------------------------------------------------------------  */

    migrate() {
        if (this.version <= 2) {
            const pages = this.properties[PAGES] ?? [
                { fieldIds: this.components.map( (comp) => comp.fieldId ) }
            ]
            const textBlocks = []
            const formPages = []
            pages.forEach((page) => {
                const formPage = new FormPage()
                // page.fieldIds used to be a mixed array with fieldIds and textBlock objects
                page.fieldIds.forEach((fieldIdOrBlock, fieldIndex) => {
                    if (typeof fieldIdOrBlock !== 'object' && this.fields.some(field => field.id === fieldIdOrBlock)) {
                        this.setFieldProperty(fieldIdOrBlock, PAGE_ID, formPage.id)
                        this.setFieldProperty(fieldIdOrBlock, FIELD_INDEX, fieldIndex)
                    } else if(fieldIdOrBlock?.type != null) {
                        textBlocks.push({
                            id: fieldIdOrBlock.id,
                            pageId: formPage.id,
                            fieldIndex,
                            style: fieldIdOrBlock.style,
                            type: fieldIdOrBlock.type,
                            text: fieldIdOrBlock.text,
                        })
                    }
                })
                formPages.push(formPage)
            })
            this.properties[PAGE_IDS] = formPages.map(page => page.id)
            this.properties[BLOCKS] = textBlocks
            delete this.properties[PAGES]
            this.version = 3
        }
        if (this.version <= 3) {
          this.properties[AFTER_SUBMIT_ACTION] = {}
          this.afterSubmitAction = defaultAction.ACTION
          this.afterSubmitTrigger = defaultAction.TRIGGER
          // get old property
          let buttonTitle = this.properties['additionalAnswer']
          if(buttonTitle) {
            this.actionButtonTitle = buttonTitle
            delete this.properties['additionalAnswer']
          }
          this.version = 4
        }
        // console.log(this)
    }

    reload() {
      return store().dispatch('AGReadFormOperation', this.uri)
    }

    validate() {
      const payload = this._payload()
      var validator = this._validator()
      return validator( payload )
    }

    validatePage(page) {
      const payload = this._payloadPage(page)
      var validator = this._validator()
      return validator( payload )
    }

    schema() {
        const schema = {
            type: 'object',
            properties: {},
            required: []
        }
        for (let index = 0; index < this.components.length ; index++) {
            const field = this.fields[index]
            schema.properties[field.id] = field.type.jsonSchema
            if (this.components[index].required) {
                schema.required.push(field.id)
            }
        }
        return schema
    }

    _validator() {
        if (this._validate) {
            return this._validate
        } else {
            var ajv = newAjv()
            var validate = ajv.compile(this.schema())
            this._validate = validate
            return validate
        }
    }

    _payloadPage(page) {
      let components = page.items.map( id => this.components.find( comp => comp.fieldId == id ))
      const payload = {}
      components.forEach((component) => {
        payload[component.property] = component.value
      })
      return payload
    }

    _payload() {
      const payload = {}
      this.components.forEach((component) => {
        payload[component.property] = component.value
      })
      return payload
    }

    validationErrors() {
      const payload = this._payload()
      var validator = this._validator()
      validator(payload)
      return validator.errors || []
    }

    submitFormPayload() {

        const payload = {}
        const allComponents = [...this.components, ...this.nonDisplayedComponents]
        allComponents.forEach((component) => {
            const typeString = this.typeString(component.fieldId)
            if(typeString === 'array') {
                const value = Array.isArray(component.value) ? component.value : []
                payload[component.fieldId] = value
            } else if (component.value != null) {
                if (typeString === 'string' || typeString === 'object') {
                    payload[component.fieldId] = component.value
                } else if (typeString === 'integer') {
                    const value = parseInt(component.value)
                    if (isNaN(value)) throw ('value not convertable to a int')
                    payload[component.fieldId] = value
                } else if (typeString === 'number') {
                    const value = parseFloat(component.value)
                    if (isNaN(value)) throw ('value not convertable to a decimal')
                    payload[component.fieldId] = value
                } else if (typeString === 'date-time') {
                    payload[component.fieldId] = component.value
                } else if (typeString === 'boolean') {
                    if (component.value === true || component.value === false) {
                        payload[component.fieldId] = component.value
                    } else {
                        throw ('value is not a boolean')
                    }
                } else {
                    throw (`missing implementation for schema type: ${typeString}`)
                }
            } else {
                payload[component.fieldId] = null
            }
        })
        return payload
    }

    typeString(fieldId) {
        return this.schemaWith(fieldId).type
    }

    schemaWith(fieldId) {
        return this.fieldWith(fieldId).type.jsonSchema
    }

    fieldWith(fieldId) {
        const field = this.fields.find(field => field.id === fieldId)
        return field
    }

    saveLocalData() {
      let formData = this._formData()
      // get previous stored data sets
      let timestamp = new Date().toISOString()
      this.localData[timestamp] = formData
      let json = JSON.stringify(this.localData)
      localStorage.setItem( this.id , json )
    }

    _loadLocalData() {
      let data = localStorage.getItem( this.id )
      return data ? JSON.parse(data) : {}
    }

    deleteSavedDataWith(key){
      Vue.delete(this.localData, key)
      let json = JSON.stringify(this.localData)
      localStorage.setItem( this.id , json )
    }

    clearLocalSavedData() {
      this.localData = {}
      localStorage.removeItem( this.id )
    }

    _formData() {
      const data = {}
      this.components.forEach((component) => {
        if (component.value) {
          data[component.fieldId] = component.value
        }
      })
      return data
    }

    prefillWithLocalData(prefill) {
      this.components.forEach(component => {
        if (component.fieldId in prefill) {
          component.value = prefill[component.fieldId]
        }
      })
    }

    title() {
        return this.title ? this.title : this.uri.substring(15, 20)
    }

    submit() {
        return store().dispatch('AGSubmitFormOperation', {
            form: this,
            payload: this.submitFormPayload()
        }).then(response => {
            if (this.prefilledWithLocalDataKey) {
                this.deleteSavedDataWith(this.prefilledWithLocalDataKey)
            }
            return response
        })
    }

    addComponentToPage(newComponent, page, field) {
      // add to fieldIds of the page
      page.items.push(newComponent.fieldId)
      this._addComponent(newComponent, field)
      this.sortComponents()
      return this.updateComponents()
      .then(response => {
          store().dispatch('AGReadFormOperation', this.uri)
          return response
      })
    }

    _addComponent(newComponent, field) {
        this.components.push(newComponent)
        this.fields.push(field)
    }

    changeComponentType(component, type) {
        if (component.type === type) {
            return
        }
        component.type = type
        return this.updateComponents()
        .then(response => {
            store().dispatch('AGReadFormOperation', this.uri)
            return response
        })
    }

    removeComponentFromPage(newComponent, page) {
      const index = page.items.indexOf(newComponent.fieldId ?? newComponent)
      if (index > -1) {
        page.items.splice(index, 1)
      }
      this._removeComponent(newComponent)

    }

    _removeComponent(aFormComponent) {
        const index = this.components.indexOf(aFormComponent)
        if (index > -1) {
            this.components.splice(index, 1)
        }
        return this.updateComponents()
    }

    rename (newName) {
      this.name = newName
        return store().dispatch('AGRenameFormOperation', this)
    }

    async updateComponents() {
        this.exportPages()
        const form = await store().dispatch('AGUpdateFormOperation', this)
        return form
    }

    clearForm() {
        this.components.forEach(component => component.value = undefined)
    }

    clone() {
        return new Form(JSON.parse(JSON.stringify(this)))
    }

    get logoImage() {
        return this.properties[LOGO_IMAGE]
    }

    set logoImage(logoImage) {
        Vue.set(this.properties, LOGO_IMAGE, logoImage)
    }

    get buttonTitle() {
        return this.properties[BUTTON_TITLE]
    }

    set buttonTitle(title) {
        Vue.set(this.properties, BUTTON_TITLE, title)
    }

    get successTitle() {
        return this.properties[SUCCESS_TITLE]
    }

    set successTitle(title) {
        Vue.set(this.properties, SUCCESS_TITLE, title)
    }

    get successMessage() {
        return this.properties[SUCCESS_MESSAGE]
    }

    set successMessage(message) {
        Vue.set(this.properties, SUCCESS_MESSAGE, message)
    }

    get buttonColor() {
        return this.properties[BUTTON_COLOR] || defaultSyle.BUTTON_COLOR
    }

    set buttonColor(color) {
        let sanitized = color.length > 0 ? color : null
        Vue.set(this.properties, BUTTON_COLOR, sanitized)
    }

    get primaryColor() {
        return this.properties[PRIMARY_COLOR] || defaultSyle.PRIMARY_COLOR
    }

    set primaryColor(color) {
        let sanitized = color.length > 0 ? color : null
        Vue.set(this.properties, PRIMARY_COLOR, sanitized)
    }

    get cardColor() {
        return this.properties[CARD_COLOR] || defaultSyle.CARD_COLOR
    }

    set cardColor(color) {
        let sanitized = color.length > 0 ? color : null
        Vue.set(this.properties, CARD_COLOR, sanitized)
    }

    get backgroundColor() {
        return this.properties[BACKGROUND_COLOR] || defaultSyle.BACKGROUND_COLOR
    }

    set backgroundColor(color) {
        let sanitized = color.length > 0 ? color : null
        Vue.set(this.properties, BACKGROUND_COLOR, sanitized)
    }

    get headerColor() {
        return this.properties[HEADER_COLOR] || defaultSyle.HEADER_COLOR
    }

    set headerColor(color) {
        let sanitized = color.length > 0 ? color : null
        Vue.set(this.properties, HEADER_COLOR, sanitized)
    }

    get flatCard() {
        return this.properties[CARD_FLAT]
    }

    set flatCard(boolean) {
        Vue.set(this.properties, CARD_FLAT, boolean)
    }

    get adsHidden() {
        return this.properties[ADS_HIDDEN]
    }

    set adsHidden(boolean) {
        Vue.set(this.properties, ADS_HIDDEN, boolean)
    }

    get afterSubmitAction() {
        return this.properties[AFTER_SUBMIT_ACTION][ACTION]
    }

    set afterSubmitAction(action) {
        Vue.set(this.properties[AFTER_SUBMIT_ACTION], ACTION, action)
    }

    get afterSubmitTrigger() {
        return this.properties[AFTER_SUBMIT_ACTION][TRIGGER]
    }

    set afterSubmitTrigger(trigger) {
        // prevent loop for auto reload form if form autosubmit
        if(trigger == triggers.AUTO && this.autoSubmit) {
            this.autoSubmit = false
        }
        Vue.set(this.properties[AFTER_SUBMIT_ACTION], TRIGGER, trigger)
    }

    get afterSubmitTriggerDelay() {
        return this.properties[AFTER_SUBMIT_ACTION][TRIGGER_DELAY]
    }

    set afterSubmitTriggerDelay(delay) {
        Vue.set(this.properties[AFTER_SUBMIT_ACTION], TRIGGER_DELAY, delay)
    }

    get afterSubmitActionButtonTitle() {
        return this.properties[AFTER_SUBMIT_ACTION][ACTION_BUTTON_TITLE]
    }

    set afterSubmitActionButtonTitle(title) {
        Vue.set(this.properties[AFTER_SUBMIT_ACTION], ACTION_BUTTON_TITLE, title)
    }

    set afterSubmitTargetUrl(url) {
        Vue.set(this.properties[AFTER_SUBMIT_ACTION], TARGET_URL, url)
    }

    get afterSubmitTargetUrl() {
        return this.properties[AFTER_SUBMIT_ACTION][TARGET_URL]
    }

    set saveProgress(boolean) {
        Vue.set(this.properties, SAVE_PROGRESS, boolean)
    }

    get saveProgress() {
        return this.properties[SAVE_PROGRESS]
    }
    
    set autoSubmit(boolean) {
        // prevent loop for forms automtic reload after submit
        if(boolean && this.afterSubmitAction == actions.ADDITIONAL_ANSWER && this.afterSubmitTrigger == triggers.AUTO) {
            this.afterSubmitTrigger = triggers.BUTTON
        }
        Vue.set(this.properties, AUTO_SUBMIT, boolean)
    }
    
    get autoSubmit() {
        return this.properties[AUTO_SUBMIT]
    }

    set i18nFeature(boolean) {
        Vue.set(this.properties, I18N_FEATURE, boolean)
    }

    get i18nFeature() {
        return this.properties[I18N_FEATURE]
    }

    get defaultLanguage() {
        return this.properties[DEFAULT_LANGUAGE]
    }

    set defaultLanguage(language) {
        Vue.set(this.properties, DEFAULT_LANGUAGE, language)
    }

    get languages() {
        return [this.defaultLanguage, ...this.additionalLanguages]
    }

    get additionalLanguages() {
        return this.properties[ADDITIONAL_LANGUAGES] ?? []
    }

    set additionalLanguages(languages) {
        Vue.set(this.properties, ADDITIONAL_LANGUAGES, languages)
    }

    get i18n() {
        return this.properties[I18N]
    }

    set i18n(i18n) {
        Vue.set(this.properties, I18N, i18n)
    }

    get textBlocks() {
        return this.properties[BLOCKS]
    }

    get fieldStyle() {
        return this.properties[FIELD_STYLE] || 'default'
    }

    set fieldStyle(style) {
        Vue.set(this.properties, FIELD_STYLE, style)
    }

    importPages() {
        return this.properties[PAGE_IDS].map(id => {
            let page = {
                id,
                items: Array(this.fields.length).fill(null)
            }
            Object.keys(this.fieldProperties).forEach(key => {
                if (this.fieldProperties[key][PAGE_ID] === id) {
                    page.items[this.fieldProperties[key][FIELD_INDEX]] = key
                }
            })
            page.items = page.items.filter(item => item != null)
            this.properties[BLOCKS].forEach(textBlock => {
                const textBlockInstance = new TextBlock(textBlock)
                if (textBlock.pageId === id) {
                    page.items.splice(textBlock.fieldIndex, 0, textBlockInstance)
                }
            })
            return page
        })
    }

    exportPages() {
        const oldPages = this.pages
        // Reset properties
        Object.keys(this.fieldProperties).forEach(fieldId => {
            this.deleteFieldProperty(fieldId, PAGE_ID)
            this.deleteFieldProperty(fieldId, FIELD_INDEX)
        })
        this.properties[BLOCKS] = []
        this.properties[PAGE_IDS] = []

        // Rebuild properties from pages array
        oldPages.forEach((page) => {
            this.properties[PAGE_IDS].push(page.id)
            page.items.forEach((item, index) => {
                if (typeof item === 'string') {
                    this.setFieldProperty(item, PAGE_ID, page.id)
                    this.setFieldProperty(item, FIELD_INDEX, index)
                } else if( item?.type === 'textBlock' ) {
                    item[PAGE_ID] = page.id
                    item[FIELD_INDEX] = index
                    this.properties[BLOCKS].push(item)
                }
            })
        })
    }

    async addPage() {
        const newPage = new FormPage()
      this.pages.push(newPage)
      await this.updateComponents()
    }

    async removePage(page) {
        const index = this.pages.indexOf(page)
        if (index > -1) {
            this.pages.splice(index, 1)
        }
        // remove components of this page to keep components in sync
        let components = page.items.map( id => this.components.find( comp => comp.fieldId == id ))
        components.forEach((aComp) => {
          const index = this.components.indexOf(aComp)
          if (index > -1) {
              this.components.splice(index, 1)
          }
        })
        await this.updateComponents()
    }

    sortComponents() {
      // we moved a form field on a page. Now we need to update the components arrayMove
      // to keep the order in sync for e.g. flutter app not using the pages
      let pageFieldIds = this.pages.flatMap( page => page.items )
      this.components.sort((a, b) => pageFieldIds.indexOf(a.fieldId) - pageFieldIds.indexOf(b.fieldId))
    }

    _validatePageFieldsIds() {
      // remove fieldIds not part of the components
      let componentFieldIds = this.components.map( comp => comp.fieldId )
      this.pages.forEach((page) => {
        // remove fieldids that are not part of components
        page.items = page.items.filter( id =>
          id.type !=null || componentFieldIds.includes(id)
        )
      })
      // find fields that are not on a page
      let pageFieldIds = this.pages.flatMap( page => page.items )
      let remainingFieldIds = componentFieldIds.filter( id => !pageFieldIds.includes(id) )
      // add fields to first pages
      this.pages[0].items.push(...remainingFieldIds)

    }

    componentMovedOnPage() {
      this.sortComponents()
      return this.updateComponents()
      .then(response => {
          store().dispatch('AGReadFormOperation', this.uri)
          return response
      })
    }

    addTextBlock(page) {
        page.items.push(new TextBlock({text: i18n.t('forms.builder.newTextBlock')}))
        this.updateComponents()
    }

    componentsOnVisiblePage(page) {
        if(this.pages.length == 0) {
            return this.components
        }
        let visiblePage = this.pages[page]
        if (visiblePage == null) {
            return []
        }
        return visiblePage.items
            .map( (id) => {
            if (typeof id === 'string') {
                return this.components.find( component => component.fieldId == id)
            }
            return id
            })
            .filter(component => component !== undefined)
    }

    downgrade() {
        // called from store plugin paywallDowngradPlugin 
        if(this.adsHidden || this.saveProgress){
            this.adsHidden = false
            this.saveProgress = false
            this.updateComponents()
        }
    }

    loadLinks() {
        return store().dispatch('AGListFormLinksOperation', this)
    }

    get defaultModel() {
        return {
            title: this.title,
            description: this.description,
            buttonTitle: this.buttonTitle,
            successTitle: this.successTitle,
            successMessage: this.successMessage,
            afterSubmitActionButtonTitle: this.afterSubmitActionButtonTitle
        }
    }

    blankI18nLanguageModel() {
        return {
            title: undefined,
            description: undefined,
            buttonTitle: undefined,
            successTitle: undefined,
            successMessage: undefined,
            afterSubmitActionButtonTitle: undefined,
        }
    }
}
