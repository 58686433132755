import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class PageBlock extends Block {
  constructor(data) {
    super(data)
  }

  get name() {
    return this.page.name
  }

  set name(newVal) {
    this.page.name = newVal
  }

  async patchName(newName) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        page: {
          name: newName
        }
      }
    })
    this.name = newName
  }

  async update() {
    await store().dispatch('AGUpdateBlock', {
      block: this,
      payload: this
    })
  }

  get headerImageUrl() {
    return this.page.headerImage
  }

  set headerImageUrl(newVal) {
    this.page.headerImage = newVal
  }

  async setHeaderImageUrl(url) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        page: {
          headerImage: url
        }
      }
    })
    this.headerImageUrl = url
  }
}