import ApiClient from '@/ApiClient'
import Vue from 'vue'
import FlowInstance from '@/store/models/flow/FlowInstance.js'
import {instanceStates} from '@/store/models/flow/FlowInstance.js'
import apptiveErrorReporter from '@/plugins/apptiveErrorReporter.js'
import store from '../../store'
import i18n from '@/plugins/i18n.js'

export const states = {
  'created':'created' ,
  'polling':'polling' ,
  'done':'done' ,
  'error':'error' ,
}

const POLL_INTERVAL = 3000

export default class FlowRunner {

  constructor(flow) {
    this.flow = flow
    this.state = null
    this.errorDescription = null
  }

  stop() {
    this.setState(states.done)
  }

  async testRunHook( webhook ) {
    this.setState(states.created)
    try {
      Vue.set(this.flow, 'testInstance', null)
      await webhook.testRun()
      let testInstance = await this.poll({
          fn: () => this.loadTestInstance(this.flow),
          validate: testInstance => testInstance != null,
          interval: POLL_INTERVAL,
        })
        Vue.set(this.flow, 'testInstance', testInstance)
        await this.pollUntilStepsDone(testInstance)
    } catch (error) {
      apptiveErrorReporter.captureException(error)
      this.setState(states.error)
    }
  }

  async runWithPayload( webhook, payload = {} ) {
    this.setState(states.created)
    try {
      Vue.set(this.flow, 'testInstance', null)
      // run with payload call will return before flow execution is done
      await webhook.runExternalHook(payload)
      // fetch 
      let nextInstance = await this.poll({
        fn: () => this.getLatestFlowInstance(),
        validate: testInstance => testInstance != null,
        interval: POLL_INTERVAL,
      })
      const nextInstanceFull = await store().dispatch('AGReadFlowInstanceOperation', nextInstance)
      Vue.set(this.flow, 'testInstance', nextInstanceFull)

      await this.pollUntilStepsDone(nextInstanceFull)
      this.setState(states.done)
    } catch (error) {
      apptiveErrorReporter.captureException(error)
      this.setState(states.error)
    }
  }

  // open the first page 
  // wait until there is a new instance or if no previours a instance
  // poll until the instance is done or the the runner is stopped 
  async openPageAndWaitUntilNextRun(){
    this.setState(states.created)
    try {
      Vue.set(this.flow, 'testInstance', null)
      const lastInstance = await this.getLatestFlowInstance()
      let nextInstance = await this.poll({
        fn: () => this.getLatestFlowInstance(),
        validate: instance => lastInstance?.id != instance.id,
        interval: POLL_INTERVAL,
      })
      const nextInstanceFull = await store().dispatch('AGReadFlowInstanceOperation', nextInstance)
      Vue.set(this.flow, 'testInstance', nextInstanceFull)

      await this.pollUntilStepsDone(nextInstanceFull)
      this.setState(states.done)
    } catch (error) {
      apptiveErrorReporter.captureException(error)
      this.setState(states.error)
    }

  }

  async reRunFlow(webhook) {
    this.setState(states.created)
    try {
      Vue.set(this.flow, 'testInstance', null)
      const lastInstance = await this.getLatestFlowInstance()
      if (!lastInstance) {
        this.setState(states.error, i18n.t('flow.editor.errors.noPreviousRunFound'))
        return
      }
      const fullInstance = await store().dispatch('AGReadFlowInstanceOperation', lastInstance)
      // get output trigger
      let payload = fullInstance.steps[0].output
      // run with payload call will return before flow execution is done
      await webhook.runExternalHook(payload)
      // fetch 
      let nextInstance = await this.poll({
        fn: () => this.getLatestFlowInstance(),
        validate: instance => lastInstance?.id != instance.id,
        interval: POLL_INTERVAL,
      })
      const nextInstanceFull = await store().dispatch('AGReadFlowInstanceOperation', nextInstance)
      Vue.set(this.flow, 'testInstance', nextInstanceFull)

      await this.pollUntilStepsDone(nextInstanceFull)
      this.setState(states.done)
    } catch (error) {
      apptiveErrorReporter.captureException(error)
      this.setState(states.error)
    }
  }


  async waitUntilNextRun() {
    this.setState(states.created)
    try {
      Vue.set(this.flow, 'testInstance', null)
      const lastInstance = await this.getLatestFlowInstance()
      let nextInstance = await this.poll({
        fn: () => this.getLatestFlowInstance(),
        validate: instance => lastInstance?.id != instance?.id && instance,
        interval: POLL_INTERVAL,
      })
      const fullInstance = await store().dispatch('AGReadFlowInstanceOperation', nextInstance)
      await this.pollUntilStepsDone(fullInstance)
      this.setState(states.done)
    } catch (error) {
      apptiveErrorReporter.captureException(error)
      this.setState(states.error)
    }
  }

  async getLatestFlowInstance () {
    const instances = await store().dispatch('AGListFlowsInstancesOperation', { flow: this.flow, pageSize: 1 , pageIndex: 1 })
    return instances.items.length ? instances.items[0] : null
  }

  poll ({fn, validate, interval, maxAttempts}) {
    let attempts = 0

    const executePoll = async (resolve, reject) => {
      const result = await fn()
      attempts++
      if (validate(result)) {
        return resolve(result)
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error('Exceeded max attempts'))
      } else {
        setTimeout(executePoll, interval, resolve, reject)
      }
    }

    return new Promise(executePoll)
  }

  calculateState (testInstance) {

    if( testInstance?.state == instanceStates.done ) {
      this.setState(states.done)
    }
    else if(testInstance?.state == instanceStates.error) {
      this.setState(states.error)
    }
    else {
      this.setState(states.polling)
    }
  }

  running() {
    return (this.state == states.polling || this.state == states.created)
  }

  waiting() {
    return this.flow?.testInstance?.state == 'waiting' && this.running()
  }

  pollUntilStepsDone(instance) {
    return this.poll({
        fn: () => this.loadInstance(instance),
        validate: testInstance => testInstance.finished(),
        interval: POLL_INTERVAL,
      })
      .then(() => {
        this.calculateState(this.flow.testInstance)
      })
      .catch(err => {
        console.error(err)
        this.calculateState(this.flow.testInstance)
      })
  }

  async loadTestInstance(flow) {
    const response = await ApiClient.getUri(flow.getLink('self'))
    const testInstance = new FlowInstance(response.data.testInstance)
    return testInstance
  }

  async loadInstance(instance) {
    const response = await ApiClient.getUri(instance.getLink('self'))
    instance.update(response.data)
    return instance
  }

  setState(state, errorDescription = null) {
    this.errorDescription = errorDescription
    Vue.set(this, 'state', state)
  }
}
