<template>
  <component :is="wrapperComponent" v-bind="{...wrapperProps, ...$attrs}" v-model="menu" ref="menu">
    <template v-slot:activator="{on}">
      <slot name="activator" :on="on">
        <div class="d-flex flex-column gap-3 entity-picker-body">
          <EntityPickerPreview
            v-model="externalModel"
            class="pr-4"
            :class="{'pl-3': !isCollection}"
            :readonly="readonly"
            :color="color"
            :editOnClick="editOnClick"
            :referencedVirtualGrid="referencedVirtualGrid"
            :isCollection="isCollection"
            :queryEntities="queryEntities"
            :referenceCardSelected="false"
            :showEmptyState="readonly"
          />
          <div v-if="!isFull && !readonly">
            <v-btn v-on="on" text :color="color"><v-icon>mdi-plus</v-icon>{{$t('entityPicker.addButton', { fieldName })}}</v-btn>
          </div>
        </div>
      </slot>
    </template>

    <v-sheet class="d-flex flex-column pb-3" :style="containerStyle">
      <v-sheet class="d-flex flex-row align-center py-2 px-3" elevation="3" style="z-index: 2;">
        <v-text-field
          v-model="filter"
          @input="query"
          filled
          rounded
          dense
          clearable
          hide-details
          :loading="loading"
          data-testid="entitySelectInput"
        >
          <template v-slot:label>
            <div class="d-flex flex-row align-center">
              <v-icon :size="16" class="mr-3">mdi-magnify</v-icon>
              <span>{{ $t('entityPicker.filterPlaceholder', { fieldName }) }}</span>
            </div>
          </template>
        </v-text-field>
        <v-btn @click="menu = false" text icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-sheet>
      <div class="overflow-auto">
        <div v-show="!filter && !isEmpty" class="text-caption pa-3">{{$t('entityPicker.linkedEntries')}}</div>
        <EntityPickerPreview
          v-show="!filter"
          v-model="externalModel"
          class="pr-4"
          :class="{'pl-3': !isCollection}"
          :readonly="readonly"
          :color="color"
          :editOnClick="editOnClick"
          :referencedVirtualGrid="referencedVirtualGrid"
          :isCollection="isCollection"
          :queryEntities="queryEntities"
        />
        <div class="text-caption pa-3 mt-3" v-show="queryResult">{{$t('entityPicker.searchResults')}}</div>
        <v-list class="pa-0">
          <v-list-item
            v-for="(entity, index) in queryEntities"
            :key="index"
            class="mb-3"
            @click="select(entity)"
            :data-testid="`entity-select-item-${index}`"
          >
            <EntityReferenceCard
              :entity="entity"
              :grid="referencedVirtualGrid"
              :selected="isSelected(entity.uri)"
            />
          </v-list-item>
          <v-list-item
            v-if="showLoadMore"
            @click="loadMore"
            style="position: relative;"
            dense
            class="d-flex flex-row justify-center"
          >
            <LoadingOverlay :show="loading" />
            <div class="text-subtitle-2 primary--text">
              {{loadMoreButtonLabel}}
            </div>
          </v-list-item>
        </v-list>
      </div>
      <component
        v-if="canAddEntry"
        :is="paywallComponent"
        :feature="$apptive.constants.features.ENTITIES_PER_GRID"
        :count="gridEntityCount"
        >
        <v-list-item
          v-if="showAddEntry"
          :disabled="!canAddEntry"
          @click="addRow"
        >
          <v-icon small class="mr-3" color="grey">mdi-plus</v-icon>
          <v-list-item-title class="text-subtitle-2 grey--text text--darken-2">{{$t('entityPicker.createEntryButton', { fieldName })}}</v-list-item-title>
        </v-list-item>
      </component>
      </v-sheet>
      <EditEntityDialog
      v-model="editEntityDialog"
      :virtualGrid="referencedVirtualGrid"
      :entity="newEntity"
      @entityCreated="onEntityCreated"
      createEntityMode
    />
  </component>
</template>

<script>
import externalModel from '../../mixins/externalModel'
import EntityReferenceCard from './EntityReferenceCard.vue'
import EntityPickerPreview from './EntityPickerPreview.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'
import { PERMISSIONS, hasPermission } from '../../utils/halUtils'
import { VDialog } from 'vuetify/lib'
import { VMenu } from 'vuetify/lib'
import KeepLastPromise from '@/utils/KeepLastPromise.js'
import LoadingOverlay from '@/components/LoadingOverlay.vue'

export default {
    mixins: [externalModel],
    inject: {
      plan: {
        default: undefined
      }
    },
    props: {
      gridUri: null,
      fieldName: null,
      isCollection: {
        type: Boolean,
        default: () => false
      },
      readonly: {
        type: Boolean,
        default: () => false
      },
      editOnClick: {
        type: Boolean,
        default: () => true
      },
      showAddEntry: {
        type: Boolean,
        default: () => true
      },
      color: {
        default: () => 'accent'
      },
      paywall: {
        type: Boolean,
        default: () => true
      }
    },
    data() {
      return {
        menu: false,
        loading: false,
        filter: undefined,
        queryPromises: new KeepLastPromise(),
        queryResult: undefined,
        editEntityDialog: false,
        newEntity: false,
        gridEntityCount: undefined
      }
    },
    computed: {
      wrapperComponent() {
        return this.$vuetify.breakpoint.mobile ? VDialog : VMenu
      },
      wrapperProps() {
        return this.$vuetify.breakpoint.mobile
          ? {
            'fullscreen': this.$vuetify.breakpoint.mobile,
            'transition': 'dialog-bottom-transition'
          }
          : {
            'close-on-content-click': false,
            'max-height': 600
          }
      },
      containerStyle() {
        return {
          'height': this.$vuetify.breakpoint.mobile ? '100%' : 'inherit',
          'max-height': 'inherit',
          'max-width': this.$vuetify.breakpoint.mobile ? undefined : '550px'
        }
      },
      referencedVirtualGrid() {
        return this.$store.getters.virtualGridWithUri(this.gridUri)
      },
      queryEntities() {
        if (this.queryResult == null) return []
        return this.queryResult.entities
      },
      selectedEntityUris() {
        if (this.externalModel == null) return []
        const refArray = Array.isArray(this.externalModel) ? this.externalModel : [this.externalModel]
        return refArray.map(entity => entity.uri)
      },
      isEmpty() {
        return this.selectedEntityUris.length == 0
      },
      isFull() {
        return !this.isCollection && this.externalModel != null
      },
      canAddEntry() {
        if (this.referencedVirtualGrid == null) {
          return false
        }
        return hasPermission(this.referencedVirtualGrid, [PERMISSIONS.addEntity])
      },
      paywallComponent() {
        return this.paywall ? PaywallMenu : 'div'
      },
      showLoadMore() {
        return this.queryResult != null && this.queryResult.entities.length < this.queryResult.numberOfItems
      },
      loadMoreButtonLabel() {
        if (this.queryResult == null) {
          return ''
        }
        return this.$t('entityPicker.loadMore', {
          fieldName: this.fieldName,
          items: this.queryResult.entities.length,
          totalItems: this.queryResult.numberOfItems
        })
      }
    },
    watch: {
      menu(newVal) {
        if (!newVal) {
          this.$emit('blur')
        } else {
          this.initialQuery()
        }
      },
      canAddEntry: {
        immediate: true,
        async handler(newVal) {
          if (!newVal || this.referencedVirtualGrid == null) return
          // If the user can add an entry, we need to check the limit of entities per grid.
          // We load the first entity in order to get the grid entity count from the paging information
          this.gridEntityCount = await this.referencedVirtualGrid.getEntityCount()
        }
      }
    },
    mounted() {
      this.loadGrid()
    },
    methods: {
      openMenu() {
        this.menu = true
      },
      loadGrid() {
        this.$store.dispatch('AGReadVirtualGridOperation', {
          virtualGridUri: this.gridUri,
          loadEntities: false
        })
      },
      initialQuery() {
        if (!this.queryResult){
          this.query()
        }
      },
      async query() {
        if (!this.filter) {
          // make sure the filter is not null, this happens when the field was cleared
          this.filter = ''
        }
        if (this.gridUri == null) {
          this.queryResult = undefined
          return
        }

        this.loading = true
        const promise = this.$store.dispatch('AGVirtualGridQueryOperation', {
          virtualGridUri: this.gridUri,
          query: this.filter
        })
        this.queryPromises.add(promise,
        (result) => {
          this.queryResult = result
          this.$refs.menu?.activate?.()
        },
        () => this.loading = false)
      },
      loadMore() {
        this.loading = true
        const promise = this.$store.dispatch('AGVirtualGridQueryOperation', {
          virtualGridUri: this.gridUri,
          query: this.filter,
          pageIndex: this.queryResult.page + 1
        })
        this.queryPromises.add(promise,
        (result) => {
          this.queryResult = {
            ...result,
            entities: this.queryResult.entities.concat(result.entities)
          }
          this.$refs.menu?.activate?.()
        },
        () => this.loading = false)
      },
      remove(index) {
        if (!this.isCollection) {
          this.externalModel = null
        } else {
          this.externalModel = this.externalModel.toSpliced(index, 1)
        }
      },
      select(entity) {
        const ref = {
          uri: entity.uri,
          displayValue: this.displayValue(entity),
          fullEntity: entity
        }

        if (!this.isCollection) {
          this.externalModel = this.externalModel?.uri === ref.uri ? null : ref
          this.menu = false
          return
        }

        if (!Array.isArray(this.externalModel)) {
          this.externalModel = [ref]
          return
        }

        const index = this.externalModel.findIndex(item => item.uri === entity.uri)
        if (index >= 0) {
          this.remove(index)
          return
        }

        // Push does not trigger reactivity when using a computed setter
        // https://github.com/vuejs/vue/issues/8867
        const modelCopy = JSON.parse(JSON.stringify(this.externalModel))
        modelCopy.push(ref)
        this.externalModel = modelCopy
      },
      displayValue(entity) {
        const firstNonRefFieldIndex = this.referencedVirtualGrid.fields.findIndex(field => field.columnType.name !== 'references' && field.columnType.name !== 'reference')
        if (firstNonRefFieldIndex === -1) {
          return ''
        }
        return this.referencedVirtualGrid.displayFormat(entity.fields[firstNonRefFieldIndex], firstNonRefFieldIndex)
      },
      isSelected(uri) {
        return this.selectedEntityUris.includes(uri)
      },
      addRow() {
        this.newEntity = this.referencedVirtualGrid.emptyEntity()
        this.editEntityDialog = true
      },
      async onEntityCreated(entity) {
        await this.query()
        this.select(entity)
      }
    },
    components: {
      EntityReferenceCard,
      EntityPickerPreview,
      EditEntityDialog: () => import('@/components/gridView/EditEntityDialog.vue'),
      PaywallMenu,
      VDialog,
      VMenu,
      LoadingOverlay
    }
}
</script>

<style scoped>
.entity-picker-body {
  width: 100%;
}

.scrolling {
  overflow: auto;
}

.remove-button {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 1;
}

.position-relative {
  position: relative;
}

.handle {
  cursor: grab;
}

.ghost-card {
  opacity: 0.5;
  background-color: white;
}

</style>