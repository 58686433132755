<template>
  

        <v-row class="mt-3">
          <v-col v-for="plan in plans" :key="plan.name">
            <v-sheet
              elevation="0"
              outlined
              class="pa-2"
              rounded="lg">
              <v-col align="center">
                <div class="text-h6">{{plan.name}}</div>
                <div class="mb-3">{{plan.description}}</div>
                <div class="subtitle-1 font-weight-bold mb-3">{{plan.price}}</div>


                <v-btn v-if="isCurrentPlan(plan.name)" rounded outlined @click="plan.action" color="primary" class="font-weight-bold mb-3">
                  <template v-if="isStartPlan">
                    {{ $t("paywall.myPlan") }}
                  </template>
                  <template v-else>
                    {{ $t("paywall.upgradeSeats") }}
                  </template>
                  
                  <v-icon class="ml-2" small>mdi-share</v-icon>
                </v-btn>
                <v-btn v-else rounded @click="plan.action" color="primary" class="font-weight-bold mb-3">
                  {{plan.actionButtonTitle}}
                  <v-icon class="ml-2" small>mdi-share</v-icon>
                </v-btn>

                  <div class="d-flex justify-start align-start text-left mb-1" v-for="feature in plan.features" :key="feature">
                      <v-icon small class="mr-2 mt-1" color="green">mdi-check</v-icon>
                      <span class="font-weight-medium accent--text">{{ feature }}</span>
                  </div >

              </v-col>
            </v-sheet>
          </v-col>
        </v-row>

</template>

<script>
export default {
  props: ['text'],
  data() {
    return {
      billingPeriod: 0,
      paymentConfigUrl: null,
      plans_en: [
        {
          name: 'Start',
          description: 'All you need to start',
          price: 'Free',
          actionButtonTitle: 'Free',
          action: this.openStripe,
          features: [
            '2 User per Space',
            '1.500 entries per Grid',
            '1 Flow',
            '2 Forms',
            '1000 Flow step runs',
            'Max. 10 emails /h',
            '1 Webhook',
            'All basic form features',
          ]
        },
        {
          name: 'Plus',
          description: 'For teams and smaller companies',
          price: '9€ per user',
          actionButtonTitle: 'Upgrade now',
          action: this.openStripe,
          features: [
            'Unlimited users',
            'Unlimited flows',
            'Unlimited forms',
            '5000 entries per grid',
            '10.000 flow step runs',
            'Max. 20 emails /h',
            '5 webhooks',
            'Banner free forms',
            'Advanced form features'
          ]
        },
        {
          name: 'Pro',
          description: 'For companies and larger teams',
          price: '19€ per user',
          actionButtonTitle: 'Upgrade now',
          action: this.openStripe,
          features: [
            'Unlimited users',
            'Unlimited flows',
            'Unlimited forms',
            '50.000 entries per grid',
            '20.000 flow step runs',
            'Max. 30 emails /h',
            '10 webhooks',
            'Banner free forms',
            'Advanced form features',
            'Logo on forms',
          ]
        },
        {
          name: 'Enterprise',
          description: 'For corporates and organisations',
          price: 'Flexibel',
          actionButtonTitle: this.$t('paywall.upgradeTeaserChatButton'),
          action: this.openChat,
          features: [
            'Unlimited users',
            'Unlimited flows',
            'Unlimited forms',
            '250.000 entries per grid',
            'Unlimited webhooks',
            'Unlimited flow step runs',
            'Banner free forms',
            'Advanced form features',
            'Logo on forms',
            'Customer success Manager',
            'Fastest customer support possible',
          ]
        }
      ],
      plans_de: [
        {
          name: 'Start',
          description: 'Alles was Sie zum Start brauchen',
          price: 'Kostenlos',
          actionButtonTitle: 'Kostenlos',
          action: this.openStripe,
          features: [
            '2 Nutzer pro Space',
            '1.500 Einträge pro Grid',
            '1 Flow',
            '2 Formulare',
            '1000 Flow Steps Ausführungen',
            '10 E-Mails /h',
            '1 Webhook',
            'Basis Formular Funktionen',
          ]
        },
        {
          name: 'Plus',
          description: 'Für Teams und kleinere Firmen',
          price: '9€ / Monat pro Nutzer',
          actionButtonTitle: 'Jetzt upgraden',
          action: this.openStripe,
          features: [
            'Unbegrenzte Anzahl an Nutzern',
            '5000 Einträge pro Grid',
            'Unbegrenzte Anzahl an Flows',
            'Unbegrenzte Anzahl an Formularen',
            '10.000 Flow Steps Ausführungen',
            '20 E-Mails /h',
            '5 Webhooks',
            'Formulare ohne Werbung',
            'Erweiterte Formular Funktionen',
          ]
        },
        {
          name: 'Pro',
          description: 'Für Firmen und größere Teams',
          price: '19€ / Monat pro Nutzer',
          actionButtonTitle: 'Jetzt upgraden',
          action: this.openStripe,
          features: [
            'Unbegrenzte Anzahl an Nutzern',
            '50.000 Einträge pro Grid',
            'Unbegrenzte Anzahl an Flows',
            'Unbegrenzte Anzahl an Formularen',
            '20.000 Flow Steps Ausführungen',
            '30 E-Mails /h',
            '10 Webhooks',
            'Formulare ohne Werbung',
            'Erweiterte Formular Funktionen',
            'Formulare mit Logo',
          ]
        },
        {
          name: 'Enterprise',
          description: 'Für Organisationen und Firmen',
          price: 'Flexibel anpassbar',
          actionButtonTitle: this.$t('paywall.upgradeTeaserChatButton'),
          action: this.openChat,
          features: [
            'Unbegrenzte Anzahl an Nutzern',
            '250.000 Einträge pro Grid',
            'Unbegrenzte Anzahl an Flows',
            '200.000 Flow Ausführungen',
            'Unbegrenzte Anzahl an webhooks',
            'Formulare ohne Werbung',
            'Erweiterte Formular Funktionen',
            'Formulare mit Logo',
            'Persönlicher Kundenbetreuer',
            'Schnellst möglicher Kundensupport',
          ]
        }
      ]
    }
  },
  async mounted() {
    this.paymentConfigUrl = await this.$store.dispatch('GetCustomerPortalUrl')
  },
  methods: {
    openChat() {
      this.$intercom.showNewMessage()
    },
    openStripe() {
      this.$intercom?.trackEvent( 'User click on upgrade plan' )
      window.location = this.prefilledCustomerPortalLink
    },
    isCurrentPlan(planName) {
      return this.plan && planName && planName?.toUpperCase() === this.plan?.toUpperCase()
    },
  },
  computed: {
    plans() {
      return this.$i18n.locale == 'de' ? this.plans_de : this.plans_en
    },
    user() {
      return this.$store.state.user?.user
    },
    plan() {
      return this.$store.getters.userPlan
    },
    prefilledCustomerPortalLink() {
      return `${this.paymentConfigUrl}?prefilled_email=${this.user.email}`
    },
    isStartPlan() {
      return this.plan && this.plan?.toUpperCase() === 'START'
    },
  }
}
</script>
