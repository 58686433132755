<template>
  <div>
    <TemplateInput v-model="fileName" class="mt-1" :flow="flow" :label="$t('flow.templateFilePicker.fileName')" @input="emit" @focus="lastActiveInput = 'fileName'; $emit('focus')" ref="fileName" topLabel/>
    <TemplateInput v-model="mimeType" class="mt-1" :flow="flow" :label="$t('flow.templateFilePicker.fileType')" @input="emit" @focus="lastActiveInput = 'mimeType'; $emit('focus')" ref="mimeType" topLabel/>
    <TemplateInput v-model="contents" class="mt-1" :flow="flow" :label="$t('flow.templateFilePicker.fileData')" @input="emit" @focus="lastActiveInput = 'contents'; $emit('focus')" ref="contents" topLabel/>
  </div>
</template>

<script>
import TemplateInput from './TemplateInput.vue'
import externalModel from '../../mixins/externalModel'

export default {
  mixins: [externalModel],
  props: {
    flow: null
  },
  data() {
    return {
      fileName: undefined,
      contents: undefined,
      mimeType: undefined,
      lastActiveInput: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal != null) {
          this.fileName = newVal.fileName
          this.contents = newVal.contents
          this.mimeType = newVal.mimeType
        } else {
          this.fileName = undefined
          this.contents = undefined
          this.mimeType = undefined
        }
      }
    }
  },
  methods: {
    emit() {
      if (this.fileName == null || this.contents == null || this.mimeType == null) return
      this.$emit('input', {
        fileName: this.fileName,
        contents: this.contents,
        mimeType: this.mimeType
      })
    },
    insert(text) {
      this.$refs[this.lastActiveInput].insert(text)
      this.$emit('blur')
    },
    focus() {
      this.$refs.fileName.focus()
    }
  },
  components: { TemplateInput }
}
</script>

<style scoped>
.input {
  min-height: 40px;
  padding-bottom: 6px;
  padding-top: 6px;
  border: solid #D3D3D3 1px;
  border-radius: 4px;
  background: white;
}
</style>