<template>
  <BaseNodeComponent v-model="externalModel" v-bind="$attrs">
    <v-select
     v-model="externalModel"
     v-bind="$attrs"
     v-on="$listeners"
     hide-details solo dense
     :multiple="multiple"
    >
    <template slot="item" slot-scope="data">
        <span :data-testid="`item_${data.item}`">{{
          data.item
        }}</span>
      </template>
   </v-select>
  </BaseNodeComponent>
</template>
 
 <script>
 import BaseNodeComponent from '@/components/flow/BaseNodeComponent.vue'
import externalModel from '@/mixins/externalModel.js'
 
 export default {
    mixins: [externalModel],
    props: {
      multiple: {
        type: Boolean,
        default: () => false
      },
    },
    components: { BaseNodeComponent }
}
 </script>