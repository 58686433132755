<template>
  <component
    v-if="!hasError"
    v-bind="$attrs" v-on="$listeners"
    :value="formulaResult"
    :is="cellComponent"
  />
  <div
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    class="d-flex align-center justify-end pa-3"
  >
    <v-icon
      v-show="showErrorIndicator"
      size="20"
      color="error"
      @click="event => emitShowError(event)"
      @pointerenter="scheduleShowError"
      @pointerleave="cancelShowError"
    >mdi-alert-circle-outline</v-icon>
  </div>
</template>

<script>
const SHOW_ERROR_DELAY = 500
const HIDE_ERROR_DELAY = 500

export default {
  props: {
    type: null,
    field: null,
    value: null,
    showErrorIndicator: null
  },
  data() {
    return {
      timeoutId: undefined
    }
  },
  computed: {
    delegateColumnType() {
      return this.field?.columnType.delegateColumnType
    },
    cellComponent() {
      return this.delegateColumnType.readonlyCellComponent
    },
    formulaResult() {
      return this.value?.value
    },
    hasError() {
      return typeof this.value === 'object' && this.value?.error != null
    },
  },
  methods: {
    emitShowError(event) {
      this.$emit('showError', event, this.value?.error)
    },
    scheduleShowError(event) {
      this.timeoutId = setTimeout(() => this.emitShowError(event), SHOW_ERROR_DELAY)
    },
    cancelShowError() {
      if (this.timeoutId == null) return
      clearTimeout(this.timeoutId)
      setTimeout(() => this.$emit('hideError'), HIDE_ERROR_DELAY)
    }
  }
}
</script>