<template>
  <div>
    <v-autocomplete
      v-model="autocompleteModel"
      item-text="name"
      item-value="href"
      :items="items"
      @input="$emit('patchNodeFlowForm')"
      hide-details solo dense
    >
      <template v-slot:append-item>
        <v-divider v-if="items.length > 0"></v-divider>
        <v-list-item @click="addPage" class="mt-2">
          <v-list-item-icon class="mr-0">
            <v-icon small color="black">mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('flow.flowFormPicker.addPage') }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:append-outer>
        <v-btn class="mt-n1" icon text @click="editPage" :disabled="autocompleteModel == null">
          <v-icon>mdi-file-document-edit-outline</v-icon>
        </v-btn>
      </template>
    </v-autocomplete>
    <EditPageDialog v-if="autocompleteModel != null" v-model="editPageDialog" :blockUri="autocompleteModel.href"/>
  </div>
</template>

<script>
import EditPageDialog from '@/components/block/EditPageDialog.vue'
import externalModel from '@/mixins/externalModel'

  export default {
    mixins: [externalModel],
    props: {
      space: null
    },
    data() {
      return {
        items: [],
        loading: false,
        editPageDialog: false
      }
    },
    computed: {
    autocompleteModel: {
        get() {
          return this.value
        },
        set(newVal) {
          this.$emit('input', { href: newVal })
        }
      }
    },
    watch: {
      space: {
        immediate: true,
        async handler(newValue) {
          if (newValue == null) {
            return
          }
          this.listPages()
        }
      },
      editPageDialog(newVal) {
        if (!newVal) {
          this.$emit('pageEdited')
        }
      }
    },
    methods: {
      async listPages() {
        this.loading = true
          try {
            const blocks = await this.$store.dispatch('AGListSpaceBlocks', this.space)
            let blockItems = blocks
              .map((aBlock, index) => { return {
                href : aBlock.getLink('self'),
                name: aBlock.name || `Block ${index + 1}`}
              })
            this.items = blockItems
          } finally {
            this.loading = false
          }
      },
      async addPage() {
        const response = await this.$store.dispatch('AGAddSpacePageBlock', this.space)
        await this.listPages()
        this.autocompleteModel = response.headers.location
      },
      editPage() {
        this.editPageDialog = true
      }
    },
    components: {
      EditPageDialog
    }
  }
</script>

<style lang="scss" scoped>

</style>